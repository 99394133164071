import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { assets } from "../../assets/assets";
import CustomButton from "../../components/customButton";
import Loader from "../../components/loader";
import transactionService from "../../services/transaction.service";

const PaymentVerification: React.FC = () => {
  const [state, setState] = React.useState("loading");
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("reference");

  useEffect(() => {
    transactionService.verifyPayment(id as string, { setData: setState });
  }, [id]);
  return (
    <div className="position-absolute top-50 start-50 translate-middle">
      {state === "loading" && <Loader type="loading" />}
      {state === "success" && (
        <>
          <img
            src={assets.smile_emoji}
            className="w-20 d-block mx-auto"
            alt="success-payment"
          />
          <h5 className="text-center fw-b pt-4">Payment successful</h5>
          <CustomButton
            onClick={() => {
              window.close();
            }}
            marginTop={3}
            width={30}
            center
          >
            Okay
          </CustomButton>
        </>
      )}
      {state === "failed" && (
        <>
          <img
            src={assets.shocked_emoji}
            className="w-20 d-block mx-auto"
            alt="failed-payment"
          />
          <h5 className="text-center fw-b pt-4">Payment failed</h5>
          <CustomButton
            onClick={() => {
              window.close();
            }}
            marginTop={3}
            width={30}
            center
          >
            Okay
          </CustomButton>
        </>
      )}
    </div>
  );
};
export default PaymentVerification;
