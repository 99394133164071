import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { assets } from "../../assets/assets";
import CustomButton from "../../components/customButton";
import Input from "../../components/input";
import ResponsiveRender from "../../components/responsiveRender";
import { useGetScreen, useNotification } from "../../hooks/app";
import authServices from "../../services/auth.service";
import { SCREEN_SIZE } from "../../utils/utils";

const ResetPassword: React.FC = () => {
  const { isLargeDesktop, isXLargeDesktop } = useGetScreen();
  const showCol2 = isLargeDesktop || isXLargeDesktop ? true : false;
  const [password, setPassword] = React.useState({
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();
  const { error, success, warning, loading, setLoading } = useNotification();
  const search = useLocation().search;
  const userCode = new URLSearchParams(search).get("userCode");

  return (
    <Container fluid>
      <Row className="vh-100">
        <Col
          lg={!showCol2 ? 10 : 3}
          md={!showCol2 ? 6 : 3}
          className={`${!showCol2 ? "mx-auto" : ""}`}
        >
          <div className="w-25 mx-auto pt-3">
            <img src={assets.logo} alt="logo" className="w-100" />
          </div>
          <div className="w-60 mx-auto">
            <p className="text-center mt-3 fs-5">Reset password</p>
          </div>
          <form>
            <div className="ms-2 mt-4">
              <Input
                label="New password"
                type={"password"}
                icon={<BsFillEyeSlashFill size={20} />}
                value={password.password}
                onChange={(password) => {
                  setPassword((state: any) => {
                    return {
                      ...state,
                      password,
                    };
                  });
                }}
              />
            </div>
            <div className="ms-2 mt-4">
              <Input
                label="Confirm password"
                type={"password"}
                icon={<BsFillEyeSlashFill size={20} />}
                value={password.confirmPassword}
                onChange={(confirmPassword) => {
                  setPassword((state: any) => {
                    return {
                      ...state,
                      confirmPassword,
                    };
                  });
                }}
              />
            </div>
            <CustomButton
              type="button"
              isLoading={loading}
              onClick={async () => {
                const res = await authServices.setNewPassword(
                  {
                    password: password.password,
                    code: userCode as string,
                    confirmPassword: password.confirmPassword,
                    email: window.localStorage.getItem("tempMail") as string,
                  },
                  { setLoading, success, error, warning }
                );
                if (res) {
                  navigate("/");
                }
              }}
              width={70}
              marginTop={3}
              center
            >
              Submit
            </CustomButton>
          </form>
        </Col>
        <ResponsiveRender>
          <Col
            lg={9}
            md={9}
            id={SCREEN_SIZE.isXLargeDesktop}
            className="vh-100"
          >
            <img
              src={assets.doc_3}
              alt="doctor-2"
              className="w-100 img-fluid h-100 img-fit-left"
            />
          </Col>
        </ResponsiveRender>
      </Row>
    </Container>
  );
};

export default ResetPassword;
