import { Col, Container, Row, Stack } from "react-bootstrap";
import { BsTelephoneFill } from "react-icons/bs";
import { HiLocationMarker } from "react-icons/hi";
import { MdEmail } from "react-icons/md";
import { UserLayoutProps } from "../../@types/props";
import { assets } from "../../assets/assets";
import { useGetScreen } from "../../hooks/app";
import useAtomState from "../../hooks/state";
import Logo from "../logo";
import {
  UserAppointmentSidebar,
  UserDetailsSidebar,
  UserEnquiriesSidebar,
  UserOnboardingSidebar,
} from "../sidebar";

const UserLayout: React.FC<UserLayoutProps> = ({ children }) => {
  const {
    showBookAppointment,
    doctorInfo,
    showEnquiries,
    showUserOnboarding,
    showUserDetails,
  } = useAtomState();
  const { isMobilePortrait } = useGetScreen();
  return (
    <Container className="vh-100" fluid>
      <Row>
        {!isMobilePortrait && (
          <Col lg={2} md={4} className="bg-white">
            <Logo width={60} className="ms-4" />
            <Stack direction="vertical">
              <div className="avatar-box-user">
                <img
                  src={doctorInfo.avatar}
                  alt="doctor avatar"
                  style={{
                    width: "45%",
                    height: "45%",
                    borderRadius: 20,
                    objectFit: "cover",
                    objectPosition: "top",
                  }}
                  className="d-block mx-auto mt-3"
                />
                <p className="fs-6 text-center pt-2">
                  {doctorInfo.businessName}
                </p>
              </div>
              <div className="w-40 mx-auto">
                <img
                  src={assets.instagram_icon}
                  alt="instagram social"
                  className="cursor-p"
                  width={20}
                />
                <img
                  src={assets.twitter_icon}
                  alt="instagram social"
                  className="ps-1 cursor-p"
                  width={20}
                />
                <img
                  src={assets.whatsapp_icon}
                  alt="instagram social"
                  className="ps-1 cursor-p"
                  width={20}
                />
              </div>
              <div className="mt-5">
                <Row className="ms-1">
                  <Col>
                    <h4>Contact Info</h4>

                    <p className="pt-3 lh-1" style={{ color: "#4f4e69" }}>
                      <MdEmail size={20} color={"#b3b3bf"} />
                      <span className="ps-1">{doctorInfo.fullName}</span>
                    </p>
                  </Col>
                </Row>
                <hr style={{ margin: ".3rem 0", color: "#b3b3bf" }} />
                <Row className="ms-1">
                  <Col>
                    <p className="pt-3 lh-1" style={{ color: "#4f4e69" }}>
                      <BsTelephoneFill size={20} color={"#b3b3bf"} />
                      <span className="ps-1">{doctorInfo.phone}</span>
                    </p>
                  </Col>
                </Row>
                <hr style={{ margin: ".3rem 0", color: "#b3b3bf" }} />
                <Row className="ms-1">
                  <Col>
                    <p className="pt-3 lh-1" style={{ color: "#4f4e69" }}>
                      <HiLocationMarker size={20} color={"#b3b3bf"} />
                      <span className="ps-1"> {doctorInfo.clinicAddress}</span>
                    </p>
                  </Col>
                </Row>
              </div>
            </Stack>
          </Col>
        )}
        <Col className="vh-100" style={{ backgroundColor: "#fafafb" }}>
          {children}
        </Col>
      </Row>
      <UserAppointmentSidebar show={showBookAppointment} />
      <UserEnquiriesSidebar show={showEnquiries} />
      <UserOnboardingSidebar show={showUserOnboarding} />
      <UserDetailsSidebar show={showUserDetails} />
    </Container>
  );
};
export default UserLayout;
