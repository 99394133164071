import { Dropdown } from "react-bootstrap";
import { MdMoreHoriz } from "react-icons/md";

const More: React.FC<{
  className?: any;
  more?: {
    name?: any;
    onClick: (data?: any) => void;
    img?: any;
    classN?: string;
  }[];
  drop?: "up" | "down" | "start" | "end";
}> = ({ className, more, drop = "up" }) => {
  return (
    <Dropdown className={className} drop={drop}>
      <Dropdown.Toggle
        as={"div"}
        className="ps-2 pt-1 pe-3 border-light text-dark border-0 cursor-p"
        style={{
          height: 10,
          width: 10,
          backgroundColor: "transparent",
        }}
      >
        <MdMoreHoriz size={30} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="border-0 shadow-sm hover-white">
        {more?.map(({ name, onClick, img, classN }, ind: number) => {
          return (
            <Dropdown.Item key={ind} onClick={onClick} className={classN}>
              <img src={img} width={30} alt="more" />
              <span style={{ fontSize: 14, paddingLeft: 8 }}>{name}</span>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default More;
