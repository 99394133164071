import React from "react";
import { Dropdown } from "react-bootstrap";
import { AiOutlineSetting } from "react-icons/ai";
import { BiLogOut } from "react-icons/bi";
import { TbCopy } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import { UserMenuProps } from "../@types/props";
import { useNotification } from "../hooks/app";
import useAtomState from "../hooks/state";
import { useLayoutStyles } from "../hooks/styles";
import authServices from "../services/auth.service";
import { routes } from "../utils/router";

const UserMenu: React.FC<UserMenuProps> = ({ className, style }) => {
  const navigate = useNavigate();
  const classNames = useLayoutStyles();
  const { setShowRightSidebar } = useAtomState();
  const { success } = useNotification();
  return (
    <React.Fragment>
      {classNames.userMenu.hideName ? (
        <Dropdown className={className} style={style}>
          <Dropdown.Toggle
            className="p-4 rounded-pill ps-2 pt-1 border-light text-dark"
            style={{
              height: classNames.userMenu.height,
              width: classNames.userMenu.width,
              backgroundColor: "#fff",
            }}
          >
            <span className="d-inline-block user-menu-img">
              <img
                src={authServices.getStoredData()?.user?.avatar as string}
                className="w-100 rounded-circle"
                alt="avatar"
                height={30}
              />
            </span>
            <span className="fs-7 d-inline-block mt-1">
              {authServices.getName().lastName ||
                authServices.getName().firstName}
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu className="border-0 shadow-sm hover-white">
            <Dropdown.Item>
              <p style={{ fontSize: 13 }}>{authServices.getName().name}</p>
              <p style={{ fontSize: 14 }}>Senior Dentist at yanga</p>
            </Dropdown.Item>
            <Dropdown.Divider></Dropdown.Divider>
            <Dropdown.Item onClick={() => navigate(routes.settings)}>
              <p style={{ fontSize: 13 }}>
                <AiOutlineSetting size={20} />
                <span className="ps-2">Account settings</span>
              </p>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                authServices.logout((data) => {
                  navigate(data);
                })
              }
            >
              <p style={{ fontSize: 14 }}>
                <BiLogOut size={20} />
                <span className="ps-2">Log out</span>
              </p>
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => {
                window.navigator.clipboard.writeText(
                  `${window.location.host}/doctor/${
                    authServices.getStoredData().user?.uniqueRouteNumber
                  }`
                );
                success('Link copied!')
              }}
            >
              <p style={{ fontSize: 14 }}>
                <TbCopy size={20} />
                <span className="ps-2">Copy Link</span>
              </p>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <div className={className}>
          <span className="d-inline-block user-menu-img">
            <img
              onClick={() => setShowRightSidebar(true)}
              src={authServices.getStoredData()?.user?.avatar as string}
              className="w-100 rounded-circle cursor-p"
              alt="avatar"
              height={30}
            />
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

export default UserMenu;
