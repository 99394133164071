import React, { Fragment } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import { Col, Row, Stack } from "react-bootstrap";
import CustomButton from "../../components/customButton";
import ScrollView from "../../components/scrollview";
import { search, useGetScreen, useNotification } from "../../hooks/app";
import useAtomState from "../../hooks/state";
import appServices from "../../services/app.service";
import Input from "../../components/input";
import { useCalendarStyles } from "../../hooks/styles";
import Loader from "../../components/loader";
import Tab from "../../components/tab";

const Calendar: React.FC = () => {
  const { isXLargeDesktop, isLargeDesktop, isMobilePortrait } = useGetScreen();
  const calendarStyles = useCalendarStyles();
  const [searchInp, setSearchInp] = React.useState("");
  const { search: selectUser, setSearch } = search.patientByName();
  const { setAppointment, appointment, setShowAppointment } = useAtomState();
  const { loading, setLoading } = useNotification();
  const [tabs, setTabs] = React.useState([
    { active: true, name: "Patients" },
    { active: false, name: "Calendar" },
  ]);

  React.useEffect(() => {
    setLoading(true);
    appServices.getDoctorsAppointment().then((data) => {
      setAppointment(data?.data);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-95 mx-auto">
      {isMobilePortrait && (
        <Tab
          className="w-100 mb-2"
          tabs={tabs}
          setData={(data) => {
            setTabs(data);
          }}
        />
      )}
      <Row className="g-2">
        {isMobilePortrait ? (
          <>
            {tabs[0].active && (
              <Col lg={3} md={3} sm={12} className="pe-3 bg-white rounded p-3">
                <CustomButton
                  onClick={() => setShowAppointment(true)}
                  style={{
                    fontSize: isXLargeDesktop || isLargeDesktop ? 16 : 11,
                  }}
                >
                  Create Schedule
                </CustomButton>
                <h5 className="pt-3 ps-3">Patients</h5>
                <form>
                  <Input
                    value={searchInp}
                    type={"search"}
                    onChange={(data) => {
                      setSearch(data);
                      setSearchInp(data);
                    }}
                    placeholder="Search for Patient"
                  />
                </form>
                <ScrollView
                  isLargeDesktop={"60vh"}
                  isMediumPortrait="80vh"
                  isMobilePortrait="80vh"
                >
                  <div>
                    {loading ? (
                      <Loader type="skeleton-patient-card" fill={6} />
                    ) : (
                      <>
                        {selectUser.length !== 0 ? (
                          selectUser?.map(
                            ({ value: { picture, fullName, email } }, ind) => {
                              return (
                                <Fragment key={ind}>
                                  <Stack direction="horizontal">
                                    <div>
                                      <img
                                        src={picture}
                                        width={35}
                                        height={35}
                                        style={{
                                          borderRadius: "50%",
                                          marginTop: 10,
                                        }}
                                        alt={`${fullName}`}
                                        className="mt-1"
                                      />
                                    </div>
                                    <div>
                                      <div className="ps-4 pt-3">
                                        <p className="lh-04">{fullName}</p>
                                        <p
                                          className="fs-7 lh-04"
                                          style={{ color: "#b8b7c2" }}
                                        >
                                          {email}
                                        </p>
                                      </div>
                                    </div>
                                  </Stack>
                                  <hr style={{ color: "#b8b7c2" }} />
                                </Fragment>
                              );
                            }
                          )
                        ) : (
                          <p className="text-center mt-3">
                            No patient available
                          </p>
                        )}
                      </>
                    )}
                  </div>
                </ScrollView>
              </Col>
            )}
            {tabs[1].active && (
              <Col
                lg={9}
                md={9}
                sm={12}
                className={calendarStyles.cal_col.className}
              >
                <div className="bg-white rounded p-2">
                  <FullCalendar
                    plugins={[dayGridPlugin]}
                    initialView="dayGridMonth"
                    headerToolbar={isMobilePortrait ? { end: "" } : undefined}
                    contentHeight={"75vh"}
                    weekends={false}
                    events={[
                      ...(appointment?.map(
                        ({
                          appointmentDate,
                          patient,
                          doctor_service,
                          appointmentTime,
                        }) => {
                          return {
                            title: patient?.fullName,
                            date: appointmentDate,
                            image: patient?.picture,
                            serviceName: doctor_service?.serviceName,
                            time: appointmentTime,
                          };
                        }
                      ) || {}),
                    ]}
                  />
                </div>
              </Col>
            )}
          </>
        ) : (
          <>
            <Col lg={3} md={3} sm={12} className="pe-3 bg-white rounded p-3">
              <CustomButton
                onClick={() => setShowAppointment(true)}
                style={{
                  fontSize: isXLargeDesktop || isLargeDesktop ? 16 : 11,
                }}
              >
                Create Schedule
              </CustomButton>
              <h5 className="pt-3 ps-3">Patients</h5>
              <form>
                <Input
                  value={searchInp}
                  type={"search"}
                  onChange={(data) => {
                    setSearch(data);
                    setSearchInp(data);
                  }}
                  placeholder="Search for Patient"
                />
              </form>
              <ScrollView
                isLargeDesktop={"60vh"}
                isMediumPortrait="80vh"
                isMobilePortrait="80vh"
              >
                <div>
                  {loading ? (
                    <Loader type="skeleton-patient-card" fill={6} />
                  ) : (
                    <>
                      {selectUser.length !== 0 ? (
                        selectUser?.map(
                          ({ value: { picture, fullName, email } }, ind) => {
                            return (
                              <Fragment key={ind}>
                                <Stack direction="horizontal">
                                  <div>
                                    <img
                                      src={picture}
                                      width={35}
                                      height={35}
                                      style={{
                                        borderRadius: "50%",
                                        marginTop: 10,
                                      }}
                                      alt={`${fullName}`}
                                      className="mt-1"
                                    />
                                  </div>
                                  <div>
                                    <div className="ps-4 pt-3">
                                      <p className="lh-04">{fullName}</p>
                                      <p
                                        className="fs-7 lh-04"
                                        style={{ color: "#b8b7c2" }}
                                      >
                                        {email}
                                      </p>
                                    </div>
                                  </div>
                                </Stack>
                                <hr style={{ color: "#b8b7c2" }} />
                              </Fragment>
                            );
                          }
                        )
                      ) : (
                        <p className="text-center mt-3">No patient available</p>
                      )}
                    </>
                  )}
                </div>
              </ScrollView>
            </Col>
            <Col
              lg={9}
              md={9}
              sm={12}
              className={calendarStyles.cal_col.className}
            >
              <div className="bg-white rounded p-2">
                <FullCalendar
                  plugins={[dayGridPlugin]}
                  initialView="dayGridMonth"
                  headerToolbar={isMobilePortrait ? { end: "" } : undefined}
                  contentHeight={"75vh"}
                  weekends={false}
                  events={[
                    ...appointment?.map(
                      ({
                        appointmentDate,
                        patient,
                        doctor_service,
                        appointmentTime,
                      }) => {
                        return {
                          title: patient?.fullName,
                          date: appointmentDate,
                          image: patient?.picture,
                          serviceName: doctor_service?.serviceName,
                          time: appointmentTime,
                        };
                      }
                    ),
                  ]}
                />
              </div>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
};
export default Calendar;
