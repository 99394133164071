import React from "react";
import { Col, Row } from "react-bootstrap";
import Input from "../../components/input";
import ScrollView from "../../components/scrollview";
import Uploader from "../../components/uploader";
import { useGetScreen } from "../../hooks/app";
import useAtomState from "../../hooks/state";

const OtherDetails: React.FC = () => {
  const { otherDetails, setOtherDetails } = useAtomState();
  const { isMediumPortrait, isXLargeDesktop } = useGetScreen();
  React.useEffect(() => {
    setOtherDetails({
      ...otherDetails,
      clinicAddress: "78, Bode Thomas, Surulere, Lagos.",
    });
    // eslint-disable-next-line
  }, []);

  const uploaderWidth = () => {
    if (isXLargeDesktop) return 20;
    if (isMediumPortrait) return 20;
    return 30;
  };

  return (
    <ScrollView
      isXLargeDesktop="70vh"
      isMediumPortrait="70vh"
      isMobilePortrait="85vh"
    >
      <h3 className="mt-4 ms-3 text-gray fw-normal">Other details</h3>

      <form className="w-90 ms-2 mt-4">
        <Uploader
          width={uploaderWidth()}
          height
          onClick={(avatar: any) => {
            setOtherDetails((state: any) => {
              return { ...state, avatar };
            });
          }}
        />
        <Input
          containerClass="mt-3"
          className="mt-2"
          label="Your Bio"
          placeholder="Type here.."
          value={otherDetails.doctorBio}
          onChange={(doctorBio) => {
            setOtherDetails((state: any) => {
              return { ...state, doctorBio };
            });
          }}
        />
        <Input
          containerClass="mt-3"
          className="mt-2"
          label="Clinic address"
          placeholder="78, Bode Thomas, Surulere, Lagos."
          value={otherDetails.clinicAddress}
          disabled
        />
        <Row className="mt-5">
          <Col lg={4} sm={12}>
            <Input
              containerClass="mt-3"
              className="mt-2"
              label="Instagram"
              placeholder="https://instagram.com/"
              value={otherDetails.instagram}
              onChange={(instagram) => {
                setOtherDetails((state: any) => {
                  return { ...state, instagram };
                });
              }}
            />
          </Col>
          <Col lg={4} sm={12}>
            <Input
              containerClass="mt-3"
              className="mt-2"
              label="Twitter"
              placeholder="https://twitter.com/"
              value={otherDetails.twitter}
              onChange={(twitter) => {
                setOtherDetails((state: any) => {
                  return { ...state, twitter };
                });
              }}
            />
          </Col>
          <Col lg={4} sm={12}>
            <Input
              containerClass="mt-3"
              className="mt-2"
              label="Facebook"
              placeholder="https://twitter.com/"
              value={otherDetails.facebook}
              onChange={(facebook) => {
                setOtherDetails((state: any) => {
                  return { ...state, facebook };
                });
              }}
            />
          </Col>
        </Row>
      </form>
    </ScrollView>
  );
};

export default OtherDetails;
