import { Row, Col, Container } from "react-bootstrap";
import { UserOnboardingSidebarProps } from "../../@types/props";
import { assets } from "../../assets/assets";
import { useNotification } from "../../hooks/app";
import useAtomState from "../../hooks/state";
import authServices from "../../services/auth.service";
import CustomButton from "../customButton";
import Input from "../input";
import Sidebar from "./sidebar";

const UserOnboardingSidebar: React.FC<UserOnboardingSidebarProps> = ({
  show,
}) => {
  const {
    bookAppointment,
    setBookAppointment,
    setShowUserDetails,
    setShowUserOnboarding,
  } = useAtomState();
  const { error } = useNotification();
  return (
    <Sidebar show={show} handleClose={setShowUserOnboarding} placement="end">
      <Row>
        <Col lg={2}>
          <img
            onClick={() => setShowUserOnboarding(false)}
            src={assets.left_icon}
            alt="left-icon"
            width={45}
            height={45}
            className="cursor-p"
          />
        </Col>
      </Row>
      <hr />
      <Container className="mt-7">
        <div className="ps-2">
          <h5>Hi Chief 😉</h5>
          <p>We will love to onboard you </p>
        </div>
        <form>
          <Input
            type={"email"}
            label="Email address"
            placeholder="Email"
            className="mt-3"
            required
            value={bookAppointment.email}
            onChange={(email) => {
              setBookAppointment((state: any) => {
                return { ...state, email };
              });
            }}
          />

          <CustomButton
            onClick={async () => {
              const check = await authServices.strictCheck({
                data: { Email: bookAppointment.email },
              });
              if (check?.error) {
                error(check.error);
                return;
              }
              setShowUserDetails(true);
              setShowUserOnboarding(false);
            }}
            type="button"
            className="mt-3"
            width={90}
            center
          >
            Get started
          </CustomButton>
        </form>
      </Container>
    </Sidebar>
  );
};
export default UserOnboardingSidebar;
