import { useAtom } from "jotai";
import { Stack } from "react-bootstrap";
import { AllBankSidebarProps } from "../../../@types/props";
import { assets } from "../../../assets/assets";
import { useNotification } from "../../../hooks/app";
import useAtomState from "../../../hooks/state";
import transactionService from "../../../services/transaction.service";
import { addBankSidebar, allBankSidebar } from "../../../utils/state";
import CustomButton from "../../customButton";
import More from "../../more";
import ScrollView from "../../scrollview";
import Sidebar from "../sidebar";

const AllBank: React.FC<AllBankSidebarProps> = ({ show }) => {
  const [, setBankShow] = useAtom(allBankSidebar);
  const [, setAddBankShow] = useAtom(addBankSidebar);
  const { doctorsBankList, setDoctorsBankList } = useAtomState();
  const { success, setLoading, error } = useNotification();
  return (
    <Sidebar show={show} handleClose={setBankShow} placement="end">
      <Stack direction="horizontal">
        <div>
          <img
            onClick={() => setBankShow(false)}
            src={assets.left_icon}
            alt="left-icon"
            width={45}
            height={45}
            className="cursor-p"
          />
        </div>
        <div>
          <h3 className="pt-1 ms-2">Add bank</h3>
        </div>
      </Stack>
      <hr />
      <ScrollView isLargeDesktop="40vh">
        {doctorsBankList.length === 0 ? (
          <p className="mt-5 text-center">No bank data available</p>
        ) : (
          doctorsBankList.map((data) => {
            return (
              <Stack
                className="rounded-3 mt-2 p-2 w-100 mx-auto position-relative"
                style={{ backgroundColor: "#fef8fa" }}
                direction="horizontal"
                key={data.id}
              >
                <div>
                  <img
                    src={assets.bank_icon}
                    alt="bank icon"
                    className="mt-1"
                    width={60}
                  />
                </div>
                <div className="pt-3 ms-4">
                  <p className="lh-0 fs-7">
                    ****{data.accountNumber.substring(6, 10)}
                  </p>
                  <p className="lh-04 fs-7" style={{ color: "#c7c3c7" }}>
                    {data.BankName}
                  </p>
                </div>
                <More
                  drop="down"
                  more={[
                    { name: "Edit", onClick: () => {}, img: assets.edit_icon },
                    {
                      name: "Delete",
                      onClick: () =>
                        transactionService.deleteDoctorsBank(
                          data.id,
                          {
                            success,
                            setLoading,
                            error,
                          },
                          setDoctorsBankList
                        ),
                      img: assets.del_icon,
                    },
                  ]}
                  className={"position-absolute mt-2 end-0 me-3 top-10"}
                />
              </Stack>
            );
          })
        )}
      </ScrollView>
      <CustomButton
        onClick={() => setAddBankShow(true)}
        marginTop={2}
        width={60}
        className="mt-5"
        center
      >
        Add Bank
      </CustomButton>
    </Sidebar>
  );
};

export default AllBank;
