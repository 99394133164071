import { Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { HiMenuAlt2 } from "react-icons/hi";
import { AppLayoutProps } from "../../@types/props";
import React from "react";
import ResponsiveRender from "../responsiveRender";
import AppointmentSidebar from "../sidebar/appointment";
import ServiceSidebar from "../sidebar/serviceSidebar";
import { AppointmentState } from "../../@types/state";
import { SCREEN_SIZE } from "../../utils/utils";
import { routes } from "../../utils/router";
import ScrollView from "../scrollview";
import useAtomState from "../../hooks/state";
import { useGetScreen } from "../../hooks/app";
import { useLayoutStyles } from "../../hooks/styles";
import authServices from "../../services/auth.service";
import AppSidebarLinks from "../appSidebarLink";
import {
  AddBankSidebar,
  AllBankSidebar,
  NotificationSidebar,
  NotificationSidebarDetails,
  PatientSidebar,
  SideBar,
} from "../sidebar";
import UserMenu from "../userMenu";
import AppSidebarDetails from "../appSidebarDetails";
import Notification from "../notificationMenu";
import PaymentDetails from "../sidebar/paymentDetails";

const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const location = useLocation();
  const { isMobilePortrait } = useGetScreen();
  const {
    showAppointment,
    showService,
    showAddBank,
    showAllBank,
    showPatient,
    editServiceState,
    setEditServiceState,
    addPatient,
    showLeftSidebar,
    setShowLeftSidebar,
    setAddPatient,
    showRightSidebar,
    setShowRightSidebar,
    showPaymentDetails,
  } = useAtomState();
  const className = useLayoutStyles();
  const [appointmentData, setAppointmentData] =
    React.useState<AppointmentState>({});

  React.useEffect(() => {
    authServices.getUserProfile();
  }, []);

  const getNameFromPath = () => {
    return `${location.pathname
      .substring(5, location.pathname.length)
      .substring(0, 1)
      .toUpperCase()}${location.pathname.substring(
      6,
      location.pathname.length
    )}`;
  };

  const renderNull = () => {
    if (location.pathname === routes.calendar) return true;
    return false;
  };

  return (
    <Container className="vh-100" fluid>
      <Row className="vh-100">
        <ResponsiveRender>
          <Col lg={2} sm={12}>
            <AppSidebarLinks location={location} />
          </Col>
          <Col lg={2} id={SCREEN_SIZE.isXLargeDesktop}>
            <AppSidebarLinks location={location} />
          </Col>
          <Col md={3} id={SCREEN_SIZE.isMediumPortrait}>
            <AppSidebarLinks location={location} />
          </Col>
          <div id={SCREEN_SIZE.isMobilePortrait}>
            <SideBar
              show={showLeftSidebar}
              closeButton={true}
              placement="start"
              handleClose={setShowLeftSidebar}
            >
              <AppSidebarLinks location={location} />
            </SideBar>
          </div>
        </ResponsiveRender>
        <Col
          className="position-relative"
          style={{ backgroundColor: "#fafafb" }}
        >
          {isMobilePortrait && (
            <HiMenuAlt2
              onClick={() => {
                setShowLeftSidebar(true);
              }}
              size={className.headerIcon.size}
              style={{
                position: className.headerIcon.position as any,
                top: className.headerIcon.top,
                left: className.headerIcon.left,
              }}
            />
          )}
          <div className={className.headerName.position}>
            <h4 className={className.headerName.name}>{getNameFromPath()}</h4>
          </div>
          <div className="d-block nav-bar">
            <Notification className={className.notification} />
            <UserMenu className={className.userMenu.position} />
          </div>
          <ScrollView
            isLargeDesktop="90vh"
            isMediumPortrait="90vh"
            isMobilePortrait="100vh"
          >
            {children}
          </ScrollView>
        </Col>
        <ResponsiveRender renderNull={renderNull()}>
          <Col
            lg={3}
            style={{ backgroundColor: "#fafafb" }}
            id={SCREEN_SIZE.isXLargeDesktop}
          >
            <AppSidebarDetails />
          </Col>
          <div id={SCREEN_SIZE.isMobilePortrait}>
            <SideBar
              show={showRightSidebar}
              placement="bottom"
              handleClose={setShowRightSidebar}
            >
              <AppSidebarDetails />
            </SideBar>
          </div>
        </ResponsiveRender>
      </Row>
      <AppointmentSidebar
        data={appointmentData}
        setData={setAppointmentData}
        show={showAppointment}
        title="Schedule appointment"
      />
      <ServiceSidebar
        title={`${
          editServiceState.type === "add" ? "Add service" : "Edit service"
        }`}
        data={editServiceState}
        show={showService}
        setData={setEditServiceState}
        type={editServiceState.type}
      />
      <PatientSidebar
        type={addPatient.type}
        title={addPatient.type === "add" ? "Add Patient" : "Edit Patient"}
        show={showPatient}
        data={addPatient}
        setData={setAddPatient}
      />
      <AllBankSidebar show={showAllBank} />
      <AddBankSidebar show={showAddBank} />
      <PaymentDetails show={showPaymentDetails} />
      <NotificationSidebarDetails />
      <NotificationSidebar />
    </Container>
  );
};

export default AppLayout;
