import React from "react";
import { PatientProps } from "../../@types/props";
import { PatientState } from "../../@types/state";
import { useGetScreen, useNotification } from "../../hooks/app";
import useAtomState from "../../hooks/state";
import appServices from "../../services/app.service";
import CustomButton from "../customButton";
import Input from "../input";
import Uploader from "../uploader";
import Sidebar from "./sidebar";

const PatientSidebar: React.FC<PatientProps> = ({
  title,
  show,
  type,
  data,
  setData,
}) => {
  const { setShowPatient, setPatients } = useAtomState();
  const { loading, setLoading, success, warning } = useNotification();
  const { isMediumPortrait, isXLargeDesktop } = useGetScreen();

  const uploaderWidth = () => {
    if (isXLargeDesktop) return 40;
    if (isMediumPortrait) return 30;
    return 30;
  };

  const patientHandler = React.useCallback(async () => {
    if (type === "add") {
      const res = await appServices.addPatients(data as PatientState, {
        setLoading,
        success,
        warning,
      });
      if (res) {
        appServices.refetchPatients(setPatients);
        setShowPatient(false);
      }
    }

    if (type === "edit") {
      const res = await appServices.editPatient(data as PatientState, {
        setLoading,
        success,
        warning,
      });
      if (res) {
        appServices.refetchPatients(setPatients);
        setShowPatient(false);
      }
    }

    appServices.refetchPatients(setPatients);
    // eslint-disable-next-line
  }, [type, data]);

  return (
    <Sidebar
      show={show}
      placement={"end"}
      handleClose={setShowPatient}
      closeButton
    >
      <h4>{title}</h4>
      <form>
        <Uploader
          width={uploaderWidth()}
          height={70}
          value={data?.picture}
          onClick={(picture) => {
            setData?.({
              ...data,
              picture,
            });
          }}
        />

        <Input
          containerClass="mt-3"
          label="Full name"
          placeholder="Full name"
          value={data?.fullName}
          onChange={(fullName) => {
            setData?.({
              ...data,
              fullName,
            });
          }}
        />
        <Input
          containerClass="mt-3"
          label="Email"
          placeholder="Email"
          type="email"
          value={data?.email}
          onChange={(email) => {
            setData?.({
              ...data,
              email,
            });
          }}
        />
        <Input
          containerClass="mt-3"
          label="Phone number"
          placeholder="Phone number"
          type={"number"}
          maxLength={11}
          value={data?.phone}
          onChange={(phone) => {
            setData?.({
              ...data,
              phone,
            });
          }}
        />
        <Input
          label="Gender"
          placeholder="Select Gender"
          type={"select"}
          className="mt-2"
          options={[
            { name: "Male", label: "Male", value: "Male" },
            { name: "Female", label: "Female", value: "Female" },
          ]}
          value={data?.gender}
          onChange={(gender) => {
            setData?.({
              ...data,
              gender,
            });
          }}
        />
        <CustomButton
          isLoading={loading}
          disabled={loading}
          onClick={async (e) => {
            e.preventDefault();
            patientHandler();
          }}
          width={90}
          marginTop={7}
          loadingText="Loading"
          center
        >
          Save
        </CustomButton>
      </form>
    </Sidebar>
  );
};

export default PatientSidebar;
