import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { RegisterProps } from "../../@types/props";
import { assets } from "../../assets/assets";
import CustomButton from "../../components/customButton";
import Input from "../../components/input";
import Logo from "../../components/logo";
import ResponsiveRender from "../../components/responsiveRender";
import ScrollView from "../../components/scrollview";
import { useGetScreen, useNotification } from "../../hooks/app";
import useAtomState from "../../hooks/state";
import authServices from "../../services/auth.service";
import { routes } from "../../utils/router";
import { SCREEN_SIZE } from "../../utils/utils";

const Register: React.FC<RegisterProps> = () => {
  const { isLargeDesktop, isXLargeDesktop } = useGetScreen();
  const { regData, setRegData } = useAtomState();
  const navigate = useNavigate();
  const [agree, setAgree] = React.useState(false);
  const { warning, success, error } = useNotification();
  const [loading, setLoading] = React.useState(false);

  const showCol2 = isLargeDesktop || isXLargeDesktop ? true : false;

  return (
    <Container as={"section"} fluid>
      <Row>
        <Col
          lg={!showCol2 ? 10 : 3}
          md={!showCol2 ? 6 : 3}
          className={`${!showCol2 ? "mx-auto" : ""}`}
        >
          <ScrollView isLargeDesktop="100vh">
            <Logo center to="/" />
            <div className="w-70 mx-auto">
              <p className="text-center mt-2 fs-5">Sign up</p>
              <button
                onClick={async () => {
                  const res = await authServices.registerWithGoogle({
                    success,
                    error,
                  });
                  if (res) navigate("/");
                }}
                className="btn border w-100 mt-2 w-sm w-md"
              >
                <img
                  src={assets.google}
                  alt="google"
                  width={20}
                  className="pe-1"
                />
                Sign up with Google
              </button>
            </div>
            <p className="or mt-2">Or</p>
            <form>
              <Input
                labelFor="name"
                containerClass="mt-3"
                className="mt-2"
                label="Full name"
                placeholder="John Doe"
                value={regData.fullName}
                onChange={(fullName) => {
                  setRegData((state: any) => {
                    return { ...state, fullName };
                  });
                }}
              />
              <Input
                labelFor="email"
                containerClass="mt-3"
                className="mt-2"
                label="Email Address"
                placeholder="example@gmail.com"
                type={"email"}
                value={regData.email}
                onChange={(email) => {
                  setRegData((state: any) => {
                    return { ...state, email };
                  });
                }}
              />
              <Input
                labelFor="phone"
                containerClass="mt-3"
                className="mt-2"
                label="Phone number"
                placeholder="0908875568"
                type={"number"}
                value={regData.phone}
                maxLength={11}
                onChange={(phone) => {
                  setRegData((state: any) => {
                    return { ...state, phone };
                  });
                }}
              />
              <Input
                label="Password"
                containerClass="mt-3 mx-auto"
                className="mt-2"
                type={"password"}
                placeholder="********"
                value={regData.password}
                icon={<BsFillEyeSlashFill size={20} />}
                onChange={(password) => {
                  setRegData((state: any) => {
                    return { ...state, password };
                  });
                }}
              />
              <Row className="mt-3">
                <Col>
                  <input
                    type={"checkbox"}
                    defaultChecked={agree}
                    onChange={() => setAgree(!agree)}
                    className="form-check-input ps-2 mt-lg-2"
                  />
                </Col>
                <Col lg={10} sm={9}>
                  <label htmlFor="check" className="form-check-label fs-7 text-right">
                    By creating an account you agree to the terms of use and our
                    privacy policy.
                  </label>
                </Col>
              </Row>
              <CustomButton
                className="mt-3"
                onClick={async (e) => {
                  e.preventDefault();
                  if (agree) {
                    const res = await authServices.register(regData, {
                      warning,
                      success,
                      setLoading,
                      error,
                    });
                    if (res) {
                      navigate(routes.otp, {
                        state: {
                          email: res?.email,
                          from: "register",
                        },
                      });
                    }
                  } else
                    warning("Agree to terms of use and our privacy policy");
                }}
                width={70}
                marginTop={1}
                loadingText="Loading"
                isLoading={loading}
                disabled={loading}
                center
              >
                Create account
              </CustomButton>
            </form>

            <p className="text-center pt-2">
              Already have an account?
              <Link to={"/auth/login"} className="text-dec-none">
                <span className="text-theme ms-1">Log in</span>
              </Link>
            </p>
          </ScrollView>
        </Col>
        <ResponsiveRender>
          <Col
            lg={9}
            md={8}
            id={SCREEN_SIZE.isXLargeDesktop}
            className="vh-100"
          >
            <img
              src={assets.doc_1}
              alt="doctor-1"
              className="w-100 img-fluid h-100 img-fit-top"
            />
          </Col>
        </ResponsiveRender>
      </Row>
    </Container>
  );
};

export default Register;
