import React from "react";
import { Col, Row } from "react-bootstrap";
import { assets } from "../../assets/assets";
import CustomButton from "../../components/customButton";
import CustomTable from "../../components/customTable";
import More from "../../components/more";
import Tab from "../../components/tab";
import { useGetScreen, useNotification } from "../../hooks/app";
import useAtomState from "../../hooks/state";
import appServices from "../../services/app.service";
import { AppointmentStatus } from "../../utils/utils";

const Appointment: React.FC = () => {
  const {
    appointment,
    setAppointment,
    showAppointment,
    setShowAppointment,
    setShowNotificationDetailsSidebar,
    setAppointmentDetails,
  } = useAtomState();
  const { success, warning, error } = useNotification();
  const { isMobilePortrait } = useGetScreen();
  const [query, setQuery] = React.useState("");

  return (
    <div className="w-95 mx-auto">
      <Row>
        <Col>
          <Tab
            tabs={[
              {
                name: "Upcoming",
                className: "mt-4 border-0",
                onClick: () => setQuery("upcoming"),
              },
              {
                name: "Recent",
                className: "mt-4 border-0",
                onClick: () => setQuery("recent"),
              },
              {
                name: "All Appointment",
                className: "mt-4 border-0",
                active: true,
                onClick: () => setQuery(""),
              },
            ]}
          />
        </Col>
        <Col lg={4} md={4}>
          <CustomButton
            onClick={() => setShowAppointment(!showAppointment)}
            marginTop={4}
          >
            New appointment
          </CustomButton>
        </Col>
      </Row>

      <CustomTable
        query={query}
        type="appointment"
        setData={setAppointment}
        screen={{
          isLargeDesktop: "73vh",
          isMediumPortrait: "73vh",
          isMobilePortrait: "90vh",
        }}
      >
        <tbody>
          {appointment?.length === 0 ? (
            <tr>
              <td colSpan={7}>
                <h4 className="text-center pt-5">You have no appointment</h4>
              </td>
            </tr>
          ) : (
            appointment?.map(
              (
                {
                  appointmentDate,
                  appointmentStatus,
                  appointmentTime,
                  patient,
                  id,
                  doctor_service,
                  isApproved,
                  payment,
                },
                ind: number
              ) => {
                return (
                  <tr className="shadow-sm bg-white p-2" key={ind}>
                    <td>
                      <div className="pt-3 ps-2">
                        <p className="fs-7 lh-1">{appointmentDate}</p>
                        <p className="fs-7 lh-0">{appointmentTime}</p>
                        {isMobilePortrait && (
                          <p className="fs-7 pt-3 lh-0">Male</p>
                        )}
                      </div>
                    </td>
                    <td rowSpan={1}>
                      <div className="mt-3">
                        <img
                          src={patient?.picture}
                          alt="user"
                          className="rounded-4 d-inline-block"
                          width={30}
                          height={30}
                        />
                      </div>
                    </td>
                    <td>
                      <div className="mt-3">
                        <span className="fs-7 ps-2">{patient?.fullName}</span>
                        {isMobilePortrait && (
                          <p
                            className={`fs-7 mt-3 ${
                              appointmentStatus ===
                                AppointmentStatus.UP_COMING && "green-text"
                            }
                          ${
                            appointmentStatus === AppointmentStatus.COMPLETED &&
                            "yellow-text"
                          }
                          ${
                            appointmentStatus === AppointmentStatus.PENDING &&
                            "pink-text"
                          }
                          `}
                          >
                            {appointmentStatus}
                          </p>
                        )}
                      </div>
                    </td>
                    {!isMobilePortrait && (
                      <td>
                        <p
                          className={`fs-7 mt-3 me-5 ${
                            appointmentStatus === AppointmentStatus.UP_COMING &&
                            "green-text"
                          }
                          ${
                            appointmentStatus === AppointmentStatus.COMPLETED &&
                            "yellow-text"
                          }
                          ${
                            appointmentStatus === AppointmentStatus.PENDING &&
                            "pink-text"
                          }
                          `}
                        >
                          {appointmentStatus}
                        </p>
                      </td>
                    )}
                    {!isMobilePortrait && (
                      <td>
                        <p className="fs-7 pt-4">Male</p>
                      </td>
                    )}
                    <td>
                      {/* TODO: A doctor can only delete pending and completed request */}
                      <More
                        className={"pt-1"}
                        drop="down"
                        more={[
                          {
                            classN: `appointment-approval ${
                              isApproved === "Yes" ? "d-none" : "d-block"
                            }`,
                            name: (
                              <span style={{ color: "#3A974C" }}>
                                Acknowledge
                              </span>
                            ),
                            onClick: async () =>
                              appServices.approveDoctorAppointment(
                                id as number,
                                setAppointment
                              ),
                            img: assets.check_icon,
                          },
                          {
                            classN: "appointment-details",
                            name: (
                              <span style={{ color: "#F4BD0B" }}>Details</span>
                            ),
                            onClick: async () => {
                              setShowNotificationDetailsSidebar((state) => {
                                return {
                                  ...state,
                                  show: true,
                                };
                              });
                              setAppointmentDetails({
                                appointmentDate,
                                appointmentStatus,
                                appointmentTime,
                                doctor_service,
                                patient,
                                payment: payment,
                              });
                            },
                            img: assets.details_icon,
                          },
                          {
                            name: <span className="text-theme">Delete</span>,
                            onClick: async () => {
                              const res = await appServices.deleteAppointment(
                                `${id}`,
                                {
                                  warning,
                                  success,
                                  error,
                                }
                              );
                              if (res) {
                                appServices.refetchAppointment(setAppointment);
                              }
                            },
                            img: assets.del_icon,
                            classN: `${
                              appointmentStatus === AppointmentStatus.COMPLETED
                                ? "d-block"
                                : "d-none"
                            }`,
                          },
                        ]}
                      />
                    </td>
                  </tr>
                );
              }
            )
          )}
        </tbody>
      </CustomTable>
    </div>
  );
};

export default Appointment;
