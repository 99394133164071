import React, { Fragment } from "react";
import { Table } from "react-bootstrap";
import { useGetScreen } from "../hooks/app";

const Loader: React.FC<{
  type?:
    | "btn"
    | "loading"
    | "skeleton-table"
    | "skeleton-service"
    | "skeleton-patient-card";
  fill?: number;
}> = ({ type = "btn", fill }) => {
  const { isMobilePortrait } = useGetScreen();

  return (
    <Fragment>
      {type === "btn" && (
        <div className={`lds-ring`}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      {type === "loading" && (
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      {type === "skeleton-service" && (
        <div
          className={`d-flex ${
            isMobilePortrait ? "flex-column" : "flex-row"
          } justify-content-around flex-wrap`}
        >
          {new Array(fill).fill(fill).map((data, ind) => {
            return (
              <div
                key={ind}
                className={`${
                  isMobilePortrait
                    ? "d-flex flex-column w-100 mt-5"
                    : "d-flex flex-column w-40 mt-5"
                } bg-white rounded-3 m-2 p-2`}
              >
                <div className="d-flex flex-row justify-content-between">
                  <div className="d-flex flex-row w-100">
                    <div className="skeleton-loader-service-icon"></div>
                    <div className="skeleton-text__body">
                      <div className="skeleton-text skeleton ms-2 mt-1 w-50"></div>
                      <div className="skeleton-text skeleton ms-2 mt-1 w-30"></div>
                    </div>
                  </div>
                  <div className="skeleton-text__body">
                    <div className="skeleton-text skeleton ms-2 mt-1 w-90"></div>
                    <div className="skeleton-text skeleton ms-2 mt-1 w-90"></div>
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-between mt-3">
                  <div className="skeleton-loader-service-button skeleton-text skeleton"></div>
                  <div className="skeleton-loader-service-button skeleton-text skeleton"></div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {type === "skeleton-table" && (
        <Table className="mt-5 p-2">
          <tbody>
            {new Array(fill).fill(fill).map((data, ind) => {
              return (
                <tr className="shadow-sm bg-white p-3 w-100 mt-1" key={ind}>
                  <td className="w-10">
                    <div
                      className="ms-3 skeleton mt-1 m-2"
                      style={{ width: 50, height: 50, borderRadius: 10 }}
                    ></div>
                  </td>
                  <td className="w-20">
                    <div className="skeleton-text skeleton mt-3"></div>
                  </td>
                  {!isMobilePortrait && (
                    <>
                      <td className="w-20">
                        <div className="skeleton-text skeleton ms-2 mt-3"></div>
                      </td>
                      <td className="w-20">
                        <div className="skeleton-text skeleton ms-3 mt-3"></div>
                      </td>
                    </>
                  )}
                  <td className="w-10">
                    <div className="skeleton-text skeleton ms-4 mt-3"></div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
      {type === "skeleton-patient-card" && (
        <React.Fragment>
          {new Array(fill).fill(fill).map((data, ind) => {
            return (
              <div
                className="d-flex flex-row justify-content-between mt-2"
                key={ind}
              >
                <div className="skeleton-img skeleton"></div>
                <div className="skeleton-text__body mt-3 me-4">
                  <div className="skeleton-text skeleton"></div>
                  <div className="skeleton-text skeleton"></div>
                </div>
              </div>
            );
          })}
        </React.Fragment>
      )}
    </Fragment>
  );
};

export default Loader;
