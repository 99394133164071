import { Row, Col, Container } from "react-bootstrap";
import { UserDetailsSidebarProps } from "../../@types/props";
import { assets } from "../../assets/assets";
import useAtomState from "../../hooks/state";
import CustomButton from "../customButton";
import Input from "../input";
import Sidebar from "./sidebar";

const UserDetailsSidebar: React.FC<UserDetailsSidebarProps> = ({ show }) => {
  const {
    bookAppointment,
    setBookAppointment,
    setShowBookAppointment,
    setShowUserDetails,
  } = useAtomState();
  return (
    <Sidebar show={show} handleClose={setShowUserDetails} placement="end">
      <Row>
        <Col lg={2}>
          <img
            onClick={() => setShowUserDetails(false)}
            src={assets.left_icon}
            alt="left-icon"
            width={45}
            height={45}
            className="cursor-p"
          />
        </Col>
        <Col>
          <h3 className="pt-1">Your details</h3>
          <p>Please fill in the form below</p>
        </Col>
      </Row>
      <hr />
      <Container>
        <form>
          <Input
            containerClass="mt-3"
            type={"text"}
            value={bookAppointment.fullName}
            onChange={(fullName) => {
              setBookAppointment((state: any) => {
                return { ...state, fullName };
              });
            }}
            label="Full name"
            placeholder="Your name"
          />
          <Input
            containerClass="mt-3"
            type={"number"}
            label="Phone number"
            placeholder="Your number"
            maxLength={11}
            className="mt-2"
            value={bookAppointment.phone}
            onChange={(phone) => {
              setBookAppointment((state: any) => {
                return { ...state, phone };
              });
            }}
          />
          <Input
            type="select"
            label="Gender"
            placeholder="Select Gender"
            options={[
              { name: "Male", label: "Male", value: "Male" },
              { label: "Female", name: "Female", value: "Female" },
            ]}
            className="mt-2"
            value={bookAppointment.gender}
            onChange={(gender) => {
              setBookAppointment((state: any) => {
                return { ...state, gender };
              });
            }}
          />
          <Input
            containerClass="mt-3"
            type={"date"}
            label="Date of birth"
            placeholder="Email"
            className="mt-2"
            value={bookAppointment.dob}
            onChange={(dob) => {
              setBookAppointment((state: any) => {
                return { ...state, dob };
              });
            }}
          />
          <CustomButton
            onClick={async () => {
              if (
                bookAppointment.fullName &&
                bookAppointment.dob &&
                bookAppointment.gender &&
                bookAppointment.phone
              ) {
                setShowBookAppointment(true);
                setShowUserDetails(false);
              }
            }}
            type="button"
            className="mt-3"
            width={90}
            center
          >
            Submit
          </CustomButton>
        </form>
      </Container>
    </Sidebar>
  );
};

export default UserDetailsSidebar;
