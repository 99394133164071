import {
  Notifications,
  StorageData,
  StoreObject,
  StrickCheck,
} from "../@types";

export default abstract class BaseService {
  private STORED_DATA: StorageData = {
    accessToken: "",
    user: {
      email: "",
      fullName: "",
      gender: "",
      phone: "",
      state: "",
      businessName: "",
      cityId: 0,
      clinicAddress: "",
      doctorBio: "",
      facebook: "",
      instagram: "",
      status: "pending",
      twitter: "",
      uniqueRouteNumber: "",
      verificationCode: "",
      verified: false,
      avatar: "",
    },
    authentication: {
      strategy: "local",
      accessToken: "",
    },
    selected_bank_id: `${0}`,
    notification: {
      length: 0,
      isCheck: true,
    },
  };


  PHONE_REGEX = /^[0-9\b]+$/;
  NUMBER_REGEX = /^[0-9]*$/;
  EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  /**
   * Function that sets localStorage
   * @param payload `Stored data`
   */
  public storeData(payload: StoreObject) {
    const store = localStorage as Storage;
    Object.keys(payload).forEach((key) => {
      store.setItem(key, JSON.stringify(payload[key]));
    });
  }

  /**
   * Function called to get stored data from localStorage
   * @returns `Stored data`
   */
  public getStoredData(): StorageData {
    const storage = localStorage as Storage;
    const data = Object.keys(this.STORED_DATA).reduce((prev, acc) => {
      return {
        ...prev,
        [acc]: JSON.parse(storage.getItem(acc) as string),
      };
    }, {});
    return data;
  }

  /**
   * Function called to clear an item from the localStorage
   * @param payload `strings[]`
   */
  public clearStoredItem(payload: StoreObject) {
    const storage = localStorage as Storage;
    Object.keys(payload).forEach((key) => {
      storage.removeItem(key);
    });
  }

  public isAuthenticated() {
    const user = this.getStoredData();
    if (user.accessToken && user.user?.email) {
      return true;
    }

    return false;
  }

  /**
   * Handles api calls to serve
   * @param param - path, headers, method, body, type
   * @returns Response
   */
  protected async fetchHandler({
    path,
    headers,
    method,
    body,
    type,
    baseUrl,
  }: {
    path: string;
    body?: any;
    method?: "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
    headers?: any;
    type?: string;
    baseUrl?: string;
  }) {
    const url = `${baseUrl || process.env.REACT_APP_BASE_URL}${path}`;
    type = type ?? "json";
    method = method ?? "POST";
    headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization:
        (await this.getStoredData().accessToken) ?? headers?.Authorization,
    };
    const resp = await fetch(url, {
      body: type === "json" ? JSON.stringify(body) : body,
      method,
      headers,
    }).catch((error: any) => {
      throw new Error(error);
    });

    return resp;
  }

  /**
   * Handles response gotten from sever
   * @param res - response from fetchHandler
   * @param actions
   * @param metaData
   * @returns data
   */
  protected async handleResponse(res?: Response | void) {
    if (res) {
      const data = await res.json();
      if (!data.code) {
        return data;
      }
      if (data?.data?.name === "TokenExpiredError") {
        localStorage.clear();
        window.location.assign("/");
        return;
      }
      throw new Error(data.message);
    }
  }

  /**
   * Checks data and confirm all its value exist
   * @param data
   * @param customKey a value to be displayed instead of default key
   * @param config
   * @returns
   */
  public async strictCheck(params: StrickCheck) {
    const { data, config, customKey } = params;
    const validation = (value: string, key: string) => {
      if (config?.validations) {
        if (Array.isArray(config.validations)) {
          if (
            config?.validations?.indexOf(key) !== -1 &&
            key === "phoneNumber"
          ) {
            if (
              value.length !== 11 ||
              !this.regexTest({ value, type: "PHONE" })
            ) {
              throw new Error("Invalid phone format");
            }
          }
          if (config?.validations?.indexOf(key) !== -1 && key === "email") {
            if (!this.regexTest({ value, type: "EMAIL" })) {
              throw new Error("Invalid email format");
            }
          }
        }
      }
    };

    if (!Array.isArray(data)) {
      for (const key in data) {
        if (
          config?.skipCheck?.indexOf(key) !== -1 &&
          (config?.skipCheck?.length as number) > 0
        ) {
          continue;
        }
        if (
          data[key] === "" ||
          typeof data[key] === "undefined" ||
          data[key] === null
        ) {
          if (customKey) {
            return {
              status: false,
              error: `${customKey[key]} is required`,
              value: key,
            };
          }
          return {
            status: false,
            error: `${key} is required`,
          };
        }
        validation(data[key], key);
      }
      return { status: true, data };
    }
  }

  public regexTest({
    value,
    type,
  }: {
    value: string;
    type: "PHONE" | "NUMBER" | "EMAIL";
  }) {
    if (type === "PHONE") {
      return this.PHONE_REGEX.test(value);
    }
    if (type === "NUMBER") {
      return this.NUMBER_REGEX.test(value);
    }
    if (type === "EMAIL") {
      return this.EMAIL_REGEX.test(value);
    }
  }

  protected async imageUploader(image: any) {
    const body = new FormData();
    body.append("file", image);
    body.append(
      "upload_preset",
      process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET as string
    );
    const res = await fetch(
      `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_NAME}/image/upload`,
      {
        method: "POST",
        body,
      }
    );
    const data = await res.json();
    if (data.url) {
      return data.url;
    }
  }

  /**
   * Combination of `HTTP POST` request logic
   * @param payload
   * @param path
   * @param notifications
   * @param msg
   * @param config
   * @returns response
   */
  protected async mergePostResponse(
    payload: any,
    path: string,
    notifications?: Notifications,
    msg?: string,
    config?: {
      validations?: string[];
      skipCheck?: string[];
    }
  ) {
    try {
      notifications?.setLoading?.(true);
      const check = await this.strictCheck({ data: payload, config });
      if (check?.data) {
        const res = await this.fetchHandler({
          path,
          body: check.data,
        });
        const resp = await this.handleResponse(res);
        if (resp) {
          notifications?.success?.(resp.message || (msg as string));
          notifications?.setLoading?.(false);
          return resp;
        }
      }

      notifications?.setLoading?.(false);
      notifications?.warning?.(check?.error as string);
    } catch (error: any) {
      notifications?.setLoading?.(false);
      notifications?.error?.(error?.message);
    }
  }

  public getBaseUrl = (join?: string) => {
    const base = window.location.origin;
    return `${base}${join}`;
  };
}
