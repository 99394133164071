import { Row, Col, Container } from "react-bootstrap";
import { UserDetailsSidebarProps } from "../../@types/props";
import { assets } from "../../assets/assets";
import { useNotification } from "../../hooks/app";
import useAtomState from "../../hooks/state";
import appServices from "../../services/app.service";
import CustomButton from "../customButton";
import Input from "../input";
import Sidebar from "./sidebar";

const UserAppointmentSidebar: React.FC<UserDetailsSidebarProps> = ({
  show,
}) => {
  const {
    bookAppointment,
    setBookAppointment,
    setShowBookAppointment,
    setShowUserDetails,
  } = useAtomState();
  const { loading, setLoading, success, error, warning } = useNotification();
  return (
    <Sidebar show={show} handleClose={setShowBookAppointment} placement="end">
      <Row>
        <Col lg={2}>
          <img
            onClick={() => setShowBookAppointment(false)}
            src={assets.left_icon}
            alt="left-icon"
            width={45}
            height={45}
            className="cursor-p"
          />
        </Col>
        <Col>
          <h3 className="pt-1">Appointment details</h3>
          <p>Please fill in the form below </p>
        </Col>
      </Row>
      <hr />
      <Container>
        <form>
          <Input
            type={"select"}
            className="mt-2"
            options={[
              {
                name: bookAppointment.serviceName,
                label: bookAppointment.serviceName,
                value: bookAppointment.serviceName,
              },
            ]}
            placeholder={bookAppointment.serviceName}
            value={bookAppointment.serviceName}
          />
          <Row className="mt-1">
            <Col>
              <Input
                containerClass="ms-2 mt-3"
                label="Preferred date"
                className="mt-2"
                type={"date"}
                value={bookAppointment.appointmentDate}
                onChange={(appointmentDate) => {
                  setBookAppointment((state: any) => {
                    return { ...state, appointmentDate };
                  });
                }}
              />
            </Col>
            <Col>
              <Input
                containerClass="ms-2 mt-3"
                className="mt-2"
                label="Preferred time"
                type={"time"}
                value={bookAppointment.appointmentTime}
                onChange={(appointmentTime) => {
                  setBookAppointment((state: any) => {
                    return { ...state, appointmentTime };
                  });
                }}
              />
            </Col>
          </Row>
          <Input
            type={"select"}
            value="Paystack"
            className="mt-2"
            options={[
              { name: "Paystack", value: "Paystack", label: "Paystack" },
            ]}
            label="Select a payment method"
          />
          <Input
            containerClass="ms-2 mt-3"
            className="mt-2"
            type={"textarea"}
            value={bookAppointment.patientNote}
            onChange={(patientNote) => {
              setBookAppointment((state: any) => {
                return { ...state, patientNote };
              });
            }}
            label="Additional note"
            placeholder="Type here"
          />
          <CustomButton
            loadingText="Loading"
            isLoading={loading}
            disabled={loading}
            onClick={async () => {
              const res = await appServices.bookAppointment(bookAppointment, {
                success,
                warning,
                setLoading,
                error,
              });
              if (res) {
                setShowBookAppointment(false);
                setShowUserDetails(false);
              }
            }}
            type="button"
            className="mt-3"
            width={90}
            center
          >
            Submit
          </CustomButton>
        </form>
      </Container>
    </Sidebar>
  );
};

export default UserAppointmentSidebar;
