import { useLocation, useNavigate } from "react-router-dom";
import { LayoutProps } from "../../@types/props";
import useAtomState from "../../hooks/state";
import authServices from "../../services/auth.service";
import {
  appRoutes,
  onboardingRoutes,
  routes,
} from "../../utils/router";
import Footer from "../footer";
import AppLayout from "./app";
import OnboardingLayout from "./onboarding";
import UserLayout from "./user";

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isLoggedIn } = useAtomState();

  const isAuthenticated = authServices.isAuthenticated() || isLoggedIn;
  if (
    onboardingRoutes.indexOf(location?.pathname as string) !== -1 &&
    isAuthenticated
  ) {
    return (
      <OnboardingLayout navigate={navigate} location={location}>
        {children}
      </OnboardingLayout>
    );
  }
  if (
    appRoutes.indexOf(location?.pathname as string) !== -1 &&
    isAuthenticated
  ) {
    return (
      <AppLayout navigate={navigate} location={location}>
        {children}
      </AppLayout>
    );
  }

  if (routes.userServices === location.pathname.substring(0, 8) + ":id") {
    return <UserLayout>{children}</UserLayout>;
  }

  return (
    <div className="vh-100 position-relative">
      {children}
      {[routes.otp, routes.forgotPassword].indexOf(
        location?.pathname as string
      ) !== -1 && <Footer />}
    </div>
  );
};

export default Layout;
