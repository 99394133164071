import { Notifications } from "../@types";
import { BankListDto, TransactionsDto } from "../@types/dto";
import BaseService from "./base.service";

class TransactionService extends BaseService {
  /**
   * Function that converts a string to have commas
   * @param x string to be converted to string with commas
   * @returns
   */
  public numberWithCommas(x: any = 0) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  /**
   * Function called to verify user payment
   * @param token token received from paystack or payment api
   * @param notifications
   */
  public async verifyPayment(token: string, notifications?: Notifications) {
    try {
      const res = await this.fetchHandler({
        path: `verify-payment/${token}`,
        method: "GET",
      });
      const resp = await this.handleResponse(res);
      if (resp.data.status) {
        notifications?.setData?.("success");
      } else {
        notifications?.setData?.("failed");
      }
    } catch (error) {
      notifications?.setData?.("failed");
    }
  }

  /**
   * Function called to get all banks available on the platform
   * @returns
   */
  public async getAllBanks() {
    try {
      const res = await this.fetchHandler({
        path: "customer/banklist",
        method: "GET",
      });
      const resp = await this.handleResponse(res);
      return resp.data.map((data: any) => {
        return {
          name: data?.name,
          label: data?.name,
          value: data.code,
        };
      });
    } catch (error) {}
  }

  /**
   * Function called to add doctors bank
   * @param data
   * @param notifications
   * @returns
   */
  public async addBank(
    data: { bankCode: string; accountNumber: string },
    notifications?: Notifications,
    setData?: any
  ) {
    const res = await this.mergePostResponse(
      data,
      "customer-bank",
      notifications,
      "Bank added successfully"
    );
    this.refetchBankDetails(setData);
    return res;
  }

  /**
   * Function called to handle doctors wallet/Account internally
   * @returns
   */
  protected async getDoctorsWalletDetails() {
    try {
      const res = await this.fetchHandler({
        path: "customer/mybank-list/me",
        method: "GET",
      });
      const resp = await this.handleResponse(res);
      return resp.data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  /**
   * Function called to handle getting doctors bank details
   * @returns
   */
  public async getDoctorsBankDetails(): Promise<BankListDto[] | undefined> {
    try {
      const res = await this.getDoctorsWalletDetails();
      return res.myBankList;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  /**
   * Function to handle wallet account details
   * @returns
   */
  public async getWalletAccount() {
    const resp = await this.getDoctorsWalletDetails();
    return {
      availableBalance: this.numberWithCommas(
        resp.availableBalance[0].totalAmount ?? 0
      ),
      pendingBalance: this.numberWithCommas(
        resp.pendingBalance[0].totalAmount ?? 0
      ),
    };
  }

  public refetchBankDetails = async (setData: any) => {
    const res = await this.getDoctorsBankDetails();
    setData(res);
  };

  /**
   * Deletes doctors bank details
   * @param id
   * @param notifications
   * @param setData
   * @returns
   */
  public async deleteDoctorsBank(
    id: number,
    notifications: Notifications,
    setData?: any
  ) {
    try {
      notifications.setLoading?.(true);
      const res = await this.fetchHandler({
        path: `customer-bank/${id}`,
        method: "DELETE",
      });
      const resp = await this.handleResponse(res);
      if (resp) {
        notifications?.success?.("Bank deleted successfully");
        notifications?.setLoading?.(false);
        this.refetchBankDetails(setData);
        return resp;
      }
    } catch (error) {}
  }

  public setCurrentBank(data: any, setData: (data: any) => void) {
    this.storeData({
      selected_bank_id: `${data.id}`,
    });
    setData(`${data.id}`);
  }

  /**
   * Handles doctors transfer
   * @param data
   * @param notifications
   * @returns
   */
  public async makeDoctorTransfer(
    data: {
      customerBankId: number;
      transferNote?: string;
    },
    notifications: Notifications
  ) {
    try {
      const res = await this.mergePostResponse(
        data,
        "doctor/withdrawal",
        notifications,
        "Transfer requested successfully"
      );
      return res;
    } catch (error) {}
  }

  /**
   * Get all transactions of a doctor
   * @param query - `limit`,`skip`,`referenceNumber`,`transactionType`,`transactionDate`
   * @returns
   */
  public async getAllTransaction(query?: any): Promise<
    | {
        data: TransactionsDto[];
        total: number;
        limit: number;
        skip: number;
      }
    | undefined
  > {
    try {
      const res = await this.fetchHandler({
        path: `my-transactions/me${
          query?.limit ? `?$limit=${query?.limit}` : ""
        }${query?.skip ? `&$skip=${query?.skip}` : ""} ${
          query?.query?.referenceNumber
            ? `&referenceNumber=${query.query?.referenceNumber}`
            : ""
        }${
          query?.query?.transactionType
            ? `&transactionType=${query?.query?.transactionType}`
            : ""
        }${
          query?.query?.transactionDate
            ? `&transactionDate=${query?.query?.transactionDate}`
            : ""
        }`,
        method: "GET",
      });
      let resp = await this.handleResponse(res);
      if (resp) {
        return {
          data: resp.data.data,
          total: resp.data.total,
          limit: resp.data.limit,
          skip: resp.data.skip,
        };
      }
    } catch (error) {}
  }

  public async requestFunds(
    id?: string | number,
    notification?: Notifications
  ) {
    notification?.setLoading?.(true);
    try {
      const res = await this.fetchHandler({
        path: `doctor/deliver-service`,
        body: { transactionId: id },
      });
      const resp = await this.handleResponse(res);
     if(resp){
      notification?.setLoading?.(false)
      notification?.success?.(resp.message)
     }
    } catch (error: any) {
      notification?.setLoading?.(false);
      notification?.error?.(error.e);
    }
  }
}
const transactionService = new TransactionService();
export default transactionService;
