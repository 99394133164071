export const assets = {
  logo: require("./img/logo.png"),
  doc_1: require("./img/doc_1.png"),
  doc_2: require("./img/doc_2.png"),
  doc_3: require("./img/doc_3.png"),
  google: require("./img/google.png"),
  success_1: require("./img/success_1.png"),
  plus_1: require("./img/plus_1.png"),
  calendar: require("./img/calendar.png"),
  time: require("./img/time.png"),
  mark_icon: require("./img/mark_icon.png"),
  naira_icon: require("./img/naira_icon.png"),
  del_icon: require("./img/delete_icon.png"),
  edit_icon: require("./img/edit_icon.png"),
  braces: require("./img/braces.png"),
  eye_icon: require("./img/eye_icon.png"),
  bag_icon: require("./img/bag_icon.png"),
  pink_calendar: require("./img/pink_calendar.png"),
  mock_avatar_7: require("./img/avatar_7.png"),
  img_holder: require("./img/img_holder.png"),
  ava_bal: require("./img/ava_bal.png"),
  pending: require("./img/pending.png"),
  bank_icon: require("./img/bank_icon.png"),
  add_icon: require("./img/add_icon.png"),
  left_icon: require("./img/left_icon.png"),
  logout_icon: require("./img/logout_icon.png"),
  hide_icon: require("./img/hide_icon.png"),
  onboard_img1: require("./img/onboard_img1.png"),
  onboard_img2: require("./img/onboard_img2.png"),
  patient_icon: require("./img/patient_icon.png"),
  input_date_icon: require("./img/input_date_icon.png"),
  instagram_icon: require("./img/instagram_icon.png"),
  twitter_icon: require("./img/twitter_icon.png"),
  whatsapp_icon: require("./img/whatsapp_icon.png"),
  service_card_holder: require("./img/service_card_holder.png"),
  box_1: require("./img/box_1.png"),
  box_2: require("./img/box_2.png"),
  box_3: require("./img/box_3.png"),
  box_4: require("./img/box_4.png"),
  onboarding_success_1: require("./img/onboarding_success_1.png"),
  onboarding_success_2: require("./img/onboarding_success_2.png"),
  onboarding_success_3: require("./img/onboarding_success_3.png"),
  onboarding_success_4: require("./img/onboarding_success_4.png"),
  onboarding_success_5: require("./img/onboarding_success_5.png"),
  shocked_emoji: require("./img/shocked_emoji.png"),
  smile_emoji: require("./img/smile_emoji.png"),
  selected_bank: require("./img/selected_bank.png"),
  check_icon: require("./img/check_icon.png"),
  details_icon: require("./img/details_icon.png"),
};
