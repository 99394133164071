import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { assets } from "../../assets/assets";
import CustomButton from "../../components/customButton";
import { useGetScreen } from "../../hooks/app";
import { routes } from "../../utils/router";

const Success: React.FC = () => {
  const navigate = useNavigate();
  const { isMobilePortrait } = useGetScreen();
  return (
    <Container as={"section"} fluid>
      <div>
        <img
          src={assets.onboarding_success_1}
          className="position-absolute start-10 top-30 w-7"
          alt="onboarding_success_1"
        />
        <img
          src={assets.onboarding_success_2}
          className="position-absolute start-10 top-60 w-10"
          alt="onboarding_success_1"
        />
        <img
          src={assets.onboarding_success_3}
          className="position-absolute end-10 top-10 w-10"
          alt="onboarding_success_1"
        />
        <img
          src={assets.onboarding_success_4}
          className="position-absolute end-10 top-50 w-7"
          alt="onboarding_success_1"
        />
        <img
          src={assets.onboarding_success_5}
          className="position-absolute end-20 top-80 w-10"
          alt="onboarding_success_1"
        />
      </div>
      <div className="w-50 mx-auto position-absolute top-50 start-50 translate-middle w-sm">
        <div className="w-30 mx-auto pt-3 w-sm">
          <img src={assets.success_1} alt="logo" className="w-100" />
        </div>
        <p className="mt-4 text-center">Account created successfully</p>
        <CustomButton
          onClick={() => {
            navigate(routes.login);
          }}
          width={isMobilePortrait ? 100 : 30}
          marginTop={3}
          className="mb-3"
          center
        >
          Login
        </CustomButton>
      </div>
    </Container>
  );
};
export default Success;
