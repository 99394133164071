import React from "react";
import { Col, Row } from "react-bootstrap";
import { assets } from "../../assets/assets";
import CustomButton from "../../components/customButton";
import CustomTable from "../../components/customTable";
import More from "../../components/more";
import { useGetScreen, useNotification } from "../../hooks/app";
import useAtomState from "../../hooks/state";
import appServices from "../../services/app.service";

const Patients: React.FC = () => {
  const { showPatient, setShowPatient, patients, setPatients, setAddPatient } =
    useAtomState();
  const { success, warning, error } = useNotification();
  const { isMobilePortrait } = useGetScreen();

  return (
    <div className="w-95 mx-auto">
      <Row className="justify-content-end">
        <Col lg={4} md={4}>
          <CustomButton
            className="mb-2"
            marginTop={3}
            onClick={() => {
              setAddPatient((state: any) => {
                return {
                  ...state,
                  type: "add",
                  phone: "",
                  email: "",
                  gender: "",
                  picture: "",
                  fullName: "",
                  id: "",
                };
              });
              setShowPatient(!showPatient);
            }}
          >
            <span>
              <img src={assets.plus_1} width={15} alt="plus" />
            </span>
            <span className="ps-2">Add patients</span>
          </CustomButton>
        </Col>
      </Row>
      <CustomTable
        type="patients"
        setData={setPatients}
        screen={{
          isLargeDesktop: "73vh",
          isMediumPortrait: "73vh",
          isMobilePortrait: "90vh",
        }}
      >
        <tbody>
          {patients?.length === 0 ? (
            <tr>
              <td colSpan={7}>
                <h4 className="text-center pt-5">You have no patient</h4>
              </td>
            </tr>
          ) : (
            patients?.map(({ email, fullName, gender, picture, phone, id }) => {
              return (
                <tr key={id} className="shadow-sm bg-white p-2">
                  <td>
                    <div className="pt-3 ps-3">
                      <img
                        src={picture}
                        alt="user"
                        className="rounded-4 d-inline-block"
                        width={30}
                        height={30}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="pt-3 ps-3">
                      <span className="fs-7 ps-2">{fullName}</span>
                    </div>
                  </td>
                  {!isMobilePortrait && (
                    <>
                      <td>
                        <p className="fs-7 pt-4">Email- Admin only</p>
                      </td>
                      <td>
                        <p className="fs-7 pt-4">Phone- Admin only</p>
                      </td>
                    </>
                  )}
                  <td>
                    <p className="fs-7 mt-3 blue-text me-3">{gender}</p>
                  </td>
                  <td>
                    <More
                      className={"pt-1"}
                      drop='down'
                      more={[
                        {
                          name: <span style={{ color: "#5B93FF" }}>Edit</span>,
                          onClick: () => {
                            setAddPatient((state: any) => {
                              return {
                                ...state,
                                type: "edit",
                                phone,
                                email,
                                gender,
                                picture,
                                fullName,
                                id,
                              };
                            });
                            setShowPatient(true);
                          },
                          img: assets.edit_icon,
                        },
                        {
                          name: <span className="text-theme">Delete</span>,
                          img: assets.del_icon,
                          onClick: async () => {
                            const res = await appServices.deletePatients(
                              `${id}`,
                              { success, warning, error }
                            );
                            if (res) {
                              appServices.refetchPatients(setPatients);
                            }
                          },
                        },
                      ]}
                    />
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </CustomTable>
    </div>
  );
};

export default Patients;
