import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { EnquiriesSidebarProps } from "../../@types/props";
import { assets } from "../../assets/assets";
import { useNotification } from "../../hooks/app";
import useAtomState from "../../hooks/state";
import appServices from "../../services/app.service";
import CustomButton from "../customButton";
import Input from "../input";
import Sidebar from "./sidebar";

const EnquiriesSidebar: React.FC<EnquiriesSidebarProps> = ({ show }) => {
  const { enquiries, setEnquiries, setShowEnquiries } = useAtomState();
  const { loading, setLoading, success, warning, error } = useNotification();

  function setDoctorId() {
    setEnquiries({
      ...enquiries,
      doctorId: window.location.href.substring(29, 80),
    });
  }

  return (
    <Sidebar show={show} handleClose={setShowEnquiries} placement="end">
      <Row>
        <Col lg={2}>
          <img
            onClick={() => setShowEnquiries(false)}
            src={assets.left_icon}
            alt="left-icon"
            width={45}
            height={45}
            className="cursor-p"
          />
        </Col>
        <Col>
          <h3 className="pt-1">Enquiries</h3>
          <p>Please fill in the enquiries form </p>
        </Col>
      </Row>
      <hr />
      <Container>
        <form>
          <Input
            type={"text"}
            containerClass="mt-3"
            className="mt-2"
            label="Full name"
            placeholder="Your name"
            value={enquiries.fullName}
            onChange={(fullName) => {
              setEnquiries((state: any) => {
                return { ...state, fullName };
              });
            }}
          />
          <Input
            containerClass="mt-3"
            className="mt-2"
            type={"number"}
            label="Phone number"
            placeholder="Your number"
            maxLength={11}
            value={enquiries.phone}
            onChange={(phone) => {
              setEnquiries((state: any) => {
                return { ...state, phone };
              });
            }}
          />
          <Input
            type={"email"}
            containerClass="mt-3"
            className="mt-2"
            label="Email address"
            placeholder="Email"
            value={enquiries.email}
            onChange={(email) => {
              setEnquiries((state: any) => {
                return { ...state, email };
              });
            }}
          />
          <Input
            type={"textarea"}
            containerClass="mt-3"
            className="mt-2"
            label="Your message here"
            value={enquiries.note}
            onChange={(note) => {
              setEnquiries((state: any) => {
                return { ...state, note };
              });
            }}
          />
          <CustomButton
            isLoading={loading}
            disabled={loading}
            loadingText="Loading"
            onClick={async () => {
              setDoctorId();
              const res = await appServices.makeEnquiry(enquiries, {
                setLoading,
                success,
                warning,
                error,
              });
              if (res) {
                setShowEnquiries(false);
              }
            }}
            type="button"
            className="mt-3"
            width={60}
            center
          >
            Send
          </CustomButton>
        </form>
      </Container>
    </Sidebar>
  );
};

export default EnquiriesSidebar;
