import React from "react";
import { Col, Row, Stack } from "react-bootstrap";
import { SideBar } from ".";
import { NotificationSidebarProps } from "../../@types/props";
import useAtomState from "../../hooks/state";
import CustomButton from "../customButton";
import transactionService from "../../services/transaction.service";

const NotificationSidebarDetails: React.FC<NotificationSidebarProps> = ({
  data,
}) => {
  const {
    showNotificationDetailsSidebar,
    setShowNotificationDetailsSidebar,
    appointmentDetails,
  } = useAtomState();
  return (
    <SideBar
      show={showNotificationDetailsSidebar.show}
      handleClose={(e) => {
        setShowNotificationDetailsSidebar({
          ...showNotificationDetailsSidebar,
          show: e,
        });
      }}
      placement={"end"}
    >
      <CustomButton
        width={20}
        onClick={() => {
          setShowNotificationDetailsSidebar({
            ...showNotificationDetailsSidebar,
            show: false,
          });
        }}
        bgColor="bg-white"
        borderColor="border-black"
        textColor="text-black"
      >
        Back
      </CustomButton>
      <hr />
      <div className="w-95 mx-auto">
        <div className="w-45 mx-auto">
          <img
            src={appointmentDetails.patient?.picture}
            alt="patient"
            className="w-50 d-block mx-auto mb-4"
            style={{ borderRadius: "50%", height: 70 }}
          />
          <h6 className="text-center">
            {appointmentDetails.patient?.fullName}
          </h6>
          <p className="pink-text">
            {appointmentDetails.appointmentStatus
              ?.substring(0, 1)
              .toUpperCase()}
            {appointmentDetails.appointmentStatus?.substring(
              1,
              appointmentDetails.appointmentStatus.length
            )}{" "}
            appointment
          </p>
        </div>
        <Stack direction="vertical" className="mt-4">
          <Row className="mt-2">
            <Col>
              <p style={{ color: "#A0AEC0" }}>Service Booked</p>
            </Col>
            <Col>
              <p className="">
                {appointmentDetails.doctor_service?.serviceName}
              </p>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <p style={{ color: "#A0AEC0" }}>Date and Time</p>
            </Col>
            <Col>
              <p className="">{appointmentDetails.appointmentDate}</p>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <p style={{ color: "#A0AEC0" }}>Payment</p>
            </Col>
            <Col>
              <p className="">
                {transactionService.numberWithCommas(appointmentDetails?.payment?.transaction?.amount)}
              </p>
            </Col>
          </Row>
        </Stack>
        <hr />
        <Stack direction="vertical" className="mt-4">
          <Row className="mt-2">
            <Col>
              <p style={{ color: "#A0AEC0" }}>Name</p>
            </Col>
            <Col>
              <p className="">{appointmentDetails.patient?.fullName}</p>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <p style={{ color: "#A0AEC0" }}>Phone</p>
            </Col>
            <Col>
              <p className="">Admin - only</p>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <p style={{ color: "#A0AEC0" }}>Email</p>
            </Col>
            <Col>
              <p className="">Admin - only</p>
            </Col>
          </Row>
        </Stack>
      </div>
    </SideBar>
  );
};

export default NotificationSidebarDetails;
