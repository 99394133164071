import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { assets } from "../../assets/assets";
import CustomButton from "../../components/customButton";
import Loader from "../../components/loader";
import ScrollView from "../../components/scrollview";
import ServiceCard from "../../components/serviceCard";
import { useGetScreen, useNotification } from "../../hooks/app";
import useAtomState from "../../hooks/state";
import appServices from "../../services/app.service";

const UserServices: React.FC = () => {
  const {
    bookAppointment,
    setBookAppointment,
    doctorInfo,
    setDoctorInfo,
    setShowUserOnboarding,
    setShowEnquiries,
  } = useAtomState();
  const [pageLoading, setPageLoading] = React.useState(false);
  const { id } = useParams();
  const { error, success } = useNotification();
  const { isMobilePortrait } = useGetScreen();

  React.useEffect(() => {
    setPageLoading(true);
    Promise.all([appServices.getDoctorInfo(id as string)]).then(([doctor]) => {
      setDoctorInfo(doctor);
      setPageLoading(false);
    });
    // eslint-disable-next-line
  }, [id]);

  React.useEffect(() => {
    if (
      appServices.regexTest({
        value: bookAppointment.email as string,
        type: "EMAIL",
      })
    ) {
      appServices.recordProfileVisit(
        {
          doctorId: id as string,
          visitorEmail: bookAppointment.email as string,
        },
        { error, success }
      );
    }
    // eslint-disable-next-line
  }, [bookAppointment.email]);

  return (
    <div className="w-95 mx-auto">
      <ScrollView isLargeDesktop="100vh">
        <div className="w-100 mx-auto mt-3 user-header">
          <Row>
            <Col>
              <div className="mt-6 ms-5">
                <p className="text-white">Welcome to</p>
                <h1 className="text-white">Yanga Dental 👋🏻</h1>
                <p className="text-white fs-6 lh-1">
                  You can start by booking appointment with your dentist{" "}
                </p>
                <CustomButton
                  btnClass="p-3"
                  width={isMobilePortrait ? 60 : 40}
                  onClick={() => setShowEnquiries(true)}
                >
                  Make enquiry
                </CustomButton>
              </div>
            </Col>
            {!isMobilePortrait && (
              <Col>
                <Container
                  as={"section"}
                  className="d-flex flex-row justify-content-center mt-3"
                  fluid
                >
                  <div className="d-flex flex-row justify-content-center w-50">
                    <div className="px-2 d-flex align-items-center">
                      <div
                        // style={{ marginTop: 20 }}
                        className="card-size-user rounded-border card"
                      >
                        <img
                          src={assets.box_1}
                          alt="box holder"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </div>
                    <div className="px-2 d-flex flex-column">
                      <div className="card-size-user rounded-border mb-3 my-2 card">
                        <img
                          src={assets.box_4}
                          alt="box holder"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                      <div className="card-size-user rounded-border mt-3 my-2 card">
                        <img
                          src={assets.box_2}
                          alt="box holder"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </div>
                    <div className="px-2 d-flex align-items-center">
                      <div
                        // style={{ marginTop: 100 }}
                        className="card-size-user rounded-border card"
                      >
                        <img
                          src={assets.box_3}
                          alt="box holder"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    </div>
                  </div>
                </Container>
              </Col>
            )}
          </Row>
        </div>
        <ScrollView isLargeDesktop="70vh" className="w-100">
          {pageLoading ? (
            <Loader type="skeleton-service" fill={6} />
          ) : (
            <div className="flex-row-wrap">
              {doctorInfo?.doctor_services?.map((data, ind) => {
                return (
                  <ServiceCard
                    data={{
                      doctorAvailability: data.doctorAvailability,
                      discountPrice: data.discountApply,
                      serviceName: data.serviceName,
                      originalPrice: data.originalPrice,
                    }}
                    key={ind}
                    type="user"
                    button2={() => {
                      setBookAppointment({
                        ...bookAppointment,
                        doctorId: id as string,
                        serviceId: parseInt(data.id as string),
                        serviceName: data.serviceName,
                      });
                      setShowUserOnboarding(true);
                    }}
                  />
                );
              })}
            </div>
          )}
        </ScrollView>
      </ScrollView>
    </div>
  );
};

export default UserServices;
