import { Row, Col } from "react-bootstrap";
import { ServiceCardProps } from "../@types/props";
import { assets } from "../assets/assets";
import CustomButton from "./customButton";

const ServiceCard: React.FC<ServiceCardProps> = ({
  data,
  type,
  button1,
  button2,
}) => {
  return (
    <div
      className={`bg-white rounded-3 m-2 p-2 ${
        type === "doctor" ? "services-basis " : "services-basis-user "
      }`}
    >
      <Row className="m-1">
        <Col lg={2} md={2}>
          <img
            src={assets.service_card_holder}
            alt="service_card_holder"
            width={40}
          />
        </Col>
        <Col className="position-relative">
          <div className="mt-2">
            <p className="fs-7 lh-1">{data?.serviceName}</p>
            <p className="fs-7 lh-1"  style={{ color: "#848d9e" }}>{data?.doctorAvailability}</p>
          </div>
          {type === "doctor" && (
            <div className="position-absolute top-0 end-0 me-3">
              <h6 className="text-end fw-bold lh-1">
                {data?.currencySign}
                {data?.servicePrice}
              </h6>
              <h6 className="fs-7 lh-1" style={{ color: "#848d9e" }}>
                Save ₦{data?.discountPrice}
              </h6>
            </div>
          )}
        </Col>
      </Row>
      <Row className="m-1">
        <Col>
          {type === "doctor" ? (
            <CustomButton onClick={button1}>Edit</CustomButton>
          ) : (
            <div className="ms-4 pt-3">
              <h6 className="fw-bold lh-1">
                {data?.currencySign}
                {data?.servicePrice}
              </h6>
              <h6 className="fs-7 lh-1" style={{ color: "#848d9e" }}>
                Save ₦{data?.discountPrice}
              </h6>
            </div>
          )}
        </Col>
        <Col>
          {type === "doctor" ? (
            <CustomButton
              bgColor="bg-white"
              textColor="text-theme"
              borderColor="border-theme"
              onClick={button2}
            >
              Delete
            </CustomButton>
          ) : (
            <CustomButton btnClass="fs-7 p-2" onClick={button2}>
              Book appointment
            </CustomButton>
          )}
        </Col>
      </Row>
    </div>
  );
};
export default ServiceCard;
