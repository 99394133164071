import React from "react";
import { Col, Row } from "react-bootstrap";
import { AppointmentSidebarProps } from "../../@types/props";
import { assets } from "../../assets/assets";
import { search, useNotification } from "../../hooks/app";
import useAtomState from "../../hooks/state";
import appServices from "../../services/app.service";
import CustomButton from "../customButton";
import Input from "../input";
import Sidebar from "./sidebar";

const AppointmentSidebar: React.FC<AppointmentSidebarProps> = ({
  show,
  title,
}) => {
  const { showAppointment, setShowAppointment } = useAtomState();
  const [selectService, setSelectService] = React.useState<any[]>([]);
  const {
    addAppointment,
    setAddAppointment,
    setAppointment,
    setDashboardData,
  } = useAtomState();
  const { loading, setLoading, success, warning, error } = useNotification();
  const { search: selectUser, setSearch } = search.patientByName();

  React.useEffect(() => {
    appServices.getDoctorServiceNameAndId(setSelectService);
    // eslint-disable-next-line
  }, []);

  return (
    <Sidebar
      show={show}
      // title={title}
      handleClose={() => setShowAppointment(!showAppointment)}
      placement={"end"}
      closeButton
    >
      <div>
        <h4>{title}</h4>
        <p className="pt-3">Please fill in the schedule form</p>

        <form>
          <Input
            containerClass="mt-3"
            label="Select Service"
            className="mt-2"
            type={"select"}
            placeholder="Select service"
            options={selectService}
            selectType="bordered-btn"
            onChange={(serviceId) => {
              setAddAppointment({
                ...addAppointment,
                serviceId,
              });
            }}
          />
          <Input
            className="mt-2"
            label="Patient full name"
            type={"select"}
            selectType="image"
            options={selectUser}
            selectInputChange={(fullName) => {
              setSearch(fullName);
            }}
            onChange={(data) => {
              setAddAppointment({
                ...addAppointment,
                email: data.email,
                patientId: data.id,
                phone: data.phone,
              });
            }}
            icon={<img src={assets.patient_icon} width={30} alt="patient" />}
          />
          <Input
            containerClass="mt-3"
            label="Email"
            type={"text"}
            value={addAppointment?.email}
            onChange={(email) => {
              setAddAppointment({
                ...addAppointment,
                email,
              });
            }}
          />
          <Input
            containerClass="mt-3"
            label="Phone number"
            type={"number"}
            value={addAppointment?.phone}
            maxLength={11}
            onChange={(phone) => {
              setAddAppointment({
                ...addAppointment,
                phone,
              });
            }}
          />
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Input
                label="Add date"
                containerClass="mt-3"
                type={"text"}
                placeholder="MM/DD/YYYY"
                value={addAppointment?.appointmentDate}
                onChange={(appointmentDate) => {
                  setAddAppointment({
                    ...addAppointment,
                    appointmentDate,
                  });
                }}
                icon={
                  <img src={assets.input_date_icon} width={20} alt="calendar" />
                }
                onFocus={(e) => {
                  e.target.type = "date";
                }}
                id="date"
              />
            </Col>
          </Row>

          <CustomButton
            isLoading={loading}
            disabled={loading}
            loadingText="Loading"
            onClick={async (e) => {
              e.preventDefault();
              const res = await appServices.addAppointment(addAppointment, {
                setLoading,
                warning,
                success,
                error,
              });
              if (res) {
                appServices.refetchAppointment(setAppointment);
                appServices.refetchDashboardData(setDashboardData);
                setShowAppointment(false);
              }
            }}
            width={60}
            marginTop={3}
            center
          >
            Add
          </CustomButton>
        </form>
      </div>
    </Sidebar>
  );
};

export default AppointmentSidebar;
