import React from "react";
import { Container } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { OtpProps } from "../../@types/props";
import { assets } from "../../assets/assets";
import CustomButton from "../../components/customButton";
import Logo from "../../components/logo";
import { useNotification } from "../../hooks/app";
import { useAuthStyles } from "../../hooks/styles";
import authServices from "../../services/auth.service";
import { routes } from "../../utils/router";
import Success from "./success";

const Otp: React.FC<OtpProps> = ({ onChange, length }) => {
  const [otp, setOtp] = React.useState("");
  const [type, setType] = React.useState("otp");
  const location = useLocation();
  const [loading, setLoading] = React.useState(false);
  const { update, info, error, success } = useNotification();
  const navigate = useNavigate();
  const { form } = useAuthStyles();

  const handleOnChange = (data: string) => {
    setOtp(data);
    onChange?.(data);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const res = await authServices.verifyUserAccount(
      {
        code: otp,
        email: location.state?.email,
      },
      { info, error, setLoading, success }
    );
    if (res) {
      update("success", "Account verified successfully");
      setType("success");
    }
  };

  return (
    <Container as={"section"} className="container">
      <Logo width={10} center />
      {type === "otp" && (
        <div className="w-30 mx-auto border rounded position-absolute top-50 start-50 translate-middle w-sm w-md-60">
          <div className="mt-4">
            <h4 className="text-center">Verify your email</h4>
            <p className="text-center fs-7">We have sent code to your email</p>
            <p className="text-center fs-7 lh-sm">{location.state?.email}</p>
          </div>
          <form className={form}>
            <OtpInput
              value={otp}
              onChange={handleOnChange}
              numInputs={length || 6}
              inputStyle={{
                width: 40,
                height: 40,
                borderRadius: 3,
                borderWidth: 0.8,
                marginLeft: 7,
                marginTop: 10,
              }}
              separator={" "}
            />
            <CustomButton
              onClick={(e) => handleSubmit(e)}
              width={60}
              marginTop={3}
              isLoading={loading}
              loadingText="Loading"
              center
            >
              Verify Account
            </CustomButton>
          </form>
          <p className="mt-4 text-center">
            Resend code in <b>59:00</b>
          </p>
        </div>
      )}
      {type === "success" && location.state?.from === "login" && (
        <div className="w-50 mx-auto border rounded position-absolute top-50 start-50 translate-middle w-sm">
          <div className="w-30 mx-auto pt-3 w-sm">
            <img src={assets.success_1} alt="logo" className="w-100" />
          </div>
          <p className="mt-4 text-center">Account created successfully</p>
          <CustomButton
            onClick={() => {
              navigate(routes.login);
            }}
            width={30}
            marginTop={3}
            className="mb-3"
            center
          >
            Onboard
          </CustomButton>
        </div>
      )}
      {type === "success" && location.state?.from === "register" && <Success />}
    </Container>
  );
};

export default Otp;
