import React from "react";
import { Row, Col } from "react-bootstrap";
import { ServicesProps } from "../../@types/props";
import { ServicesState } from "../../@types/state";
import { useGetScreen, useNotification } from "../../hooks/app";
import useAtomState from "../../hooks/state";
import appServices from "../../services/app.service";
import CustomButton from "../customButton";
import Input from "../input";
import Sidebar from "./sidebar";

const ServiceSidebar: React.FC<ServicesProps> = ({
  title,
  show,
  data,
  setData,
  type,
}) => {
  const { services, setService, setShowService } = useAtomState();
  const { error, success, warning, loading, setLoading } = useNotification();
  const [availability, setAvailability] = React.useState<any[]>([]);
  const [workingHours, setWorkingHours] = React.useState<any[]>([]);
  const { isMobilePortrait } = useGetScreen();

  React.useEffect(() => {
    Promise.all([
      appServices.getDoctorAvailability(),
      appServices.getDoctorWorkingHours(),
    ]).then(([availabilityRes, workingHoursRes]) => {
      setAvailability(availabilityRes);
      setWorkingHours(workingHoursRes);
    });
  }, []);

  const buttonHandler = React.useCallback(async () => {
    if (type === "add") {
      const res = await appServices.addDoctorService(
        { ...data },
        { error, success, warning, setLoading }
      );
      if (res) {
        services.push(data as ServicesState);
        setService(services);
        setData?.(services as any);
        setShowService(false);
      }
    }

    if (type === "edit") {
      const res = await appServices.editDoctorService(
        { ...data },
        { error, success, warning, setLoading }
      );
      if (res) {
        setData?.(services as any);
        setShowService(false);
      }
    }

    appServices.refetchService(setService);
    // eslint-disable-next-line
  }, [type, data]);

  return (
    <Sidebar
      title={title}
      show={show}
      handleClose={setShowService}
      placement={`${isMobilePortrait ? "bottom" : "end"}`}
    >
      <p>Please fill in the service form </p>
      <form>
        <Input
          containerClass="ms-2 mt-3"
          label="Service"
          placeholder="Service name"
          value={data?.serviceName || ""}
          onChange={(serviceName) => {
            setData?.((state: any) => {
              return { ...state, serviceName };
            });
          }}
        />
        <Input
          containerClass="ms-2 mt-3"
          label="Service price"
          type={"number"}
          placeholder="0.00"
          formatNumber=","
          value={data?.originalPrice || ""}
          onChange={(originalPrice) => {
            setData?.((state: any) => {
              return { ...state, originalPrice };
            });
          }}
        />
        <Row>
          <Col>
            <Input
              containerClass="ms-2 mt-3"
              label="Save"
              type={"number"}
              placeholder="0.00"
              formatNumber=","
              maxLength={3}
              value={data?.discountApply || ""}
              onChange={(discountApply) => {
                setData?.((state: any) => {
                  return { ...state, discountApply };
                });
              }}
            />
          </Col>
        </Row>
        <Input
          label="Availability"
          type={"select"}
          className="mt-2"
          placeholder="Select Availability"
          options={[...availability]}
          value={data?.doctorAvailability}
          onChange={(doctorAvailability) => {
            setData?.((state: any) => {
              return {
                ...state,
                doctorAvailability:
                  doctorAvailability || availability[0]?.value,
              };
            });
          }}
        />
        <Input
          label="Working hours"
          type={"select"}
          className="mt-2"
          value={data?.workingHours}
          placeholder="Select Working hours"
          onChange={(workingHours) => {
            setData?.((state: any) => {
              return {
                ...state,
                workingHours: workingHours,
              };
            });
          }}
          options={[...workingHours]}
        />
        <CustomButton
          width={90}
          type="button"
          marginTop={5}
          loadingText="Loading"
          isLoading={loading}
          disabled={loading}
          center
          onClick={buttonHandler}
        >
          {type === "add" ? "Add" : "Edit"}
        </CustomButton>
      </form>
    </Sidebar>
  );
};

export default ServiceSidebar;
