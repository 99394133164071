import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { OnboardingLayoutProps, Width } from "../../@types/props";
import { assets } from "../../assets/assets";
import { useGetScreen, useNotification } from "../../hooks/app";
import useAtomState from "../../hooks/state";
import { useOboardingtyles } from "../../hooks/styles";
import appServices from "../../services/app.service";
import authServices from "../../services/auth.service";
import { routes } from "../../utils/router";
import { SCREEN_SIZE } from "../../utils/utils";
import CustomButton from "../customButton";
import Logo from "../logo";
import ResponsiveRender from "../responsiveRender";
import ScrollView from "../scrollview";
import { ServiceSidebar } from "../sidebar";

const OnboardingLayout: React.FC<OnboardingLayoutProps> = ({
  children,
  navigate,
  location,
}) => {
  const { isMobilePortrait } = useGetScreen();
  const {
    setService,
    setDetailsState,
    otherDetails,
    showService,
    detailsState,
    editServiceState,
    services,
    setEditServiceState,
  } = useAtomState();

  const { success, warning, info, error, loading, setLoading } =
    useNotification();
  const className = useOboardingtyles();

  React.useEffect(() => {
    setDetailsState({
      businessName: "",
      email: authServices.getStoredData().user?.email as string,
      fullName: authServices.getStoredData().user?.fullName as string,
      gender: "",
      phone: authServices.getStoredData().user?.phone as string,
      state: "",
    });
    // eslint-disable-next-line
  }, []);

  const isService = routes.onboardService === window.location.pathname;

  return (
    <Container as={"section"} className="vh-100" fluid>
      <Row>
        <ResponsiveRender>
          <Col
            lg={2}
            md={2}
            className="vh-100 bg-theme onboarding-sidebar"
            id={SCREEN_SIZE.isLargeDesktop}
          >
            <img
              src={
                routes.onboardDetails === location?.pathname
                  ? assets.onboard_img1
                  : assets.onboard_img2
              }
              alt="onboarding"
              style={{ width: "95%" }}
              className="position-absolute top-25"
            />
          </Col>
          <Col
            lg={2}
            md={2}
            className="vh-100 bg-theme onboarding-sidebar position-relative"
            id={SCREEN_SIZE.isXLargeDesktop}
          >
            <img
              src={
                routes.onboardDetails === location?.pathname
                  ? assets.onboard_img1
                  : assets.onboard_img2
              }
              alt="onboarding"
              style={{ width: "95%" }}
              className="position-absolute top-25"
            />
          </Col>
          <Col
            lg={2}
            md={2}
            className="vh-100 bg-theme onboarding-sidebar position-relative"
            id={SCREEN_SIZE.isMediumPortrait}
          >
            <img
              src={
                routes.onboardDetails === location?.pathname
                  ? assets.onboard_img1
                  : assets.onboard_img2
              }
              alt="onboarding"
              style={{ width: "95%" }}
              className="position-absolute top-25"
            />
          </Col>
        </ResponsiveRender>
        <Col>
          <Logo
            width={className.logoWidth as Width}
            className={`${isMobilePortrait ? "ms-2" : "ms-4"} `}
          />
          <ScrollView isLargeDesktop="70vh">{children}</ScrollView>
          <Row
            className={`${className.onboardingBtnControl} ${
              isService ? "position-fixed bottom-0 mb-2" : "mt-4"
            }`}
          >
            <Col>
              <CustomButton
                onClick={() => navigate?.(-1)}
                disabled
                style={{ height: 50 }}
              >
                Previous
              </CustomButton>
            </Col>
            <Col>
              <CustomButton
                disabled={loading}
                style={{ height: 50 }}
                isLoading={loading}
                loadingText="Loading"
                onClick={async () => {
                  if (
                    location?.pathname === "/onboarding/add-services" &&
                    services.length === 0
                  ) {
                    return;
                  }
                  const res = await authServices.onboardingSteps(
                    location?.pathname,
                    detailsState,
                    otherDetails,
                    {
                      success,
                      warning,
                      info,
                      setLoading,
                      error,
                    },
                    () => {
                      appServices.refetchService(setService);
                    }
                  );
                  navigate?.(res as string);
                }}
              >
                Continue
              </CustomButton>
            </Col>
          </Row>
        </Col>
      </Row>
      <ServiceSidebar
        data={editServiceState}
        setData={setEditServiceState}
        show={showService}
        type={editServiceState.type}
      />
    </Container>
  );
};

export default OnboardingLayout;
