import { Link } from "react-router-dom";
import { LogoProps } from "../@types/props";
import { assets } from "../assets/assets";

const Logo: React.FC<LogoProps> = ({ className, to, width = 25, center }) => {
  return (
    <div className={`w-${width} pt-2 ${className ? className : ""} ${center ? "d-block mx-auto": ""}`}>
      <Link to={to as string}>
        <img src={assets.logo} alt="logo" className={`w-100`} />
      </Link>
    </div>
  );
};

export default Logo;
