import React, { Fragment } from "react";
import { UploaderProps } from "../@types/props";
import { assets } from "../assets/assets";
import { useOboardingtyles } from "../hooks/styles";

const Uploader: React.FC<UploaderProps> = ({
  id,
  width = 30,
  onClick,
  value,
  height,
  disabled = false,
  imageId
}) => {
  const [image, setImage] = React.useState<any>(value);
  const { uploaderWidth } = useOboardingtyles();

  return (
    <Fragment>
      <label
        className={`d-block w-${width} mx-auto cursor-p`}
        htmlFor={id || "upload"}
      >
        <img
          src={image || assets.img_holder}
          alt="upload_holder"
          className={`${uploaderWidth}`}
          style={{ borderRadius: "50%", height }}
          id={imageId}
        />
      </label>
      <input
        disabled={disabled}
        type={"file"}
        id={id || "upload"}
        accept="image/*"
        hidden
        onChange={(e) => {
          e.preventDefault();
          const reader = new FileReader();
          reader.addEventListener("load", () => {
            setImage(reader.result);
          });
          reader.readAsDataURL(e.target.files![0]);
          onClick?.(e.target.files![0]);
        }}
      />
    </Fragment>
  );
};
export default Uploader;
