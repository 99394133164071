import React from "react";
import { ButtonProps } from "../@types/props";
import Loader from "./loader";

const CustomButton: React.FC<ButtonProps> = ({
  width,
  marginTop,
  children,
  btnText = "Submit",
  className,
  center = false,
  type,
  onClick,
  style,
  disabled,
  btnClass,
  marginRight,
  radius,
  btnStyle,
  bgColor = "bg-theme",
  textColor = "text-white",
  borderColor,
  isLoading,
  loadingText,
}) => {
  const [isDisabled] = React.useState(disabled);
  return (
    <div
      className={`${width ? `w-${width}` : ""}  ${
        marginRight ? `me-${marginRight}` : ""
      } ${center && "mx-auto"} ${className ? className : ""}`}
      style={style}
    >
      <button
        type={type}
        disabled={disabled}
        className={`btn ${
          marginTop ? `mt-${marginTop}` : ""
        } ${bgColor} w-100 ${textColor} h-100 ${btnClass ? btnClass : ""} ${
          radius ? `rounded-${radius}` : ""
        } ${borderColor ? borderColor : ""}`}
        onClick={(e) => {
          if (!isDisabled) {
            onClick?.(e);
          }
        }}
        style={btnStyle}
      >
        {!isLoading && (children ?? btnText)}
        {isLoading && (
          <>
            <span>{loadingText}</span>
            <Loader />
          </>
        )}
      </button>
    </div>
  );
};

export default CustomButton;
