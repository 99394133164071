import { Fragment } from "react";
import { Stack } from "react-bootstrap";
import { AiFillCalendar, AiTwotoneSetting } from "react-icons/ai";
import { FaWallet } from "react-icons/fa";
import { HiUserGroup, HiClipboardList } from "react-icons/hi";
import { MdDashboard, MdAccessTimeFilled } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import { Width } from "../@types/props";
import { assets } from "../assets/assets";
import { useGetScreen } from "../hooks/app";
import useAtomState from "../hooks/state";
import { useLayoutStyles } from "../hooks/styles";
import authServices from "../services/auth.service";
import { routes } from "../utils/router";
import Logo from "./logo";
import ScrollView from "./scrollview";

const SidebarLinks: React.FC<{ location?: any }> = ({ location }) => {
  const isActive = (route: string, pathname: string) => {
    const active = route === pathname;
    if (active) {
      return { nav: "nav-active", icon: "#d41a5c", text: "text-theme" };
    }
    return { nav: "", icon: "#d0cfd4", text: "text-gray" };
  };
  const sideLinks = [
    { route: routes.dashboard, name: "Dashboard", Icon: MdDashboard },
    {
      route: routes.appointment,
      name: "Appointment",
      Icon: MdAccessTimeFilled,
    },
    { route: routes.patient, name: "Patients", Icon: HiUserGroup },
    { route: routes.services, name: "Services", Icon: HiClipboardList },
    { route: routes.calendar, name: "Calendar", Icon: AiFillCalendar },
    { route: routes.wallet, name: "Wallet", Icon: FaWallet },
    { route: routes.settings, name: "Settings", Icon: AiTwotoneSetting },
  ];
  const navigate = useNavigate();
  const className = useLayoutStyles();
  const { isMobilePortrait } = useGetScreen();
  const { setShowLeftSidebar } = useAtomState();
  const { sideBarLinks } = useLayoutStyles();
  return (
    <Fragment>
      <ScrollView isLargeDesktop="100vh" isMediumPortrait="100vh">
        <Logo width={className.sideBar.logo as Width} className="ms-4" />
        <Stack direction="vertical" className="mt-4">
          {sideLinks?.map(({ route, name, Icon }, ind: number) => {
            return (
              <div
                key={ind}
                className={`p-2 mt-3 ${
                  isActive(route, location?.pathname).nav
                }`}
                onClick={() => setShowLeftSidebar(false)}
              >
                <NavLink to={route} className={`text-decoration-none`}>
                  <Icon
                    size={25}
                    color={isActive(route, location?.pathname).icon}
                  />
                  <span
                    className={`fs-6 ps-2 text-gray fw-normal ${
                      isActive(route, location?.pathname).text
                    }`}
                  >
                    {name}
                  </span>
                </NavLink>
              </div>
            );
          })}

          {!isMobilePortrait && (
            <div className="avatar-box-app">
              <img
                src={authServices?.getStoredData()?.user?.avatar}
                alt="doctor avatar"
                style={{
                  width: "75%",
                  height: "75%",
                  borderRadius: 20,
                  objectFit: "cover",
                  objectPosition: "top",
                }}
                className="d-block mx-auto mt-3"
              />
            </div>
          )}
        </Stack>
        <Stack direction="horizontal">
          <div className={`${isMobilePortrait ? "w-20" : ""}`}>
            <img
              src={authServices?.getStoredData()?.user?.avatar}
              alt="doctor avatar"
              style={{
                borderRadius: 20,
                objectFit: "cover",
                objectPosition: "top",
                height: 60,
              }}
              className="w-100 mt-3"
            />
          </div>
          <div className={sideBarLinks.stack_2.div_2.con}>
            <h6 className="fs-7 lh-1">{authServices?.getName()?.name}</h6>
            <h6 className="fs-7 lh-1">Log out</h6>
          </div>
          <div className="ps-4">
            <img
              src={assets.logout_icon}
              alt="logout icon cursor-pointer"
              width={20}
              onClick={() => authServices.logout(navigate)}
            />
          </div>
        </Stack>
      </ScrollView>
    </Fragment>
  );
};
export default SidebarLinks;
