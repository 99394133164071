import React from "react";
import { Col, Row } from "react-bootstrap";
import { assets } from "../../assets/assets";
import CustomButton from "../../components/customButton";
import ScrollView from "../../components/scrollview";
import { useGetScreen, useNotification } from "../../hooks/app";
import useAtomState from "../../hooks/state";
import appServices from "../../services/app.service";
import { editServicesState } from "../../utils/state";

const Services: React.FC = () => {
  const {
    services,
    setService,
    showService,
    setShowService,
    setEditServiceState,
  } = useAtomState();
  const { isMobilePortrait } = useGetScreen();
  const { error, success, warning } = useNotification();
  const isServiceExist = services.length > 0;

  React.useEffect(() => {
    Promise.all([appServices.getDoctorService()]).then(([service]) => {
      setService(service);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-95 mx-auto">
      {!isServiceExist && (
        <div
          className={`${
            isMobilePortrait ? "w-40" : "w-30"
          } mx-auto position-absolute top-50 start-50 translate-middle w-sm w-md-60`}
        >
          <h2 className="text-center">Add services</h2>
          <CustomButton
            width={isMobilePortrait ? 50 : 50}
            type="button"
            onClick={() => {
              setShowService(!showService);
              setEditServiceState({
                ...editServicesState,
                type: "add",
              });
            }}
            center
          >
            <img src={assets.plus_1} alt="plus" width={15} className="mb-1" />
            Add new
          </CustomButton>
        </div>
      )}
      {isServiceExist && (
        <>
          <Row className="justify-content-between mt-5">
            <Col lg={3} md={3} sm={12}>
              <h3
                className={`text-gray fw-normal ps-3 ${
                  isMobilePortrait ? "text-center" : ""
                }`}
              >
                Add services
              </h3>
            </Col>
            <Col lg={3} md={8} sm={12}>
              {isServiceExist && (
                <CustomButton
                  width={isMobilePortrait ? 60 : 50}
                  type="button"
                  onClick={() => {
                    setShowService(!showService);
                    setEditServiceState({
                      ...editServicesState,
                      type: "add",
                    });
                  }}
                  center
                  className="mb-5"
                >
                  <img
                    src={assets.plus_1}
                    alt="plus"
                    width={15}
                    className="mb-1"
                  />
                  <span className="ms-1">Add new</span>
                </CustomButton>
              )}
            </Col>
          </Row>
          <ScrollView
            isLargeDesktop="55vh"
            isMediumPortrait="55vh"
            isMobilePortrait="70vh"
          >
            {services.map((data, ind) => {
              return (
                <Row
                  className={`${
                    isMobilePortrait ? "ms-2" : "ms-1"
                  } mt-2 shadow p-2 rounded w-95`}
                  key={ind}
                >
                  <Col lg={10} md={10} sm={10} className="">
                    <Row className="ps-1">
                      <Col
                        lg={3}
                        md={3}
                        sm={12}
                        className={`${
                          isMobilePortrait ? "w-50 mt-1" : "me-2"
                        } p-2 rounded`}
                        style={{ backgroundColor: "#FFEDF3" }}
                      >
                        <img src={assets.mark_icon} width={15} alt="mark" />
                        <span className="ps-2 fs-7">{data.serviceName}</span>
                      </Col>
                      <Col
                        lg={2}
                        md={2}
                        sm={4}
                        className={`${
                          isMobilePortrait ? "w-45 ms-1 mt-1" : "ms-2"
                        } p-2 rounded`}
                        style={{ backgroundColor: "#FFEDF3" }}
                      >
                        <img src={assets.naira_icon} width={15} alt="naira" />
                        <span className="ps-2 fs-7">{data.originalPrice}</span>
                      </Col>
                      <Col
                        lg={2}
                        md={3}
                        sm={4}
                        className={`${
                          isMobilePortrait ? "w-50 mt-1" : "ms-2"
                        } p-2 rounded `}
                        style={{ backgroundColor: "#FFEDF3" }}
                      >
                        <img src={assets.calendar} width={15} alt="available" />
                        <span className="ps-2 fs-7">
                          {data.doctorAvailability}
                        </span>
                      </Col>
                      <Col
                        lg={3}
                        md={3}
                        sm={4}
                        className={`${
                          isMobilePortrait ? "w-45 ms-1 mt-1" : "ms-2"
                        } p-2 rounded`}
                        style={{ backgroundColor: "#FFEDF3" }}
                      >
                        <img src={assets.time} width={15} alt="time" />
                        <span className="ps-2 fs-7">{data.workingHours}</span>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    lg={2}
                    md={1}
                    sm={1}
                    className={`${
                      isMobilePortrait ? "w-100" : "mt-1 ms-1 w-10"
                    }`}
                  >
                    <Row>
                      <Col
                        lg={5}
                        md={6}
                        sm={6}
                        className={`${isMobilePortrait ? "w-50 mt-3" : ""}`}
                      >
                        <img
                          src={assets.edit_icon}
                          alt="edit"
                          width={30}
                          className={`cursor-p ${
                            isMobilePortrait ? "ms-4" : ""
                          }`}
                          onClick={async () => {
                            setEditServiceState({
                              ...data,
                              type: "edit",
                            });
                            setShowService(true);
                          }}
                        />
                      </Col>
                      <Col
                        lg={2}
                        md={1}
                        sm={6}
                        className={`${isMobilePortrait ? "w-50 mt-3" : ""}`}
                      >
                        <img
                          src={assets.del_icon}
                          alt="delete"
                          width={30}
                          className={`cursor-p ${
                            isMobilePortrait ? "ms-4" : ""
                          }`}
                          onClick={async () => {
                            const id = data.id;
                            const res = await appServices.deleteDoctorService(
                              id as string,
                              { error, warning, success }
                            );
                            if (res)
                              await appServices.refetchService(setService);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              );
            })}
          </ScrollView>
        </>
      )}
    </div>
  );
};

export default Services;
