import React from "react";
import { ResponsiveRenderProps } from "../@types/props";
import { useGetScreen } from "../hooks/app";
import { SCREEN_SIZE } from "../utils/utils";

/**
 * Component called to render children at specific screen breakpoint by setting the id:
 *  `isMobilePortrait`,
    `isXLargeDesktop`,
    `isLargeDesktop`,
    `isMediumPortrait`,
    `isMediumLandscape`,
    `isMobileLandscape`,
 * @param children 
 * @returns 
 */
const ResponsiveRender: React.FC<ResponsiveRenderProps> = ({
  children,
  renderNull,
}) => {
  const {
    isMobilePortrait,
    isXLargeDesktop,
    isLargeDesktop,
    isMediumPortrait,
    isMediumLandscape,
    isMobileLandscape,
  } = useGetScreen();


  if (renderNull) {
    return null;
  }

  return (
    <>
      {React.Children.map(children, (child?: any) => {
        if (
          child.props.id === SCREEN_SIZE.isMobilePortrait &&
          isMobilePortrait
        ) {
          return React.cloneElement(child);
        }
        if (
          child.props.id === SCREEN_SIZE.isMediumPortrait &&
          isMediumPortrait
        ) {
          return React.cloneElement(child);
        }
        if (
          child.props.id === SCREEN_SIZE.isMobileLandscape &&
          isMobileLandscape
        ) {
          return React.cloneElement(child);
        }
        if (child.props.id === SCREEN_SIZE.isXLargeDesktop && isXLargeDesktop) {
          return React.cloneElement(child);
        }
        if (child.props.id === SCREEN_SIZE.isLargeDesktop && isLargeDesktop) {
          return React.cloneElement(child);
        }
        if (
          child.props.id === SCREEN_SIZE.isMediumPortrait &&
          isMediumPortrait
        ) {
          return React.cloneElement(child);
        }
        if (
          child.props.id === SCREEN_SIZE.isMediumLandscape &&
          isMediumLandscape
        ) {
          return React.cloneElement(child);
        }
      })}
    </>
  );
};

export default ResponsiveRender;
