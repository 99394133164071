export enum SCREEN_SIZE {
  isMobilePortrait = "isMobilePortrait",
  isMobileLandscape = "isMobileLandscape",
  isMediumPortrait = "isMediumPortrait",
  isMediumLandscape = "isMediumLandscape",
  isLargeDesktop = "isLargeDesktop",
  isXLargeDesktop = "isXLargeDesktop",
}

export enum AppointmentStatus {
  PENDING = "pending",
  RECENT = "recent",
  ALL_TRANSACTION = "",
  CANCELED = "canceled",
  COMPLETED = "completed",
  UP_COMING = 'upcoming'
}
