import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { assets } from "../../assets/assets";
import CustomButton from "../../components/customButton";
import Input from "../../components/input";
import Logo from "../../components/logo";
import ResponsiveRender from "../../components/responsiveRender";
import { useGetScreen, useNotification } from "../../hooks/app";
import useAtomState from "../../hooks/state";
import authServices, { OnInit } from "../../services/auth.service";
import { routes } from "../../utils/router";
import { SCREEN_SIZE } from "../../utils/utils";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { isLargeDesktop, isXLargeDesktop } = useGetScreen();
  const showCol2 = isLargeDesktop || isXLargeDesktop ? true : false;
  const [rememberMe, setRememberMe] = React.useState(false);
  const { loginData, setLoginData, isLoggedIn, setIsLoggedIn } = useAtomState();
  const { warning, success, error } = useNotification();
  const [loading, setLoading] = React.useState(false);
  OnInit();

  return (
    <Container as={"section"} fluid>
      <Row>
        <Col
          lg={!showCol2 ? 10 : 3}
          md={!showCol2 ? 6 : 4}
          className={`${!showCol2 ? "mx-auto" : 3}`}
        >
          <Logo className="mx-auto" to="/" />
          <div className="w-70 mx-auto">
            <p className="text-center mt-3 fs-5">Sign up</p>
            <button
              onClick={async () => {
                let route;
                const res = await authServices.logInWithGoogle({
                  success,
                  error,
                });
                if (res) {
                  authServices.storeData({ ...res });
                  const data = await authServices.getUserProfile();
                  setIsLoggedIn(true);
                  route = await authServices.authenticationStages(
                    data,
                    isLoggedIn as boolean
                  );

                  authServices.storeData({ accessToken: res.accessToken });
                  setIsLoggedIn(true);
                  navigate(route as string, {
                    state: {
                      email: res.user.email,
                      from: "login",
                    },
                  });
                }
              }}
              className="btn border w-100 mt-3"
            >
              <img
                src={assets.google}
                alt="google"
                width={20}
                className="pe-1"
              />
              Sign in with Google
            </button>
          </div>
          <p className="or mt-3">Or</p>
          <form>
            <Input
              label="Email Address"
              labelFor="email"
              placeholder="example@gmail.com"
              containerClass="mt-3"
              className="mt-2"
              type={"email"}
              value={loginData.email}
              onChange={(email) => {
                setLoginData((state: any) => {
                  return { ...state, email };
                });
              }}
            />
            <Input
              label="Password"
              containerClass="mt-3"
              className="mt-2"
              labelFor="password"
              type={"password"}
              icon={<BsFillEyeSlashFill size={20} />}
              value={loginData.password}
              onChange={(password) => {
                setLoginData((state: any) => {
                  return { ...state, password };
                });
              }}
            />
            <Row className="mt-3">
              <Col>
                <input
                  type={"checkbox"}
                  defaultChecked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                <label className="ps-1">Remember me</label>
              </Col>
              <Col>
                <Link
                  to={routes.forgotPassword}
                  className="text-theme text-decoration-none"
                >
                  <p className="text-end">Reset password?</p>
                </Link>
              </Col>
            </Row>
            <CustomButton
              width={70}
              marginTop={3}
              isLoading={loading}
              disabled={loading}
              loadingText="Loading"
              onClick={async (e) => {
                e.preventDefault();
                let route;
                const res = await authServices.login(loginData, {
                  warning,
                  success,
                  setLoading,
                  error,
                });
                if (res) {
                  authServices.storeData({ ...res });
                  const data = await authServices.getUserProfile();
                  route = await authServices.authenticationStages(
                    data,
                    isLoggedIn as boolean
                  );
                  authServices.storeData({ accessToken: res.accessToken });
                  setIsLoggedIn(true);
                  navigate(route as string, {
                    state: {
                      email: res.user.email,
                      from: "login",
                    },
                  });
                }
              }}
              center
            >
              Login
            </CustomButton>
          </form>

          <p className="text-center mt-4">
            Don’t have account yet?
            <Link to={"/auth/register"} className="text-dec-none">
              <span className="text-theme ms-1"> New Account</span>
            </Link>
          </p>
        </Col>
        <ResponsiveRender>
          <Col
            lg={9}
            md={9}
            id={SCREEN_SIZE.isXLargeDesktop}
            className="vh-100"
          >
            <img
              src={assets.doc_2}
              alt="doctor-2"
              className="w-100 img-fluid h-100 img-fit-top"
            />
          </Col>
        </ResponsiveRender>
      </Row>
    </Container>
  );
};

export default Login;
