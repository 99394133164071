import React from "react";
import { Col, Row, Stack, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Width } from "../../@types/props";
import { assets } from "../../assets/assets";
import CustomButton from "../../components/customButton";
import Loader from "../../components/loader";
import ScrollView from "../../components/scrollview";
import { useGetScreen } from "../../hooks/app";
import useAtomState from "../../hooks/state";
import { useDashboardStyles } from "../../hooks/styles";
import appServices from "../../services/app.service";
import authServices from "../../services/auth.service";
import { routes } from "../../utils/router";

const Dashboard: React.FC = () => {
  const style = useDashboardStyles();
  const [pageLoading, setPageLoading] = React.useState(false);
  const { isMobilePortrait } = useGetScreen();
  // const [filter, setFilter] = React.useState<number>();
  const {
    showAppointment,
    setShowAppointment,
    setDashboardData,
    dashboardData,
  } = useAtomState();

  React.useEffect(() => {
    setPageLoading(true);
    Promise.all([appServices.getDashboardData()]).then(([dashboardData]) => {
      setDashboardData(dashboardData);
      setPageLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-95 mx-auto">
      <Row className="rounded-3 flex-nowrap" style={{ background: "#f8eff3" }}>
        <Col sm={4} md={8} className={style.header.col1.colClass}>
          <div className={style.header.col1.container}>
            <p className="pt-5">Hello {authServices.getName().firstName},</p>
            <h1 className={style.header.col1.welcomeText}>Welcome back</h1>
            <Row>
              <Col lg={6} md={6} sm={12}>
                <CustomButton
                  width={style?.header.col1.width as Width}
                  marginTop={3}
                  style={{
                    height: 45,
                  }}
                  btnStyle={{ fontSize: style?.header.col1.btnFontSize }}
                  onClick={() => setShowAppointment(!showAppointment)}
                >
                  Schedule appointment
                </CustomButton>
              </Col>
              <Col lg={6} md={6} sm={6}>
                <p className="pt-4" style={{ fontSize: 11 }}>
                  You have {dashboardData?.todayAppointmentCount || 0}{" "}
                  appointments remaining today!
                </p>
              </Col>
            </Row>
          </div>
        </Col>
        {!isMobilePortrait && (
          <Col lg={4} sm={6} md={4}>
            <div className={style.header.col2.container}>
              <img
                src={
                  dashboardData.nextAppointment?.patient?.picture ||
                  assets.braces
                }
                className={style.header.col2.img}
                style={{ height: style.header.col2.height }}
                alt="braces"
              />
              <p
                className="p-1 border border-success text-center w-75 mx-auto mt-2 text-success"
                style={{ fontSize: 8 }}
              >
                NEXT Appointment with
              </p>
              <p className="pb-2 text-center">
                {dashboardData?.nextAppointment?.patient?.fullName}
              </p>
            </div>
          </Col>
        )}
      </Row>

      {!isMobilePortrait && (
        <Row className={style.section2.rowContainer}>
          <Col className="p-3 rounded-3 mt-3 bg-white">
            <Row>
              <Col lg={3} className={style.section2.innerCol}>
                <img
                  src={assets.eye_icon}
                  className={`${style?.section2.icon}`}
                  alt="eye-icon"
                />
              </Col>
              <Col lg={7}>
                <p className="count-text-1">
                  {dashboardData?.profileVisitCount}
                </p>
                <p className="count-text-2">Total Profile View</p>
              </Col>
            </Row>
          </Col>
          <Col className="p-3 rounded-3 mt-3 ms-2 bg-white">
            <Row>
              <Col lg={3} className={style.section2.innerCol}>
                <img
                  src={assets.pink_calendar}
                  className={`${style?.section2.icon}`}
                  alt="eye-icon"
                />
              </Col>
              <Col lg={7}>
                <p className="count-text-1">
                  {dashboardData?.appointmentCounts}
                </p>
                <p className="count-text-2">Total Bookings</p>
              </Col>
            </Row>
          </Col>
          <Col className="p-3 rounded-3 mt-3 ms-2 bg-white">
            <Row>
              <Col lg={3} className={style.section2.innerCol}>
                <img
                  src={assets.bag_icon}
                  className={`${style?.section2.icon}`}
                  alt="eye-icon"
                />
              </Col>
              <Col lg={7}>
                <p className="count-text-1">{dashboardData?.patientCount}</p>
                <p className="count-text-2">Total Patients</p>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      {isMobilePortrait && (
        <Row className={style.section2.rowContainer}>
          <Col sm={12} className="rounded-3 mt-3 bg-white w-80">
            <Row className="flex-nowrap">
              <Col lg={3} sm={3} className={style.section2.innerCol}>
                <img
                  src={assets.eye_icon}
                  className={`${style?.section2.icon}`}
                  alt="eye-icon"
                />
              </Col>
              <Col lg={7} className="mt-2">
                <p className="count-text-1">
                  {dashboardData?.profileVisitCount}
                </p>
                <p className="count-text-2">Total Profile View</p>
              </Col>
            </Row>
          </Col>
          <Col sm={12} className="rounded-3 mt-3 ms-2 bg-white w-80">
            <Row className="flex-nowrap">
              <Col lg={3} sm={3} className={style.section2.innerCol}>
                <img
                  src={assets.pink_calendar}
                  className={`${style?.section2.icon}`}
                  alt="eye-icon"
                />
              </Col>
              <Col lg={7} className="mt-2">
                <p className="count-text-1">
                  {dashboardData?.appointmentCounts}
                </p>
                <p className="count-text-2">Total Bookings</p>
              </Col>
            </Row>
          </Col>
          <Col sm={12} className="rounded-3 mt-3 ms-2 bg-white w-80">
            <Row className="flex-nowrap">
              <Col lg={3} className={style.section2.innerCol}>
                <img
                  src={assets.bag_icon}
                  className={`${style?.section2.icon}`}
                  alt="eye-icon"
                />
              </Col>
              <Col lg={7} className="mt-2">
                <p className="count-text-1">{dashboardData?.patientCount}</p>
                <p className="count-text-2">Total Patients</p>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      <Row className="bg-white mt-3 rounded-2">
        {!isMobilePortrait && (
          <p className="ps-3 pt-3 fs-5 fw-normal">Upcoming Appointment</p>
        )}

        <ScrollView
          className="table-responsive"
          isLargeDesktop={"30vh"}
          // maxHeight={"40vh"}
          isMobilePortrait={"45vh"}
          isMediumPortrait={"40vh"}
        >
          {pageLoading ? (
            <Loader type="skeleton-table" fill={4} />
          ) : (
            <React.Fragment>
              {!isMobilePortrait ? (
                <React.Fragment>
                  <Table responsive>
                    <thead className="theader">
                      <tr>
                        <th></th>
                        <th></th>
                        <th className={style.table.theader.nameClass}>
                          Patient name
                        </th>
                        <th className={style.table.theader.nameClass}>
                          Service Booked
                        </th>
                        <th className={style.table.theader.nameClass}>
                          Date / Time
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData?.UpcomingAppointment?.length === 0 ? (
                        <tr>
                          <td colSpan={7}>
                            <h6 className="text-center pt-5">
                              You have no upcoming appointment
                            </h6>
                          </td>
                        </tr>
                      ) : (
                        dashboardData?.UpcomingAppointment?.map(
                          (
                            { phone, appointmentDate, patient, doctor_service },
                            ind: number
                          ) => {
                            return (
                              <tr key={ind}>
                                <td rowSpan={1}></td>
                                <td>
                                  <img
                                    src={patient?.picture}
                                    alt="avatar"
                                    className="rounded-3"
                                    width={50}
                                    height={50}
                                  />
                                </td>
                                <td>
                                  <p style={{ ...style.table.fullName.style }}>
                                    {patient?.fullName}
                                  </p>
                                  <p
                                    className="text-gray"
                                    style={{ ...style.table.phone.style }}
                                  >
                                    {phone}
                                  </p>
                                </td>
                                <td>
                                  <p style={{ fontSize: 13 }} className="pt-3">
                                    {doctor_service?.serviceName}
                                  </p>
                                </td>
                                <td>
                                  <p className="pt-3" style={{ fontSize: 13 }}>
                                    {appointmentDate}
                                  </p>
                                </td>
                              </tr>
                            );
                          }
                        )
                      )}
                    </tbody>
                  </Table>
                  <Link to={routes.appointment}>
                    <p className="text-center" style={{ color: "#0062FF" }}>
                      View All
                    </p>
                  </Link>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <div className="rounded-3 theader w-95 mx-auto">
                    <p className="ps-3 p-2 fs-5 fw-normal">
                      Upcoming Appointment
                    </p>
                  </div>
                  <ScrollView className="w-95 mx-auto">
                    {dashboardData.UpcomingAppointment?.map((data) => {
                      return (
                        <div className="mt-4">
                          <p
                            className="mt-3 fw-bold"
                            style={{ lineHeight: 0.9, fontSize: 12 }}
                          >
                            Patient Name
                          </p>
                          <Stack direction="horizontal">
                            <img
                              src={data.patient?.picture}
                              alt="avatar"
                              className="rounded-3"
                              width={50}
                              height={50}
                            />
                            <div className="ms-2 mt-3">
                              <p style={{ lineHeight: 0.5, fontSize: 12 }}>
                                {data.patient?.fullName}
                              </p>
                              <p
                                className="text-gray"
                                style={{ lineHeight: 0.5, fontSize: 12 }}
                              >
                                {data.phone}
                              </p>
                            </div>
                          </Stack>
                          <p
                            className="mt-3 fw-bold"
                            style={{ lineHeight: 0.9, fontSize: 12 }}
                          >
                            Service Booked
                          </p>
                          <Stack direction="horizontal">
                            <p style={{ lineHeight: 0.5, fontSize: 12 }}>
                              {data.doctor_service?.serviceName}
                            </p>
                          </Stack>
                          <p
                            className="mt-3 fw-bold"
                            style={{ lineHeight: 0.9, fontSize: 12 }}
                          >
                            Date / Time
                          </p>
                          <Stack direction="horizontal">
                            <p style={{ lineHeight: 0.5, fontSize: 12 }}>
                              {data.appointmentDate}
                            </p>
                          </Stack>
                          <hr
                            style={{
                              color: "#667085",
                              width: "80%",
                              margin: "auto",
                            }}
                            className="mt-2"
                          />
                        </div>
                      );
                    })}
                  </ScrollView>
                  <Link to={routes.appointment}>
                    <p className="text-center" style={{ color: "#0062FF" }}>
                      View All
                    </p>
                  </Link>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </ScrollView>
      </Row>
    </div>
  );
};

export default Dashboard;
