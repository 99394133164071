import { Offcanvas } from "react-bootstrap";
import { SidebarProps } from "../../@types/props";

const Sidebar: React.FC<SidebarProps> = ({
  show,
  handleClose,
  placement,
  children,
  title,
  className,
  closeButton = false,
}) => {
  if (show) {
    return (
      <Offcanvas show={show} onHide={handleClose} placement={placement}>
        <Offcanvas.Header className={className} closeButton={closeButton}>
          <Offcanvas.Title className="text-center" as={"h5"}>
            {title}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className={className}>{children}</Offcanvas.Body>
      </Offcanvas>
    );
  }
  return null;
};

export default Sidebar;
