import "./App.css";
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { routes } from "./utils/router";
import { Layout } from "./components/layouts";
import {
  AppAppointment,
  AppCalendar,
  AppDashboard,
  AppPatients,
  AppWallet,
  AppServices,
  AppTransactions,
  AppSettings,
} from "./containers/app";
import { UserServices } from "./containers/user";
import {
  OnboardingDetails,
  OnboardingOthers,
  OnboardingServices,
} from "./containers/onboarding";
import {
  AuthForgotPassword,
  AuthLogin,
  AuthOtp,
  AuthRegister,
  AuthResetPassword,
} from "./containers/auth";
import authServices from "./services/auth.service";
import PageNotFound from "./containers/utils/404";
import useAtomState from "./hooks/state";
import Success from "./containers/auth/success";
import { ToastContainer } from "react-toastify";
import PaymentVerification from "./containers/utils/paymentVerification";

const App: React.FC = () => {
  const { isLoggedIn } = useAtomState();
  const [auth] = React.useState(authServices.authenticationStages() as string);

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="*" element={<PageNotFound />} />
          <Route path="/" element={<Navigate to={auth} />} />
          <Route path={routes.login} element={<AuthLogin />} />
          <Route path={routes.register} element={<AuthRegister />} />
          <Route path={routes.otp} element={<AuthOtp />} />
          <Route
            path={routes.forgotPassword}
            element={<AuthForgotPassword />}
          />
          <Route path={routes.resetPassword} element={<AuthResetPassword />} />
          <Route path={routes.success} element={<Success />} />
          <Route
            path={routes.paystackPayment}
            element={<PaymentVerification />}
          />
          {(authServices.isAuthenticated() || isLoggedIn) && (
            <>
              <Route
                path={routes.onboardDetails}
                element={<OnboardingDetails />}
              />
              <Route
                path={routes.onboardService}
                element={<OnboardingServices />}
              />
              <Route
                path={routes.onboardOthers}
                element={<OnboardingOthers />}
              />
              <Route path={routes.dashboard} element={<AppDashboard />} />
              <Route path={routes.appointment} element={<AppAppointment />} />
              <Route path={routes.services} element={<AppServices />} />
              <Route path={routes.patient} element={<AppPatients />} />
              <Route path={routes.calendar} element={<AppCalendar />} />
              <Route path={routes.wallet} element={<AppWallet />} />
              <Route path={routes.settings} element={<AppSettings />} />
              <Route path={routes.transactions} element={<AppTransactions />} />
            </>
          )}
          <Route path={routes.userServices} element={<UserServices />} />
        </Routes>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          limit={2}
        />
      </Layout>
    </Router>
  );
};

export default App;
