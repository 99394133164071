import { useGetScreen } from "./app";

export function useOboardingtyles() {
  const {
    isMobilePortrait,
    isMediumLandscape,
    isMediumPortrait,
    isMobileLandscape,
  } = useGetScreen();

  const styles = {
    onboardingBtnControl: "w-40 mb-4 start-20",
    logoWidth: 10,
    logoInnerWidth: 30,
    uploaderWidth: "w-50 mx-auto d-block",
  };

  if (isMobilePortrait)
    return {
      ...styles,
      onboardingBtnControl: "w-90 mb-3",
      logoWidth: 15,
      uploaderWidth: "w-100",
    };
  if (isMediumPortrait)
    return {
      ...styles,
      onboardingBtnControl: "w-50 start-20",
      logoWidth: 20,
      uploaderWidth: "w-100 mx-auto",
    };
  if (isMobileLandscape)
    return {
      ...styles,
      onboardingBtnControl: "w-90 mb-3",
      logoWidth: 20,
    };
  if (isMediumLandscape)
    return {
      ...styles,
      onboardingBtnControl: "w-90 mb-3",
      logoWidth: 20,
    };

  return styles;
}

export function useDashboardStyles() {
  const { isMobilePortrait, isMediumPortrait } = useGetScreen();

  const styles = {
    header: {
      col1: {
        container: "ms-5",
        btnFontSize: 14,
        width: 90,
        colClass: "",
        welcomeText: "fs-1",
      },
      col2: {
        container: "bg-white rounded-4 w-80 mt-5 mx-auto",
        img: "w-50 d-block mx-auto pt-2",
        height: 90,
      },
    },
    section2: {
      rowContainer: "mt-1",
      innerCol: "",
      icon: "w-100 pt-3",
      style: {
        overflowX: "hidden",
      },
    },
    table: {
      fullName: {
        style: {
          lineHeight: 0.5,
          fontSize: 13,
          paddingTop: 10,
        },
      },
      phone: {
        style: {
          lineHeight: 0.5,
          fontSize: 13,
        },
      },
      theader: {
        nameClass: "",
      },
    },
  };

  if (isMobilePortrait) {
    return {
      ...styles,
      header: {
        col1: {
          container: "",
          btnFontSize: 14,
          width: 90,
          colClass: "w-75",
          welcomeText: "fs-1",
        },
        col2: {
          container: "bg-white rounded-4 w-40 mt-5",
          img: "w-50 d-block mx-auto pt-2",
          height: 40,
        },
      },
      section2: {
        rowContainer: "mt-1 flex-nowrap scrollbar-x",
        innerCol: "w-20 mt-3 ms-4",
        icon: "w-90 d-block mx-auto",
        style: {
          overflowX: "scroll",
        },
      },
      table: {
        fullName: {
          name: "Name",
          style: {
            lineHeight: 1.5,
            fontSize: 13,
            paddingTop: 10,
          },
        },
        phone: {
          style: {
            lineHeight: 0.5,
            fontSize: 13,
          },
        },
        theader: {
          nameClass: "fs-8",
        },
      },
    };
  }

  if (isMediumPortrait) {
    return {
      ...styles,
      header: {
        col1: {
          container: "ms-5",
          btnFontSize: 12,
          width: 90,
          colClass: "",
          welcomeText: "fs-1",
        },
        col2: {
          container: "bg-white rounded-4 w-80 mt-5 mx-auto",
          img: "w-50 d-block mx-auto pt-2",
          height: 90,
        },
      },
      section2_icon: "w-25 d-block mx-auto",
      section2: {
        rowContainer: "mt-1 flex-nowrap scrollbar-x",
        icon: "w-25 d-block mx-auto pt-3",
        style: {
          overflowX: "scroll",
        },
      },
    };
  }

  return styles;
}

export function useLayoutStyles() {
  const {
    isMobilePortrait,
    isMediumLandscape,
    isMediumPortrait,
    isMobileLandscape,
  } = useGetScreen();

  const styles = {
    userMenu: {
      hideName: true,
      position: "position-absolute top-0 user-menu mt-2",
      width: 120,
      height: 40,
    },
    notification: "position-absolute top-0 end-20 mt-2",
    headerName: {
      name: "ms-5",
      position: "position-absolute start-0 mt-3",
    },
    headerIcon: {
      position: "absolute",
      top: 15,
      left: 20,
      size: 30,
    },
    sideBar: {
      logo: 60,
    },
    sideBarLinks: {
      stack_2: {
        div_2: {
          con: "mt-2 ps-4",
        },
      },
    },
  };

  if (isMobilePortrait)
    return {
      ...styles,
      userMenu: {
        hideName: false,
        position: "position-absolute top-1 end-10 user-menu mt-2",
        width: 40,
        height: 40,
      },
      notification: "position-absolute top-0 end-20 mt-2",
      headerName: {
        name: "ms-0",
        position: "position-absolute start-25 mt-3",
      },
      sideBar: {
        logo: 20,
      },
      sideBarLinks: {
        stack_2: {
          div_2: {
            con: "mt-2 ps-2",
          },
        },
      },
    };
  if (isMediumPortrait)
    return {
      ...styles,
    };
  if (isMobileLandscape)
    return {
      ...styles,
    };
  if (isMediumLandscape)
    return {
      ...styles,
    };

  return styles;
}

export function useAppointmentStyles() {
  const {
    isMobilePortrait,
    isMediumLandscape,
    isMediumPortrait,
    isMobileLandscape,
  } = useGetScreen();

  const styles = {
    tab: {},
  };

  if (isMobilePortrait) return {};
  if (isMediumPortrait) return {};
  if (isMobileLandscape) return {};
  if (isMediumLandscape) return {};

  return styles;
}

export function usePlacementStyles() {
  const {
    isMobilePortrait,
    isMediumLandscape,
    isMediumPortrait,
    isMobileLandscape,
  } = useGetScreen();

  const styles = {
    containerPosition: "position-relative",
    position: "position-absolute",
    top: {
      left: 0,
      top: 0,
    },
    top_end: {
      right: 0,
      top: 0,
    },
    bottom: {
      bottom: 0,
      left: 0,
    },
    bottom_end: {
      right: 0,
      bottom: 0,
    },
    bottom_middle: {
      bottom: 0,
      left: "50%",
    },
  };

  if (isMobilePortrait)
    return {
      ...styles,
    };
  if (isMediumPortrait)
    return {
      ...styles,
    };
  if (isMobileLandscape)
    return {
      ...styles,
    };
  if (isMediumLandscape)
    return {
      ...styles,
    };

  return styles;
}

export function useWalletStyles() {
  const {
    isMobilePortrait,
    isMediumLandscape,
    isMediumPortrait,
    isMobileLandscape,
  } = useGetScreen();

  const styles = {
    balance: {
      stack_1: {
        direction: "horizontal",
        class: "p-3 rounded-3 mt-2 w-100 mx-auto",
      },
      stack_2: {
        direction: "horizontal",
        class: "p-3 rounded-3 mx-auto",
        style: {
          backgroundColor: "#fdf1f5",
          height: 120,
          width: "80%",
        },
      },
    },
    bank_card: {
      selected_icon: "position-absolute start-30 top-0 w-15",
    },
  };

  if (isMobilePortrait)
    return {
      ...styles,
      balance: {
        stack_1: {
          direction: "vertical",
          class: "p-1 rounded-3 mt-2 w-95 mx-auto",
        },
        stack_2: {
          direction: "horizontal",
          class: "p-3 rounded-3 mx-auto",
          style: {
            backgroundColor: "#fdf1f5",
            height: 120,
            width: "95%",
          },
        },
      },
      bank_card: { selected_icon: "position-absolute start-20 top-0 w-10" },
    };
  if (isMediumPortrait)
    return {
      ...styles,
      bank_card: { selected_icon: "position-absolute start-15 top-0 w-5" },
    };
  if (isMobileLandscape) return { ...styles };
  if (isMediumLandscape) return { ...styles };

  return styles;
}

export function useCalendarStyles() {
  const {
    isMobilePortrait,
    isMediumLandscape,
    isMediumPortrait,
    isMobileLandscape,
  } = useGetScreen();

  const styles = {
    cal_col: {
      className: "ps-3",
    },
  };

  if (isMobilePortrait)
    return {
      ...styles,
      cal_col: {
        className: "",
      },
    };
  if (isMediumPortrait)
    return {
      ...styles,
    };
  if (isMobileLandscape)
    return {
      ...styles,
    };
  if (isMediumLandscape) return { ...styles };

  return styles;
}

export function useAuthStyles() {
  const {
    isMobilePortrait,
    isMediumLandscape,
    isMediumPortrait,
    isMobileLandscape,
  } = useGetScreen();

  const styles = {
    form: "w-65 mx-auto",
  };

  if (isMobilePortrait)
    return {
      ...styles,
      form: "w-82 mx-auto",
    };
  if (isMediumPortrait)
    return {
      ...styles,
      form: "w-45 mx-auto",
    };
  if (isMobileLandscape)
    return {
      ...styles,
    };
  if (isMediumLandscape)
    return {
      ...styles,
    };

  return styles;
}

// export function useLayoutStyles() {
//   const {
//     isMobilePortrait,
//     isMediumLandscape,
//     isMediumPortrait,
//     isMobileLandscape,
//   } = useGetScreen();

//   const styles = {}

//   if (isMobilePortrait) return {};
//   if (isMediumPortrait) return {};
//   if (isMobileLandscape) return {};
//   if (isMediumLandscape) return {};

//   return styles
// }
