import { atom } from "jotai";
import {
  AppointmentDto,
  BankListDto,
  DashboardDataDto,
  PaymentDetailsDto,
} from "../@types/dto";
import {
  ServicesState,
  AppointmentState,
  LoginState,
  RegisterState,
  DoctorInfo,
  PatientState,
  BookUserAppointment,
  EnquiriesState,
  TransactionsState,
  DoctorNotificationState,
} from "../@types/state";

export const serviceShowState = atom<boolean>(false);
export const patientShowState = atom<boolean>(false);
export const servicesState = atom<ServicesState[]>([]);
export const appointmentState = atom<AppointmentState[]>([]);
export const appointmentDataState = atom<AppointmentDto[]>([]);
export const doctorInfoState = atom<Partial<DoctorInfo>>({});
export const transactionState = atom<Partial<TransactionsState[]>>([]);
export const appointmentSidebar = atom(false);
export const allBankSidebar = atom(false);
export const userOnboardingSidebar = atom(false);
export const userDetailsSidebar = atom(false);
export const addBankSidebar = atom(false);
export const enquiriesSidebar = atom(false);
export const renderNullState = atom(false);
export const isLoggedInState = atom(false);
export const bookAppointmentShowState = atom(false);
export const notificationSidebarState = atom(false);
export const pageLoadingState = atom(false);
export const leftSidebarState = atom(false);
export const rightSidebarState = atom(false);
export const doctorsBankListState = atom<BankListDto[]>([]);
export const doctorNotificationState = atom<DoctorNotificationState[]>([]);
export const appointmentDetailsState = atom<AppointmentDto>({});
export const dashboardDataState = atom<Partial<DashboardDataDto>>({});
export const anyDataState = atom<any>(null);
export const patientState = atom<PatientState[]>([]);
export const paymentDetailsState = atom<PaymentDetailsDto>({});
export const paymentDetailsSidebar = atom(false);
export const notificationSidebarDetailsState = atom({
  show: false,
  type: "details",
});
export const walletAccountSate = atom({
  availableBalance: "",
  pendingBalance: "",
});

export const addAppointmentDataState = atom<AppointmentDto>({
  appointmentDate: "",
  email: "",
  phone: "",
  patientId: 0,
  serviceId: 0,
});
export const loginState = atom<LoginState>({
  email: "",
  password: "",
  strategy: "local",
});
export const registerState = atom<RegisterState>({
  email: "",
  password: "",
  fullName: "",
  phone: "",
});
export const detailsOnboardingState = atom({
  fullName: "",
  businessName: "",
  email: "",
  phone: "",
  gender: "",
  state: "",
});
export const otherDetailsState = atom({
  clinicAddress: "",
  doctorBio: "",
  instagram: "",
  twitter: "",
  facebook: "",
  avatar: "",
});
export const editServicesState = atom<ServicesState>({
  discountApply: "",
  doctorAvailability: "",
  id: "",
  originalPrice: "",
  serviceName: "",
  workingHours: "",
});
export const addPatientState = atom<PatientState>({
  email: "",
  fullName: "",
  gender: "",
  phone: "",
  picture: "",
  id: "",
});
export const enquiryState = atom<EnquiriesState>({
  email: "",
  phone: "",
  note: "",
  fullName: "",
  doctorId: "",
});
export const bookAppointmentState = atom<BookUserAppointment>({
  appointmentDate: "",
  appointmentTime: "",
  dob: "",
  doctorId: "",
  email: "",
  fullName: "",
  gender: "",
  patientNote: "",
  phone: "",
  serviceId: 0,
  serviceName: "",
});
