import { useAtom } from "jotai";
import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { AddBankSidebarProps } from "../../../@types/props";
import { assets } from "../../../assets/assets";
import { useNotification } from "../../../hooks/app";
import useAtomState from "../../../hooks/state";
import transactionService from "../../../services/transaction.service";
import { addBankSidebar } from "../../../utils/state";
import CustomButton from "../../customButton";
import Input from "../../input";
import Sidebar from "../sidebar";

const AddBank: React.FC<AddBankSidebarProps> = ({ show }) => {
  const [, setAddBankShow] = useAtom(addBankSidebar);
  const [allBanks, setAllBanks] = React.useState([]);
  const [addBank, setAddBank] = React.useState({
    bankCode: "",
    accountNumber: "",
  });
  const { error, info, setLoading, loading, success } = useNotification();
  const { setDoctorsBankList } = useAtomState();

  React.useEffect(() => {
    transactionService.getAllBanks().then((banks) => {
      setAllBanks(banks);
    });
  }, []);

  return (
    <Sidebar show={show} handleClose={setAddBankShow} placement="end">
      <Row>
        <Col lg={2}>
          <img
            onClick={() => setAddBankShow(false)}
            src={assets.left_icon}
            alt="left-icon"
            width={45}
            height={45}
            className="cursor-p"
          />
        </Col>
        <Col>
          <h3 className="pt-1">Add bank</h3>
        </Col>
      </Row>
      <hr />
      <Container>
        <form>
          <Input
            containerClass="ms-1 mt-3"
            type={"number"}
            label="Account number"
            value={addBank.accountNumber}
            onChange={(accountNumber) => {
              setAddBank({
                ...addBank,
                accountNumber,
              });
            }}
          />
          <Input
            containerClass="ms-2 mt-3"
            type={"select"}
            label="Add bank"
            className="mt-3"
            options={allBanks}
            value={addBank.bankCode}
            onChange={(bankCode) => {
              setAddBank({
                ...addBank,
                bankCode,
              });
            }}
          />
          <CustomButton
            isLoading={loading}
            loadingText="Loading"
            onClick={async () => {
              const res = await transactionService.addBank(
                addBank,
                {
                  error,
                  info,
                  setLoading,
                  success,
                },
                setDoctorsBankList
              );
              if (res) {
                setAddBankShow(false);
              }
            }}
            type="button"
            className="mt-5"
            width={60}
            center
          >
            Save
          </CustomButton>
        </form>
      </Container>
    </Sidebar>
  );
};

export default AddBank;
