import React from "react";
import { Stack } from "react-bootstrap";
import { SideBar } from ".";
import useAtomState from "../../hooks/state";
import appServices from "../../services/app.service";
import { NotificationCard } from "../notificationMenu";
import ScrollView from "../scrollview";

const NotificationsSidebar: React.FC = () => {
  const {
    showNotificationSidebar,
    setShowNotificationSidebar,
    setDoctorNotification,
    doctorNotification,
  } = useAtomState();

  React.useEffect(() => {
    appServices.getDoctorNotification().then((data) => {
      if (data) {
        setDoctorNotification(data);
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <SideBar
      show={showNotificationSidebar}
      handleClose={setShowNotificationSidebar}
      placement="bottom"
    >
      <h5 className="text-center pb-3 fs-6">Notifications</h5>
      <ScrollView isMobilePortrait="80vh" className="w-95 mx-auto">
        <Stack direction="vertical">
          {doctorNotification?.map((data, ind) => {
            return (
              <NotificationCard
                key={ind}
                id={`${ind}`}
                action={data.action}
                patientData={JSON.parse(data.patientData as any)}
                subject={data.subject}
                notificationMessage={data.notificationMessage}
                createdAt={data.createdAt}
                doctorData={JSON.parse(data.doctorData as any)}
                updatedAt={data.updatedAt}
                userId={data.userId}
                className="mt-3"
              />
            );
          })}
        </Stack>
      </ScrollView>
    </SideBar>
  );
};

export default NotificationsSidebar;
