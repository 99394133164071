import { Notifications, StorageData } from "../@types";
import { LoginState, RegisterState, VerifyOtpState } from "../@types/state";
import { routes } from "../utils/router";
import BaseService from "./base.service";
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";

class AuthServices extends BaseService {
  static OnInit() {
    initializeApp({
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_ID,
      appId: process.env.REACT_APP_FIREBASE_API_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    });
  }

  /**
   * Method called for internal authentication with google
   * @returns auth response
   */
  protected async googleAuth() {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    const authRep = await signInWithPopup(auth, provider);
    const credential = GoogleAuthProvider.credentialFromResult(authRep);
    return {
      credential,
      user: authRep.user,
    };
  }

  public async logInWithGoogle(notifications: Notifications) {
    const auth = await this.googleAuth();
    const data = {
      userEmail: auth.user.email,
      loginToken: auth.credential?.accessToken,
    };
    const res = await this.mergePostResponse(
      data,
      "google/signin",
      notifications,
      "Sign in successful"
    );
    return res.data;
  }

  public async registerWithGoogle(notifications: Notifications) {
    const auth = await this.googleAuth();
    const data = {
      fullName: auth.user.displayName,
      userEmail: auth.user.email,
      phone: auth.user.phoneNumber,
      profileImage: auth.user.photoURL,
    };
    const resp = await this.mergePostResponse(
      data,
      "google/signup-with-google",
      notifications,
      "Sign up successful",
      { skipCheck: ["phone", "profileImage"] }
    );
    return resp?.data;
  }

  /**
   * Function called to handle the onboarding process if false
   * @param currentPath
   * @param detailsOnboardingState
   * @param others
   * @param notifications
   * @param refetch
   * @returns
   */
  public async onboardingSteps(
    currentPath?: string,
    detailsOnboardingState?: any,
    others?: any,
    notifications?: Notifications,
    refetch?: () => void
  ) {
    if (routes.onboardDetails === currentPath) {
      this.getUserProfile();
      refetch?.();
      const res = await this.updateUserProfile(
        { ...detailsOnboardingState },
        notifications
      );
      if (res) {
        return routes.onboardService;
      }
      return;
    }
    if (routes.onboardService === currentPath) {
      return routes.onboardOthers;
    }
    if (routes.onboardOthers === currentPath) {
      notifications?.setLoading?.(true);
      others.avatar = await this.imageUploader(others.avatar);
      const res = await this.updateUserProfile({ ...others }, notifications);
      if (res) {
        return routes.dashboard;
      }
      return;
    }

    return routes.onboardDetails;
  }

  /**
   * Function used to get the current stage a user is while onboarding, but returns
   * dashboard if all stages are true
   * @param user
   * @param authPriority
   * @returns
   */
  public authenticationStages(user?: StorageData, authPriority?: boolean) {
    const data = this.getStoredData();

    if (
      !Boolean(data.user?.onboardingStages?.isVerified) &&
      !Boolean(data.user?.onboardingStages?.personalDetailsCompleted) &&
      !Boolean(data.user?.onboardingStages?.addedService) &&
      !Boolean(data.user?.onboardingStages?.otherDetails) &&
      !authPriority
    )
      return routes.login;

    if (
      Boolean(data.user?.onboardingStages?.isVerified) &&
      Boolean(data.user?.onboardingStages?.personalDetailsCompleted) &&
      Boolean(data.user?.onboardingStages?.addedService) &&
      Boolean(data.user?.onboardingStages?.otherDetails)
    )
      return routes.dashboard;

    if (!Boolean(data.user?.onboardingStages?.isVerified) && authPriority)
      return routes.otp;

    if (!Boolean(data.user?.onboardingStages?.personalDetailsCompleted))
      return routes.onboardDetails;

    if (!Boolean(data.user?.onboardingStages?.addedService))
      return routes.onboardService;

    if (!Boolean(data.user?.onboardingStages?.otherDetails))
      return routes.onboardOthers;
  }

  /**
   * Function called to login a user into the system
   * @param payload
   * @param notifications
   * @returns
   */
  public async login(payload: LoginState, notifications?: Notifications) {
    try {
      notifications?.setLoading?.(true);
      const check = await this.strictCheck({
        data: payload,
        customKey: {
          email: "Email address",
          password: "Password",
        },
      });
      if (check?.data) {
        const res = await this.fetchHandler({
          path: "authentication",
          body: check.data,
        });
        const resp = await this.handleResponse(res);
        if (resp) {
          notifications?.setLoading?.(false);
          notifications?.success?.("Login successful");
          return resp;
        }
      }

      notifications?.setLoading?.(false);
      notifications?.warning?.(check?.error as string);
    } catch (error: any) {
      console.error(error);

      notifications?.setLoading?.(false);
      notifications?.error?.(error?.message as string);
    }
  }

  /**
   * Function called to register a user into the system
   * @param payload
   * @param notifications
   * @returns
   */
  public async register(payload: RegisterState, notifications?: Notifications) {
    try {
      notifications?.setLoading?.(true);
      const check = await this.strictCheck({
        data: payload,
        customKey: {
          email: "Email address",
          fullName: "Full name",
          password: "Password",
          phone: "Phone number",
        },
      });
      if (check?.data) {
        const res = await this.fetchHandler({
          path: "users",
          body: check.data,
        });
        const resp = await this.handleResponse(res);
        if (resp) {
          notifications?.setLoading?.(false);
          notifications?.success?.("Account created successfully");
          return resp;
        }
      }

      notifications?.setLoading?.(false);
      notifications?.warning?.(check?.error as string);
    } catch (error: any) {
      console.error(error);

      notifications?.setLoading?.(false);
      notifications?.error?.(error?.message);
    }
  }

  /**
   * Function called to verify user account with otp
   * @param payload
   * @param notifications
   * @returns
   */
  public async verifyUserAccount(
    payload: VerifyOtpState,
    notifications?: Notifications
  ) {
    const res = await this.mergePostResponse(
      payload,
      "verify-user-account",
      notifications
    );
    return res;
  }

  /**
   * Updates user profile
   * @param payload
   * @param notifications
   * @returns
   */
  public async updateUserProfile(
    payload: any,
    notifications?: Notifications,
    skip?: string[] | undefined
  ) {
    try {
      let arraySkip;
      notifications?.setLoading?.(true);
      if (skip) arraySkip = [...skip];
      else arraySkip = ["none"];
      const check = await this.strictCheck({
        data: payload,
        customKey: { avatar: "Profile picture" },
        config: {
          skipCheck: ["instagram", "twitter", "facebook", ...arraySkip],
        },
      });
      if (check?.data) {
        const res = await this.fetchHandler({
          path: "user-profile",
          body: check.data,
          method: "PATCH",
        });
        const resp = await this.handleResponse(res);
        if (resp) {
          notifications?.success?.("Profile updated successfully");
          notifications?.setLoading?.(false);
          return resp;
        }
      }
      notifications?.setLoading?.(false);
      notifications?.warning?.(check?.error as string);
    } catch (error: any) {
      notifications?.setLoading?.(false);
      notifications?.error?.(error?.message);
    }
  }

  /**
   * Gets current logged in user profile
   * @param cache
   * @returns
   */
  public async getUserProfile(): Promise<StorageData> {
    const res = await this.fetchHandler({
      path: "user-profile/me",
      method: "GET",
    });
    const resp = await this.handleResponse(res);
    this.storeData({ user: resp.data });
    return resp;
  }

  public async initiateResetPassword(
    email: string,
    notifications?: Notifications
  ) {
    try {
      let frontEndUrl = this.getBaseUrl(routes.resetPassword);
      window.localStorage.setItem("tempMail", email);
      const res = await this.mergePostResponse(
        { email, frontEndUrl },
        "forgotPassword/initiate-reset-pwd",
        notifications,
        "Reset link sent to email"
      );
      return res.data;
    } catch (error) {
      console.log(error);
    }
  }

  public async setNewPassword(
    data: {
      password: string;
      confirmPassword: string;
      email: string;
      code: string;
    },
    notifications: Notifications
  ) {
    const { password, confirmPassword, email, code } = data;
    try {
      if (password === confirmPassword) {
        const check = await this.strictCheck({
          data: { password, email, code },
        });
        if (check?.data) {
          const res = await this.mergePostResponse(
            { email, password, code },
            "forgotPassword/reset-user-password",
            notifications,
            "Password changed successfully"
          );
          window.localStorage.removeItem("tempMail");
          return res.data;
        }
      } else notifications.warning?.("Password not similar");
    } catch (error) {}
  }

  public getName = () => {
    const [firstName, lastName] = this.getStoredData().user?.fullName?.split(
      " "
    ) as any;
    return {
      firstName,
      lastName,
      name: `${firstName} ${lastName || ""}`,
    };
  };

  public logout(navigate: (data: string) => void) {
    const storage = localStorage as Storage;
    storage.clear();
    navigate(routes.login);
  }
}
const authServices = new AuthServices();
export const OnInit = AuthServices.OnInit;
export default authServices;
