import React, { Fragment } from "react";
import { InputProps } from "../@types/props";
import CurrencyFormat from "react-currency-format";
import Select, { components } from "react-select";

const Input: React.FC<InputProps> = ({
  label,
  type = "text",
  placeholder,
  labelFor,
  id,
  icon,
  className,
  options,
  onChange,
  value,
  maxLength,
  required,
  style,
  disabled = false,
  formatNumber = "",
  onFocus,
  selectType = "normal",
  selectInputChange,
  selectStyle,
  containerClass,
  iconClass,
}) => {
  const [typeValue, setTypeValue] = React.useState(type);
  const onChangeHandler = (data?: any) => {
    onChange?.(data);
  };

  const handleIconEvent = () => {
    if (type === "password") {
      if (typeValue === "password") setTypeValue("text");
      else setTypeValue("password");
    }
  };

  return (
    <Fragment>
      {type === "select" && (
        <div className={`${className ? className : ""}`}>
          {label && (
            <label
              htmlFor={labelFor}
              className={`fs-6 pb-1 ${className ? className : ""}`}
            >
              {label}
            </label>
          )}
          <Select
            options={options}
            className={`rounded${className ? className : ""} log-input`}
            onChange={(e) => onChangeHandler(e?.value)}
            placeholder={placeholder}
            isDisabled={disabled}
            defaultInputValue={value}
            onInputChange={selectInputChange}
            components={{
              Option: ({ children, ...props }) => {
                if (selectType === "image") {
                  return (
                    <components.Option {...props}>
                      <img
                        src={props.data.img}
                        width={35}
                        height={35}
                        alt="patient"
                        style={{ borderRadius: "50%" }}
                      />
                      <span className="ps-2 d-inline-block">{children}</span>
                    </components.Option>
                  );
                }
                return (
                  <components.Option {...props}> {children}</components.Option>
                );
              },
            }}
            styles={{
              control: (styles) => {
                if (selectType === "colored-pink") {
                  return {
                    ...styles,
                    backgroundColor: "#fbe8ef",
                    border: 0,
                    paddingTop: 9,
                    borderRadius: 50,
                    color: "black",
                    paddingRight: 17,
                    padding: 10,
                  };
                }
                return {
                  ...styles,
                  borderWidth: 0,
                  backgroundColor: "#f7f7f8",
                  paddingTop: 4,
                  height: "100%",
                  width: "100%",
                };
              },
              menu: (styles) => {
                if (selectType === "normal") {
                  return {
                    ...styles,
                    border: "0.1rem solid #D41A5C",
                    backgroundColor: "white",
                  };
                }
                return { ...styles };
              },
              option: (styles) => {
                if (selectType === "bordered-btn") {
                  return {
                    ...styles,
                    border: "0.1rem solid #D7D7D7",
                    backgroundColor: "white",
                    padding: ".8rem",
                    width: "90%",
                    margin: "auto",
                    marginTop: ".8rem",
                    color: "black",
                    borderRadius: 11,
                    ":hover": { backgroundColor: "#FFF5F9" },
                  };
                }
                if (selectType === "image") {
                  return {
                    ...styles,
                    color: "black",
                    marginTop: 3,
                    ":hover": { backgroundColor: "white" },
                  };
                }
                return { ...styles, ...selectStyle };
              },
            }}
          />
        </div>
      )}
      {type === "textarea" && (
        <div
          className={`${
            containerClass ? containerClass : ""
          } position-relative`}
        >
          {label && (
            <label htmlFor={labelFor} className="fs-6 pb-1">
              {label}
            </label>
          )}
          <textarea
            id={id}
            value={value || ""}
            required={required}
            placeholder={placeholder}
            onChange={(e) => onChangeHandler(e.target.value)}
            className={`${className ? className : ""}  log-textarea`}
          ></textarea>
        </div>
      )}
      {type === "number" && (
        <div
          className={`${
            containerClass ? containerClass : ""
          } position-relative`}
        >
          {label && (
            <label htmlFor={labelFor} className="fs-6 pb-1">
              {label}
            </label>
          )}
          {icon && (
            <label
              onClick={handleIconEvent}
              htmlFor={labelFor}
              className={`${iconClass ? iconClass : "input-icon"} d-block`}
            >
              {icon}
            </label>
          )}
          <CurrencyFormat
            thousandSeparator={formatNumber}
            inputMode="numeric"
            id={id}
            value={`${value}` || ""}
            required={required}
            className={`${className ? className : ""} log-input rounded`}
            placeholder={placeholder}
            onChange={(e: any) => onChangeHandler(e.target.value)}
            maxLength={maxLength}
            style={{ paddingLeft: 8, ...style }}
            disabled={disabled}
          />
        </div>
      )}
      {type !== "select" && type !== "textarea" && type !== "number" && (
        <div
          className={`${
            containerClass ? containerClass : ""
          } position-relative`}
        >
          {label && (
            <label htmlFor={labelFor} className="fs-6 pb-1 d-block">
              {label}
            </label>
          )}
          {icon && (
            <label
              onClick={handleIconEvent}
              htmlFor={labelFor}
              className={`${iconClass ? iconClass : "input-icon"}`}
            >
              {icon}
            </label>
          )}
          <input
            type={typeValue}
            id={id}
            onFocus={onFocus}
            value={value || ""}
            required={required}
            className={`rounded ${className ? className : ""} log-input`}
            placeholder={placeholder}
            onChange={(e) => onChangeHandler(e.target.value)}
            maxLength={maxLength}
            style={{ paddingLeft: 8, ...style }}
            disabled={disabled}
          />
        </div>
      )}
    </Fragment>
  );
};

export default Input;
