import { SetStateAction } from "jotai/core/typeUtils";
import { Notifications } from "../@types";
import {
  AppointmentDto,
  DashboardDataDto,
  EnquiryDto,
  DoctorNotificationDto,
} from "../@types/dto";
import {
  BookUserAppointment,
  PatientState,
  ServicesState,
} from "../@types/state";
import BaseService from "./base.service";
import transactionService from "./transaction.service";

class AppServices extends BaseService {
  /**
   * Get all country states
   * @returns states
   */
  public async getStates() {
    const res = await this.fetchHandler({
      path: "country-states",
      method: "GET",
    });
    const resp = await this.handleResponse(res);
    return resp.map((data: any) => {
      return {
        label: data.name,
        name: data.name,
        value: data.name,
      };
    });
  }

  /**
   * Adds services for doctor
   * @param payload
   * @param notification
   * @returns
   */
  public async addDoctorService(
    payload: ServicesState,
    notification?: Notifications
  ) {
    payload.originalPrice = `${payload.originalPrice}`.replaceAll(",", "");
    const res = await this.mergePostResponse(
      payload,
      "doctor-services",
      notification,
      "Service added successfully",
      { skipCheck: ["currencySign", "id", "servicePrice"] }
    );
    return res;
  }

  /**
   * Gets doctors services
   * @returns
   */
  public async getDoctorService() {
    try {
      const res = await this.fetchHandler({
        path: "my-services/me",
        method: "GET",
      });
      const resp = await this.handleResponse(res);
      return resp?.data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  /**
   * Operation to get the name and id for doctors services
   * @returns
   */
  public async getDoctorServiceNameAndId(setData?: (update?: any) => void) {
    const res = await this.getDoctorService();
    let data = [...res];
    data = data.map(({ serviceName, id }) => {
      return { name: serviceName, label: serviceName, value: id };
    });
    setData?.(data);
    return data;
  }

  /**
   * Gets doctors availability
   * @returns
   */
  public async getDoctorAvailability(setData?: (update?: any) => void) {
    try {
      const res = await this.fetchHandler({
        path: "doctor-availabilty",
        method: "GET",
      });
      let resp = await this.handleResponse(res);
      resp = resp.map((data: any) => {
        return { value: data.value, name: data.value, label: data.value };
      });
      setData?.(resp);
      return resp;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  /**
   * Gets doctors working hours
   * @returns
   */
  public async getDoctorWorkingHours(setData?: (update?: any) => void) {
    try {
      const res = await this.fetchHandler({
        path: "working-hour",
        method: "GET",
      });
      let resp = await this.handleResponse(res);
      resp = resp.map((data: any) => {
        return { value: data.value, name: data.value, label: data.value };
      });
      setData?.(resp);
      return resp;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  /**
   * Function called when a doctor wants to edit a service
   * @param payload
   * @param notification
   * @returns
   */
  public async editDoctorService(
    payload: ServicesState,
    notification?: Notifications
  ) {
    try {
      notification?.setLoading?.(true);
      delete payload.type;
      const id = payload.id;
      payload.servicePrice = `${payload.servicePrice}`.replaceAll(",", "");
      payload.originalPrice = `${payload.originalPrice}`.replaceAll(",", "");
      const res = await this.fetchHandler({
        path: `doctor-services/${id}`,
        body: payload,
        method: "PATCH",
      });
      const resp = await this.handleResponse(res);
      if (resp) {
        notification?.success?.("Service edited successfully");
        notification?.setLoading?.(false);
        return resp;
      }
      notification?.setLoading?.(false);
    } catch (error: any) {
      notification?.setLoading?.(false);
      notification?.error?.(error?.message);
    }
  }

  /**
   * Function called when a doctor wants to delete a service
   * @param payload
   * @param notification
   * @returns
   */
  public async deleteDoctorService(id: string, notification: Notifications) {
    try {
      notification?.setLoading?.(true);
      const res = await this.fetchHandler({
        path: `doctor-services/${id}`,
        method: "DELETE",
      });
      const resp = await this.handleResponse(res);
      if (resp) {
        notification?.success?.("Service deleted successfully");
        notification?.setLoading?.(false);
        return resp;
      }
    } catch (error: any) {
      notification?.setLoading?.(false);
      notification?.error?.(error?.message);
    }
  }

  /**
   * Function called internally to refetch doctors services
   * @param setData
   */
  public async refetchService(
    setData: (update: SetStateAction<ServicesState[]>) => void
  ) {
    const res = await this.getDoctorService();
    setData(res);
  }

  /**
   * Function called internally to refetch doctors patient
   * @param setData
   */
  public async refetchPatients(
    setData: (update: SetStateAction<PatientState[]>) => void
  ) {
    const res = await this.getPatients();
    setData(res?.data);
  }

  /**
   * Function called internally to refetch doctors appointment
   * @param setData
   */
  public async refetchAppointment(
    setData: (update: SetStateAction<AppointmentDto[]>) => void
  ) {
    const res = await this.getDoctorsAppointment();
    setData(res?.data);
  }

  /**
   * Function called when a doctor wants to add a patient
   * @param payload
   * @param notification
   * @returns
   */
  public async addPatients(
    payload: PatientState,
    notifications?: Notifications
  ) {
    delete payload.id;
    notifications?.setLoading?.(true);
    payload.picture = await this.imageUploader(payload.picture);
    const res = await this.mergePostResponse(
      payload,
      "patients",
      notifications,
      "Patient added successfully"
    );
    return res;
  }

  public async editPatient(
    payload: PatientState,
    notifications?: Notifications
  ) {
    try {
      notifications?.setLoading?.(true);
      payload.picture = await this.imageUploader(payload.picture);
      const check = await this.strictCheck({ data: payload });
      if (check?.data) {
        const res = await this.fetchHandler({
          path: `patients/${payload.id}`,
          body: check?.data,
          method: "PATCH",
        });
        const resp = await this.handleResponse(res);
        notifications?.setLoading?.(false);
        notifications?.success?.("Patient edited successfully");
        return resp;
      }
      notifications?.warning?.(check?.error as string);
      notifications?.setLoading?.(false);
    } catch (error: any) {
      notifications?.setLoading?.(false);
      notifications?.error?.(error?.message);
    }
  }

  /**
   * Function called to fetch all doctors patient
   * @returns
   */
  public async getPatients(query?: any) {
    try {
      const res = await this.fetchHandler({
        path: `my-patients/me?${query?.limit ? `$limit=${query?.limit}` : ""}${
          query?.skip ? `&$skip=${query?.skip}` : ""
        }`,
        method: "GET",
      });
      const resp = await this.handleResponse(res);

      return {
        data: resp.data.data,
        total: resp.data.total,
        limit: resp.data.limit,
        skip: resp.data.skip,
      };
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  /**
   * Function called to get doctors information
   * @param id
   * @returns
   */
  public async getDoctorInfo(id: string, setData?: (update?: any) => void) {
    try {
      const res = await this.fetchHandler({
        path: `patient/service-info/docService?doctorId=${id}`,
        method: "GET",
      });
      const resp = await this.handleResponse(res);
      setData?.(resp.data);
      return resp.data;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  /**
   * Function called by patients to book a doctors appointment
   * @param payload
   * @param notifications
   * @returns
   */
  public async bookAppointment(
    payload: BookUserAppointment,
    notifications?: Notifications
  ) {
    const res = await this.mergePostResponse(
      payload,
      "book-appointment",
      notifications,
      "Appointment booked successfully"
    );
    if (res) {
      const url = res.data.authorization_url;
      window.open(
        url,
        "_blank",
        "location=yes,height=570,width=520,scrollbars=yes,status=yes"
      );
    }
    return res.data;
  }

  /**
   * Function called to get all doctors appointment also with filter option
   * @param filter
   * @returns
   */
  public async getDoctorsAppointment(
    filter?: "recent" | "upcoming",
    query?: any
  ) {
    try {
      const res = await this.fetchHandler({
        path: `my-appointments/me${filter ? `?filter=${filter}` : ""}${
          query?.limit ? `${filter ? "&" : "?"}$limit=${query?.limit}` : ""
        }${query?.skip ? `&$skip=${query?.skip}` : ""}`,
        method: "GET",
      });
      const resp = await this.handleResponse(res);
      return {
        data: resp.data.data,
        total: resp.data.total,
        limit: resp.data.limit,
        skip: resp.data.skip,
      };
    } catch (error: any) {
      console.log(error?.message);
    }
  }

  /**
   * Function called by patients to contact doctors and make enquiries
   * @param payload
   * @param notifications
   * @returns
   */
  public async makeEnquiry(payload: EnquiryDto, notifications?: Notifications) {
    const res = await this.mergePostResponse(
      payload,
      "patient-enquiry",
      notifications,
      "Enquiry submitted"
    );

    return res;
  }

  /**
   * Function called by doctors to add appointment on behalf of a patient
   * @param payload
   * @param notifications
   * @returns
   */
  public async addAppointment(
    payload: AppointmentDto,
    notifications?: Notifications,
    setData?: any
  ) {
    const res = await this.mergePostResponse(
      payload,
      "appointments",
      notifications,
      "Appointment added successfully"
    );
    this.refetchDashboardData(setData);
    return res;
  }

  /**
   * Function called to delete an appointment
   * @param id
   * @param notification
   * @returns
   */
  public async deleteAppointment(id: string, notification?: Notifications) {
    try {
      const res = await this.fetchHandler({
        path: `appointments/${id}`,
        method: "DELETE",
      });
      const resp = await this.handleResponse(res);
      if (resp) {
        notification?.success?.("Appointment deleted successfully");
        notification?.setLoading?.(false);
        return resp;
      }
    } catch (error: any) {
      notification?.setLoading?.(false);
      notification?.error?.(error?.message);
    }
  }

  /**
   * Function called to delete a patient
   * @param id
   * @param notification
   * @returns
   */
  public async deletePatients(id: string, notification?: Notifications) {
    try {
      const res = await this.fetchHandler({
        path: `patients/${id}`,
        method: "DELETE",
      });
      const resp = await this.handleResponse(res);
      if (resp) {
        notification?.success?.("Patient deleted successfully");
        notification?.setLoading?.(false);
        return resp;
      }
    } catch (error: any) {
      notification?.setLoading?.(false);
      notification?.error?.(error?.message);
    }
  }

  /**
   * Function called to get doctor patient name and id
   * @returns
   */
  public async getDoctorPatientNameAndId(setData?: (update?: any) => void) {
    const res = await this.getPatients();
    let data = [...res?.data];
    data.map(({ fullName, picture }) => {
      return {
        name: fullName,
        value: fullName,
        label: fullName,
        img: picture,
      };
    });
    setData?.(data);
    return data;
  }

  /**
   * Function called to search doctors patient by name
   * @param name
   * @returns
   */
  public async getDoctorPatientByFullName(name?: string) {
    try {
      const res = await this.fetchHandler({
        path: `my-patients/me${name ? `?fullName=${name}` : ""}`,
        method: "GET",
      });
      let resp = await this.handleResponse(res);
      resp = resp?.data?.data;
      return resp?.map((data: any) => {
        return {
          name: data?.fullName,
          label: data?.fullName,
          value: data,
          img: data?.picture,
        };
      });
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  public async handlePagination(
    query?: any,
    type?: "appointment" | "patients" | "transaction"
  ) {
    if (type === "appointment") {
      const res = await this.getDoctorsAppointment(query?.query, query);
      return res;
    }
    if (type === "patients") {
      const res = await this.getPatients(query);
      return res;
    }

    if (type === "transaction") {
      const res = await transactionService.getAllTransaction(query);
      return res;
    }
  }

  public async getDashboardData(filter?: number): Promise<DashboardDataDto> {
    const res = await this.fetchHandler({
      path: `doctor/dashbord-data/me${filter ? `?filter=${filter}` : ""}`,
      method: "GET",
    });
    const resp = await this.handleResponse(res);
    return resp.data;
  }

  public async refetchDashboardData(setData: (data: any) => void) {
    const res = await this.getDashboardData();
    setData(res);
  }

  public async recordProfileVisit(
    data: {
      visitorEmail: string;
      doctorId: string;
    },
    notification: Notifications
  ) {
    const res = await this.mergePostResponse(
      data,
      "doctor/profile-viewed",
      notification
    );
    return res.data;
  }

  public async uploadNewAvatar(image: any) {
    return await this.imageUploader(image);
  }

  /**
   * Gets all doctors notification
   * @returns
   */
  public async getDoctorNotification(): Promise<
    DoctorNotificationDto[] | undefined
  > {
    try {
      const res = await this.fetchHandler({
        path: "my-notification/me",
        method: "GET",
      });
      const resp = await this.handleResponse(res);
      this.storeData({
        notification: JSON.stringify({
          length: resp.data.data.length,
          isCheck: false,
        }),
      });
      return resp.data.data;
    } catch (error) {}
  }

  /**
   * Approves doctors appointment
   * @param id
   * @param setAppointment
   */
  public async approveDoctorAppointment(id: number, setAppointment?: any) {
    try {
      const res = await this.fetchHandler({
        path: "approve-appointment",
        method: "PUT",
        body: { appointmentId: id },
      });
      const resp = await this.handleResponse(res);
      if (resp) {
        this.refetchAppointment(setAppointment);
      }
    } catch (error) {}
  }
}
const appServices = new AppServices();
export default appServices;
