import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/customButton";
import Logo from "../../components/logo";
import { useGetScreen, useNotification } from "../../hooks/app";
import authServices from "../../services/auth.service";

const ForgotPassword: React.FC = () => {
  const { isMediumPortrait, isXLargeDesktop } = useGetScreen();
  const [email, setEmail] = React.useState("");
  const navigate = useNavigate();
  const { error, success, warning, loading, setLoading } = useNotification();

  const logoWidth = () => {
    if (isXLargeDesktop) return 10;
    if (isMediumPortrait) return 20;
    return 30;
  };
  return (
    <Container as={"section"} className="container">
      <Logo width={logoWidth()} center />
      <div className="w-30 mx-auto border rounded position-absolute top-50 start-50 translate-middle w-sm w-md-60">
        <div className="mt-4">
          <h4 className="text-center">Need help with your account?</h4>
          <p className="text-center fs-7 w-75 mx-auto">
            Enter the email address associated with your account and we will
            send you a link to reset your password.
          </p>
        </div>
        <form className="mt-4">
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control w-75 d-block mx-auto"
            type={"email"}
          />
          <CustomButton
            loadingText="Loading"
            isLoading={loading}
            type="button"
            onClick={async () => {
              const res = await authServices.initiateResetPassword(email, {
                success,
                warning,
                error,
                setLoading,
              });
              if (res) {
                navigate("/");
              }
            }}
            width={75}
            marginTop={2}
            className="mb-4"
            center
          >
            Send Link
          </CustomButton>
        </form>
      </div>
    </Container>
  );
};

export default ForgotPassword;
