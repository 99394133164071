import React from "react";
import { useMediaQuery } from "react-responsive";
import { toast, TypeOptions } from "react-toastify";
import appServices from "../services/app.service";

export function useGetScreen() {
  return {
    isMobilePortrait: useMediaQuery({
      query: `(min-width: 320px) and (max-width: 480px)`,
    }),
    isMobileLandscape: useMediaQuery({
      query: `(min-width: 481px) and (max-width: 767px)`,
    }),
    isMediumPortrait: useMediaQuery({
      query: `(min-width: 768px) and (max-width: 1024px)`,
    }),
    isMediumLandscape: useMediaQuery({
      query: `(min-width: 768px) and (max-width: 1024px) and (orientation: landscape)`,
    }),
    isLargeDesktop: useMediaQuery({
      query: `(min-width: 1025px) and (max-width: 1280px)`,
    }),
    isXLargeDesktop: useMediaQuery({ query: `(min-width: 1281px)` }),
  };
}

export function useNotification() {
  const toastId: any = React.useRef(null);
  const [loading, setLoading] = React.useState(false);

  const config: any = {
    position: "top-center",
    autoClose: 4000,
    theme: "colored",
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  };
  const info = (message: string, timing?: number) => {
    toastId.current = toast.info(message, {
      ...config,
      autoClose: timing || 5000,
    });
  };

  const error = (message: string, timing?: number) => {
    toastId.current = toast.error(message, {
      ...config,
      autoClose: timing || 5000,
    });
  };

  const warning = (message: string, timing?: string) => {
    toastId.current = toast.warn(message, {
      ...config,
      autoClose: timing || 5000,
    });
  };

  const success = (message: string, timing?: number) => {
    toastId.current = toast.success(message, {
      ...config,
      autoClose: timing || 5000,
    });
  };

  const update = (type: TypeOptions, message: string) =>
    toast.update(toastId.current, {
      type,
      autoClose: 5000,
      render: message,
    });
  return { info, warning, error, success, update, loading, setLoading };
}

export const search = {
  patientByName: function useHook(name?: string) {
    const [state, setState] = React.useState<any[]>([]);
    const [search, setSearch] = React.useState(name);
    React.useEffect(() => {
      appServices.getDoctorPatientByFullName(search).then((result) => {
        setState(result);
      });
    }, [name, search]);

    return { search: state, setSearch };
  },
};
