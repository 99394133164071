import React, { Fragment } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { TabProps } from "../@types/props";
import { useGetScreen } from "../hooks/app";

const Tab: React.FC<TabProps> = ({
  tabs,
  setData,
  className,
  activeClass = "bg-theme",
  deActiveClass = "bg-white text-black",
}) => {
  const [tab, setTab] = React.useState(tabs);
  const { isMobilePortrait } = useGetScreen();
  return (
    <ButtonGroup aria-label="tab" className={className}>
      {tab.map((data, ind) => {
        return (
          <Fragment key={ind}>
            <Button
              variant="secondary"
              className={`${data.active ? activeClass : deActiveClass} ${
                data.className
              } ${isMobilePortrait ? "fs-7" : "fs-6"}`}
              onClick={() => {
                const dataTab = tab.filter((tab) => {
                  tab.active = false;
                  return tab;
                });
                dataTab[ind].active = true;

                setTab(dataTab);
                const name = dataTab.filter((data) => {
                  if (data.active) {
                    return data;
                  }
                  return null;
                });
                setData?.(dataTab, name[0].name);
                data.onClick?.();
              }}
            >
              {data?.name}
            </Button>
          </Fragment>
        );
      })}
    </ButtonGroup>
  );
};

export default Tab;
