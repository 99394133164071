import React from "react";
import { ScrollViewProps } from "../@types/props";
import { useGetScreen } from "../hooks/app";

const ScrollView: React.FC<ScrollViewProps> = ({
  children,
  maxHeight,
  className,
  isLargeDesktop,
  isMediumLandscape,
  isMediumPortrait,
  isMobileLandscape,
  isMobilePortrait,
  isXLargeDesktop,
  scrollbar = false,
  overflowX = "hidden",
  overflowY = "scroll",
}) => {
  const {
    isMobilePortrait: isMOP,
    isLargeDesktop: isLD,
    isMediumLandscape: isML,
    isMobileLandscape: isMOL,
    isMediumPortrait: isMP,
    isXLargeDesktop: isXLD,
  } = useGetScreen();
  const height = () => {
    if (isMOP) {
      return isMobilePortrait;
    }
    if (isMP) {
      return isMediumPortrait;
    }
    if (isLD || isXLD) {
      return isLargeDesktop || isXLargeDesktop;
    }
    if (isML) {
      return isMediumLandscape;
    }
    if (isMOL) {
      return isMobileLandscape;
    }
  };

  return (
    <div
      style={{
        overflowX,
        overflowY,
        height: height(),
        maxHeight,
        margin: 0,
        padding: 0,
       marginRight: 3,
       
      }}
      className={`${className ? className : ""} ${
        !scrollbar ? "scrollbar" : ""
      }`}
    >
      {children}
    </div>
  );
};

export default ScrollView;
