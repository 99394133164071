export const routes = {
  login: "/auth/login",
  register: "/auth/register",
  otp: "/auth/otp",
  forgotPassword: "/auth/forgot-password",
  resetPassword: "/auth/reset-password",
  success: "/auth/success",
  onboardDetails: "/onboarding/personal-details",
  onboardService: "/onboarding/add-services",
  onboardOthers: "/onboarding/other-services",
  dashboard: "/app/dashboard",
  appointment: "/app/appointment",
  patient: "/app/patients",
  services: "/app/services",
  calendar: "/app/calendar",
  wallet: "/app/wallet",
  settings: "/app/settings",
  transactions: "/app/transactions",
  userServices: "/doctor/:id",
  paystackPayment: "/payment/confirm",
  admin: "/admin",
  adminPatient: "/admin/patient",
  adminDashboard: "/admin/administrative",
  adminDoctors: "/admin/doctors",
  adminCalendar: "/admin/calendar",
  adminMessage: "/admin/message",
  adminWallet: "/admin/wallet",
  adminSettings: "admin/settings",
};

export const onboardingRoutes = [
  routes.onboardDetails,
  routes.onboardOthers,
  routes.onboardService,
];

export const appRoutes = [
  routes.appointment,
  routes.calendar,
  routes.dashboard,
  routes.patient,
  routes.wallet,
  routes.settings,
  routes.services,
  routes.transactions,
];
