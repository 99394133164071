import React from "react";
import { Row, Col } from "react-bootstrap";
import { assets } from "../../assets/assets";
import CustomButton from "../../components/customButton";
import Loader from "../../components/loader";
import ScrollView from "../../components/scrollview";
import ServiceCard from "../../components/serviceCard";
import { useGetScreen, useNotification } from "../../hooks/app";
import useAtomState from "../../hooks/state";
import appServices from "../../services/app.service";

const Services: React.FC = () => {
  const { services, setService, setEditServiceState, setShowService } =
    useAtomState();
  const [pageLoading, setPageLoading] = React.useState(false);
  const { error, success, warning } = useNotification();
  const { isMobilePortrait } = useGetScreen();

  React.useEffect(() => {
    setPageLoading(true);
    Promise.all([appServices.getDoctorService()]).then(([service]) => {
      setService(service);
      setPageLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-95 mx-auto">
      <Row className="justify-content-end">
        <Col lg={4} md={4} sm={2}>
          <CustomButton
            marginTop={1}
            onClick={() => {
              setEditServiceState((state: any) => {
                return {
                  type: "add",
                  discountApply: "",
                  doctorAvailability: "",
                  originalPrice: "",
                  serviceName: "",
                  servicePrice: "",
                  workingHours: "",
                };
              });
              setShowService(true);
            }}
          >
            <span>
              <img src={assets.plus_1} width={15} alt="plus" />
            </span>
            <span className="ps-2">Add service</span>
          </CustomButton>
        </Col>
      </Row>
      {pageLoading ? (
        <Loader type="skeleton-service" fill={6} />
      ) : (
        <ScrollView
          isLargeDesktop="90vh"
          isMediumPortrait="90vh"
          className="mt-2"
        >
          {services.length === 0 ? (
            <h4 className="text-center pt-5">You have no service</h4>
          ) : (
            <div
              className={
                isMobilePortrait
                  ? "d-flex flex-column w-100"
                  : "d-flex flex-row justify-content-between w-100"
              }
            >
              {services.map(
                (
                  {
                    discountApply,
                    doctorAvailability,
                    servicePrice,
                    serviceName,
                    id,
                    workingHours,
                    currencySign,
                    originalPrice,
                  },
                  ind: number
                ) => {
                  return (
                    <ServiceCard
                      data={{
                        doctorAvailability,
                        discountPrice: discountApply,
                        serviceName,
                        servicePrice,
                        currencySign,
                      }}
                      type="doctor"
                      key={ind}
                      button1={() => {
                        setEditServiceState({
                          discountApply,
                          doctorAvailability,
                          id,
                          servicePrice,
                          serviceName,
                          workingHours,
                          type: "edit",
                          originalPrice,
                        });
                        setShowService(true);
                      }}
                      button2={async () => {
                        const res = await appServices.deleteDoctorService(
                          id as string,
                          { error, warning, success }
                        );
                        if (res) await appServices.refetchService(setService);
                      }}
                    />
                  );
                }
              )}
            </div>
          )}
        </ScrollView>
      )}
    </div>
  );
};

export default Services;
