import React from "react";
import { Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SidebarDetailsProps } from "../@types/props";
import { assets } from "../assets/assets";
import { useGetScreen, useNotification } from "../hooks/app";
import useAtomState from "../hooks/state";
import authServices from "../services/auth.service";
import { routes } from "../utils/router";
import CustomButton from "./customButton";
import Input from "./input";
import ScrollView from "./scrollview";
import { TbCopy } from "react-icons/tb";
import transactionService from "../services/transaction.service";
import appServices from "../services/app.service";

const SidebarDetails: React.FC<SidebarDetailsProps> = () => {
  const { isMobilePortrait } = useGetScreen();
  const { setWalletAccount, walletAccount, dashboardData } = useAtomState();
  const { error, success, loading, setLoading } = useNotification();

  React.useEffect(() => {
    transactionService.getWalletAccount().then((account) => {
      setWalletAccount(account);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <ScrollView
      isLargeDesktop="100vh"
      isXLargeDesktop="100vh"
      isMobilePortrait="70vh"
    >
      <Stack direction="vertical" className="bg-white">
        <h4 className="text-center fw-b pt-3">My Balance</h4>
        <div
          className="p-3 rounded-3 mt-2 w-90 mx-auto"
          style={{ backgroundColor: "#fef8fa" }}
        >
          <Stack
            direction="horizontal"
            className="p-3 rounded-3 mx-auto"
            style={{ backgroundColor: "#fdf1f5", height: 120, width: "90%" }}
          >
            <div>
              <img
                src={assets.ava_bal}
                alt="available balance"
                width={40}
                className="mt-3"
              />
            </div>
            <div className="mt-1">
              <p className="wal-bal-text">Available Balance</p>
              <h3 className="wal-bal-amount">
                {walletAccount.availableBalance ?? 0}
              </h3>
            </div>
          </Stack>
          <Stack
            direction="horizontal"
            className="p-3 rounded-3 mt-3 mx-auto"
            style={{ height: 120, width: "90%" }}
          >
            <div>
              <img
                src={assets.ava_bal}
                alt="available balance"
                width={40}
                className="mt-3"
              />
            </div>
            <div className="mt-1">
              <p className="wal-bal-text">Pending </p>
              <h3 className="wal-bal-amount">{walletAccount.pendingBalance}</h3>
            </div>
          </Stack>
          <Link to={routes.transactions} className="text-center fs-7 d-block">
            View Transaction
          </Link>
        </div>
        <CustomButton
          width={50}
          style={{ height: 40, marginTop: 20, marginBottom: 20 }}
          center
          isLoading={loading}
          disabled={loading}
          loadingText="Loading"
          onClick={() =>
            transactionService.makeDoctorTransfer(
              {
                customerBankId: parseInt(
                  appServices?.getStoredData()?.selected_bank_id as string
                ),
              },
              { error, success, setLoading }
            )
          }
        >
          Transfer
        </CustomButton>
      </Stack>
      {isMobilePortrait ? (
        <div
          className="text-center d-block mt-2"
          onClick={() => {
            window.navigator.clipboard.writeText(
              `${window.location.host}/doctor/${
                authServices.getStoredData().user?.uniqueRouteNumber
              }`
            );
            success("Link copied!");
          }}
        >
          <p style={{ fontSize: 14 }}>
            <TbCopy size={20} />
            <span className="ps-2" style={{ cursor: "pointer" }}>
              Copy Link
            </span>
          </p>
        </div>
      ) : (
        <Stack direction="vertical" className="bg-white mt-5">
          <div style={{ width: 200 }} className="mt-3 ms-3">
            <Input
              type={"select"}
              selectType="colored-pink"
              selectStyle={{ borderRadius: 50 }}
              className="dashboard-select-input rounded-5"
              options={[
                { label: "Last 7 days", name: "Last 7 days", value: 7 },
                { label: "Last 14 days", name: "Last 14 days", value: 14 },
                { label: "Last 1 month", name: "Last 1 month", value: 30 },
              ]}
            />
          </div>
          <div className="mt-4 ms-4">
            <p style={{ lineHeight: 0.5 }}>Appointments</p>
            <h4>{dashboardData.filteredAppointments}</h4>
          </div>
          <div className="mt-3 ms-4">
            <p style={{ lineHeight: 0.5 }}>New Patients</p>
            <div className="d-flex flex-row">
              {dashboardData.newPatients?.map((data, ind) => {
                return (
                  <img
                    src={data.picture}
                    key={ind}
                    className="w-5 rounded-4"
                    alt="new-patient"
                    height={20}
                  />
                );
              })}
            </div>
          </div>
          <div className="mt-3 ms-4">
            <p style={{ lineHeight: 0.5 }}>Profit</p>
            <h4>{dashboardData.profit}</h4>
          </div>
        </Stack>
      )}
    </ScrollView>
  );
};

export default SidebarDetails;
