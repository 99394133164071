import { useGetScreen } from "../../../hooks/app";

const NotificationSettings: React.FC = () => {
  const { isMobilePortrait } = useGetScreen();
  return (
    <div
      className={`bg-white p-3 rounded-4 mt-3 ${
        isMobilePortrait ? "w-100" : "w-80"
      }`}
    >
      <form className="pt-2">
        <h5 className="fs-6">Sign-in Notifications</h5>
        <p className="fs-7" style={{ color: "#84818A" }}>
          When you’re busy or not online, Substance can send you email
          notifications for any new direct messages or mentions of your name.
        </p>
        <h5 className="fs-7">Send me email notifications:</h5>
        <div className="pt-1">
          <input type="radio" id="receive-email" name="sign-in" />
          <label className="ps-1 fs-7" htmlFor="receive-email">
            Send me email notifications:
          </label>
        </div>
        <div className="pt-2">
          <input type="radio" id="never" name="sign-in" />
          <label className="ps-1 fs-7" htmlFor="never">
            Never
          </label>
        </div>
      </form>
      <form className="pt-4">
        <h5 className="fs-6">Email Notifications</h5>
        <p className="fs-7" style={{ color: "#84818A" }}>
          These emails help keep your Yanga account secure. If you haven’t
          already, you should also enable two-factor authentication.
        </p>
        <div className="pt-2">
          <input type="radio" id="secure" name="email" />
          <label className="ps-1 fs-7" htmlFor="secure">
            Most secure
          </label>
          <span
            className="fs-7 d-inline-block ps-3"
            style={{ color: "#84818A" }}
          >
            Receive an email anytime someone signs in to your Yanga account from
            an unrecognized device.
          </span>
        </div>
        <div className="pt-2">
          <input type="radio" id="standard" name="email" />
          <label className="ps-1 fs-7" htmlFor="standard">
            Standard
          </label>
          <span
            className="fs-7 d-inline-block ps-3"
            style={{ color: "#84818A" }}
          >
            Receive an email when someone signs in from a new location, with an
            unrecognized device.
          </span>
        </div>
        <div className="pt-2">
          <input type="radio" id="don't-send" name="email" />
          <label className="ps-1 fs-7" htmlFor="don't-send">
            Don’t send me any sign-in notifications
          </label>
        </div>
      </form>
    </div>
  );
};

export default NotificationSettings;
