import moment from "moment";
import React from "react";
import { Dropdown, Stack } from "react-bootstrap";
import { CgCloseR } from "react-icons/cg";
import { MdOutlineNotifications } from "react-icons/md";
import { NotificationCardProps, NotificationProps } from "../@types/props";
import { DoctorNotificationState } from "../@types/state";
import { useGetScreen } from "../hooks/app";
import useAtomState from "../hooks/state";
import appServices from "../services/app.service";
import ScrollView from "./scrollview";

const Notification: React.FC<NotificationProps> = ({ className }) => {
  const { isMobilePortrait } = useGetScreen();
  const [notification, setNotification] = React.useState(
    appServices.getStoredData().notification
  );

  const {
    doctorNotification,
    setShowNotificationSidebar,
    setDoctorNotification,
  } = useAtomState();

  React.useEffect(() => {
    appServices.getDoctorNotification().then((data) => {
      if (data) {
        setDoctorNotification(data);
      }
    });
    // eslint-disable-next-line
  }, []);

  const Component = ({ children }: any) => {
    if (isMobilePortrait) {
      return (
        <Dropdown className={className}>
          {!notification?.isCheck && (
            <div
              onClick={() => {
                setNotification({
                  ...notification,
                  isCheck: true,
                });
                appServices.storeData({
                  notification: JSON.stringify(notification),
                });
              }}
              className="text-black bg-danger position-absolute end-10"
              style={{ width: 10, height: 10, borderRadius: "50%" }}
            ></div>
          )}
          <Dropdown.Toggle
            onClick={() => {
              setShowNotificationSidebar(true);
            }}
            className="rounded-circle bg-white ps-2 pt-1 border-light"
            style={{ height: 45, width: 45 }}
          >
            <MdOutlineNotifications
              onClick={() => {
                setNotification({
                  ...notification,
                  isCheck: true,
                });
                appServices.storeData({
                  notification: JSON.stringify(notification),
                });
              }}
              size={25}
              color={"black"}
            />
          </Dropdown.Toggle>
        </Dropdown>
      );
    }

    return (
      <React.Fragment>
        <Dropdown className={className}>
          {!notification?.isCheck && (
            <div
              className="text-black bg-danger position-absolute end-10"
              style={{ width: 10, height: 10, borderRadius: "50%" }}
            ></div>
          )}
          <Dropdown.Toggle
            className="rounded-circle bg-white ps-2 pt-1 border-light"
            style={{ height: 45, width: 45 }}
          >
            <MdOutlineNotifications
              onClick={() => {
                setNotification({
                  ...notification,
                  isCheck: true,
                });
                appServices.storeData({
                  notification: JSON.stringify(notification),
                });
              }}
              size={25}
              color={"black"}
            />
          </Dropdown.Toggle>

          <Dropdown.Menu className="border-0 shadow-sm hover-white">
            {children}
          </Dropdown.Menu>
        </Dropdown>
      </React.Fragment>
    );
  };

  return (
    <Component>
      <Dropdown.Item>
        <div className="d-flex flex-row justify-content-between">
          <div>
            <h6>Notifications</h6>
          </div>
          <div>
            <CgCloseR className="align-self-end" />
          </div>
        </div>
      </Dropdown.Item>
      <Dropdown.Divider></Dropdown.Divider>
      <Dropdown.Item href="" as={"div"}>
        <ScrollView
          isLargeDesktop="50vh"
          isMediumPortrait="50vh"
          className="w-100"
        >
          {doctorNotification.length > 0
            ? doctorNotification.map(
                (data: DoctorNotificationState, ind: number) => {
                  return (
                    <NotificationCard
                      key={ind}
                      id={`${ind}`}
                      action={data.action}
                      patientData={JSON.parse(data.patientData as any)}
                      subject={data.subject}
                      notificationMessage={data.notificationMessage}
                      createdAt={data.createdAt}
                      doctorData={JSON.parse(data.doctorData as any)}
                      updatedAt={data.updatedAt}
                      userId={data.userId}
                      className="mt-3"
                    />
                  );
                }
              )
            : "You have no notification"}
        </ScrollView>
      </Dropdown.Item>
    </Component>
  );
};

export const NotificationCard: React.FC<NotificationCardProps> = ({
  className,
  subject,
  patientData,
  notificationMessage,
  updatedAt,
  id,
}) => {
  const { isMobilePortrait } = useGetScreen();

  React.useEffect(() => {
    const element = document.getElementById(`${id}`) as HTMLElement;
    if (element) {
      const newText = `
    ${notificationMessage?.substring(0, 43)}${
        !isMobilePortrait ? "<br />" : ""
      }${notificationMessage
        ?.substring(43, notificationMessage.length)
        .replace("{", "<span style='font-weight: bold'>")
        .replace("}", "</span>")
        .replace("[", "<span style='color: green; font-weight: bold'>")
        .replace("]", "</span>")}
    `;
      element.innerHTML = newText;
    }
  }, [notificationMessage, isMobilePortrait, id]);

  return (
    <Stack direction="horizontal" className={className}>
      <div>
        <img
          src={patientData?.picture}
          width={40}
          height={40}
          alt="avatar"
          style={{ borderRadius: 30 }}
        />
      </div>
      <div className="ps-4">
        <h5>{subject}</h5>
        <div className="fs-7 word-break" id={id}></div>
        <h6 className="fs-7 pt-3">{moment(updatedAt).fromNow()}</h6>
      </div>
    </Stack>
  );
};
export default Notification;
