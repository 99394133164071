import React from "react";
import { Table } from "react-bootstrap";
import { CustomTableProps } from "../@types/props";
import usePagination from "../hooks/pagination";
import Loader from "./loader";
import ScrollView from "./scrollview";

const CustomTable: React.FC<CustomTableProps> = ({
  children,
  type,
  screen,
  className,
  showPagination = true,
  showResults = true,
  setData,
  query,
}) => {
  const [loading, setLoading] = React.useState(false);
  const { Pagination } = usePagination({
    type,
    showResults,
    setData,
    loading,
    setLoading,
    query,
  });

  return (
    <React.Fragment>
      <ScrollView
        isLargeDesktop={screen?.isLargeDesktop}
        isXLargeDesktop={screen?.isXLargeDesktop}
        isMediumLandscape={screen?.isMediumLandscape}
        isMediumPortrait={screen?.isMediumPortrait}
        isMobileLandscape={screen?.isMobileLandscape}
        isMobilePortrait={screen?.isMobilePortrait}
      >
        {loading ? (
          <Loader type="skeleton-table" fill={8} />
        ) : (
          <Table
            className={`${className ? className : ""} v-table-spacing`}
            responsive
          >
            {children}
          </Table>
        )}
      </ScrollView>

      {showPagination && <Pagination />}
    </React.Fragment>
  );
};

export default CustomTable;
