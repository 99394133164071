import React from "react";
import ScrollView from "../../../components/scrollview";
import Tab from "../../../components/tab";
import { useGetScreen } from "../../../hooks/app";
import authServices from "../../../services/auth.service";
import AccountSettings from "./account";
import NotificationSettings from "./notification";
import ProfileSettings from "./profile";

const Settings: React.FC = () => {
  const [userDetails] = React.useState({
    ...authServices.getStoredData().user,
  });
  const { isMobilePortrait } = useGetScreen();
  const [tabs, setTab] = React.useState([
    {
      active: true,
      name: "Profile",
      className: "",
    },
    {
      active: false,
      name: "Account settings",
      className: "ms-2 border p-1",
    },
    {
      active: false,
      name: "Notification",
      className: "ms-2 border p-1",
    },
  ]);
  const [cTab, setCTab] = React.useState("Profile");

  return (
    <div className="w-95 mx-auto">
      <Tab
        className={`bg-white rounded-2 ${
          isMobilePortrait ? "w-100" : "w-70 p-3"
        }`}
        tabs={tabs}
        setData={(data: any, name?: string) => {
          setTab(data);
          setCTab(name as string);
        }}
        activeClass="bg-theme"
        deActiveClass="bg-white text-black border settings-btn"
      />
      <ScrollView isLargeDesktop="75vh">
        {cTab === "Profile" && <ProfileSettings userDetails={userDetails} />}
        {cTab === "Account settings" && (
          <AccountSettings userDetails={userDetails} />
        )}
        {cTab === "Notification" && <NotificationSettings />}
      </ScrollView>
    </div>
  );
};

export default Settings;
