import moment from "moment";
import React from "react";
import { Col, Container, Row, Stack, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { assets } from "../../assets/assets";
import CustomButton from "../../components/customButton";
import Input from "../../components/input";
import ScrollView from "../../components/scrollview";
import { useGetScreen, useNotification } from "../../hooks/app";
import useAtomState from "../../hooks/state";
import { useWalletStyles } from "../../hooks/styles";
import appServices from "../../services/app.service";
import transactionService from "../../services/transaction.service";
import { routes } from "../../utils/router";

const Wallet: React.FC = () => {
  const {
    setShowAllBank,
    setWalletAccount,
    dashboardData,
    setPaymentDetails,
    setShowPaymentDetails,
  } = useAtomState();
  const { balance, bank_card } = useWalletStyles();
  const { isMobilePortrait } = useGetScreen();
  const {
    setDoctorsBankList,
    doctorsBankList,
    walletAccount,
    setTransactions,
    transactions,
  } = useAtomState();
  const [selectedBankId, setSelectedBankId] = React.useState(
    appServices?.getStoredData()?.selected_bank_id
  );
  const { error, success, loading, setLoading } = useNotification();

  React.useEffect(() => {
    transactionService.getDoctorsBankDetails().then((banks) => {
      if (banks) setDoctorsBankList(banks);
    });
    transactionService.getAllTransaction().then((data) => {
      if (data) setTransactions(data.data);
    });
    transactionService.getWalletAccount().then((account) => {
      setWalletAccount(account);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-95 mx-auto">
      <Row className="g-2">
        <Col lg={8}>
          <div className="bg-white p-4">
            <h4 className="fw-b pt-3 ps-3">My Balance</h4>
            <Stack
              direction={balance.stack_1.direction as any}
              className={balance.stack_1.class}
              style={{ backgroundColor: "#fef8fa" }}
            >
              <Stack
                direction={balance.stack_2.direction as any}
                className={balance.stack_2.class}
                style={{ ...balance.stack_2.style }}
              >
                <div>
                  <img
                    src={assets.ava_bal}
                    alt="available balance"
                    width={40}
                    className="mt-3"
                  />
                </div>
                <div className="mt-1">
                  <p className="wal-bal-text">Available Balance</p>
                  <h3 className="wal-bal-amount">
                    {walletAccount.availableBalance}
                  </h3>
                </div>
              </Stack>
              <Stack
                direction="horizontal"
                className="p-3 rounded-3  mx-auto"
                style={{ height: 120, width: "80%" }}
              >
                <div>
                  <img
                    src={assets.ava_bal}
                    alt="available balance"
                    width={40}
                    className="mt-3"
                  />
                </div>
                <div className="mt-1">
                  <p className="wal-bal-text">Pending</p>
                  <h3 className="wal-bal-amount">
                    {walletAccount.pendingBalance}
                  </h3>
                </div>
              </Stack>
            </Stack>
          </div>
          <div className="p-3 mt-3 bg-white rounded-3 account-sum">
            <Stack direction="horizontal">
              <div style={{ width: 200 }} className="mt-3 ms-3">
                <Input
                  type={"select"}
                  selectType="colored-pink"
                  selectStyle={{ borderRadius: 50 }}
                  className="dashboard-select-input rounded-5"
                  options={[
                    { label: "Last 7 days", name: "Last 7 days", value: 7 },
                    { label: "Last 14 days", name: "Last 14 days", value: 14 },
                    { label: "Last 1 month", name: "Last 1 month", value: 30 },
                  ]}
                />
              </div>
              {!isMobilePortrait && (
                <div className="mt-3 ms-4 w-30 ms-2">
                  <p style={{ lineHeight: 0.5 }}>Appointments</p>
                  <h4>{dashboardData.appointmentCounts}</h4>
                </div>
              )}

              <div className="mt-3 ms-4 w-30 ms-2">
                <p style={{ lineHeight: 0.5 }}>Profit</p>
                <h4>{dashboardData.profit}</h4>
              </div>
            </Stack>
          </div>
        </Col>
        <Col>
          <div className="p-2 bg-white rounded">
            <div className="d-flex flex-row justify-content-between">
              <div>
                <h4>Withdraw</h4>
              </div>
              <div>
                <img
                  src={assets.add_icon}
                  alt="add-button"
                  width={30}
                  height={30}
                  className="cursor-p align-self-end"
                  onClick={() => setShowAllBank(true)}
                />
              </div>
            </div>
            <ScrollView isLargeDesktop={"30vh"}>
              {doctorsBankList.length === 0 ? (
                <p className="mt-5 text-center">No bank data available</p>
              ) : (
                doctorsBankList.map((data) => {
                  if (selectedBankId === `${data.id}`) {
                    return (
                      <Container
                        style={{ cursor: "pointer" }}
                        className="position-relative"
                        key={data.id}
                      >
                        <img
                          src={assets.selected_bank}
                          className={bank_card.selected_icon}
                          alt="selected-bank"
                        />
                        <Row
                          className="rounded-3 mt-2 p-2 w-100 mx-auto"
                          style={{ backgroundColor: "#fef8fa" }}
                          onClick={() => {
                            transactionService.setCurrentBank(
                              data,
                              setSelectedBankId
                            );
                          }}
                        >
                          <Col lg={4} md={4} sm={3}>
                            <img
                              src={assets.bank_icon}
                              alt="bank icon"
                              className="mt-2"
                              width={60}
                            />
                          </Col>
                          <Col className="mt-3">
                            <p className="lh-0 fs-7 ps-2">
                              ****{data.accountNumber.substring(6, 10)}
                            </p>
                            <p className="lh-1 fs-7 ps-2">{data.BankName}</p>
                          </Col>
                        </Row>
                      </Container>
                    );
                  }
                  return (
                    <Container
                      style={{ cursor: "pointer" }}
                      key={data.id}
                      className="position-relative"
                    >
                      <Row
                        className="rounded-3 mt-2 p-2 w-100 mx-auto"
                        style={{ backgroundColor: "#fef8fa" }}
                        onClick={() => {
                          transactionService.setCurrentBank(
                            data,
                            setSelectedBankId
                          );
                        }}
                      >
                        <Col lg={4} md={4} sm={3}>
                          <img
                            src={assets.bank_icon}
                            alt="bank icon"
                            className="mt-2"
                            width={60}
                          />
                        </Col>
                        <Col className="mt-3">
                          <p className="lh-0 fs-7 ps-2">
                            ****{data.accountNumber.substring(6, 10)}
                          </p>
                          <p className="lh-1 fs-7 ps-2">{data.BankName}</p>
                        </Col>
                      </Row>
                    </Container>
                  );
                })
              )}
            </ScrollView>
            <CustomButton
              isLoading={loading}
              disabled={loading}
              loadingText="Loading"
              onClick={() =>
                transactionService.makeDoctorTransfer(
                  {
                    customerBankId: parseInt(
                      appServices?.getStoredData()?.selected_bank_id as string
                    ),
                  },
                  { error, success, setLoading }
                )
              }
            >
              Transfer
            </CustomButton>
            <p className="text-center fs-8 pt-3">
              This transaction go directly to the selected account
            </p>
          </div>
        </Col>
      </Row>
      <div className="bg-white mt-3 rounded-2">
        <p className="ps-3 pt-3 fs-5 fw-normal">Recent Transactions</p>
        <ScrollView
          className="table-responsive"
          isLargeDesktop={"27vh"}
          isMediumPortrait={"40vh"}
          maxHeight={"40vh"}
        >
          <Table responsive>
            <thead className="theader" style={{ backgroundColor: "#FAFAFB" }}>
              <tr>
                <th colSpan={2}>Category</th>
                <th>Date & time</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {transactions.map((data) => {
                const picture = data?.payment.appointment?.patient?.picture;
                const sender =
                  data?.payment.appointment?.patient?.fullName ||
                  "Patient deleted";
                const service =
                  data?.payment.appointment?.doctor_service?.serviceName;
                return (
                  <tr
                    key={data?.id}
                    className="cursor-p"
                    onClick={() => {
                      setPaymentDetails({
                        amount: data?.amount,
                        date: data?.createdAt,
                        status: data?.status,
                        picture,
                        sender,
                        service,
                        id: data?.id,
                      });
                      setShowPaymentDetails(true);
                    }}
                  >
                    <td colSpan={2}>
                      <img
                        src={assets.mock_avatar_7}
                        alt="avatar"
                        className="rounded-3 d-inline-block"
                        width={30}
                      />
                      <span className="ms-2 fs-7">{data?.category}</span>
                    </td>
                    <td>
                      <p
                        style={{
                          lineHeight: isMobilePortrait ? 1.5 : 0.5,
                          fontSize: 13,
                          paddingTop: 10,
                        }}
                      >
                        {moment(data?.createdAt).toDate().toUTCString()}
                      </p>
                    </td>
                    <td>
                      <p className="pt-2 fs-7">
                        {data?.type === "credit" ? "+" : "-"} {data?.amount}
                      </p>
                    </td>
                    <td>
                      {data?.status === "onHold" ? (
                        <p className="pink-text">{data?.status}</p>
                      ) : (
                        <p className="green-text">{data?.status}</p>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ScrollView>
        <Link to={routes.transactions}>
          <hr />
          <p className="text-center" style={{ color: "#0062FF" }}>
            View All transactions
          </p>
        </Link>
      </div>
    </div>
  );
};
export default Wallet;
