import moment from "moment";
import { Row, Col } from "react-bootstrap";
import { PaymentSidebarProps } from "../../@types/props";
import { assets } from "../../assets/assets";
import { useGetScreen, useNotification } from "../../hooks/app";
import useAtomState from "../../hooks/state";
import transactionService from "../../services/transaction.service";
import CustomButton from "../customButton";
import Uploader from "../uploader";
import Sidebar from "./sidebar";

const PaymentDetails: React.FC<PaymentSidebarProps> = ({ show }) => {
  const { setShowPaymentDetails, paymentDetails } = useAtomState();
  const { isMediumPortrait } = useGetScreen();
  const { loading, setLoading, error, success } = useNotification();
  return (
    <Sidebar show={show} placement="end" handleClose={setShowPaymentDetails}>
      <Row>
        <Col lg={2}>
          <img
            onClick={() => setShowPaymentDetails(false)}
            src={assets.left_icon}
            alt="left-icon"
            width={45}
            height={45}
            className="cursor-p"
          />
        </Col>
        <Col>
          <h3 className="pt-1">Payment Details</h3>
        </Col>
      </Row>
      <div className="mt-5">
        <Uploader
          disabled
          value={paymentDetails.picture}
          width={isMediumPortrait ? 25 : 40}
        />
        <h3 className="text-center pt-4">
          ₦{transactionService.numberWithCommas(paymentDetails.amount)}
        </h3>
        <p className="fs-7 text-center pt-1">Money Received</p>
      </div>
      <Row className="justify-content-between w-90 mx-auto mt-3">
        <Col>Sender</Col>
        <Col>
          <p className="text-end fs-7">{paymentDetails.sender}</p>
        </Col>
      </Row>
      <Row className="justify-content-between w-90 mx-auto mt-3">
        <Col>Date</Col>
        <Col>
          <p className="text-end fs-7">
            {moment(paymentDetails.date).toDate().toLocaleString()}
          </p>
        </Col>
      </Row>
      <Row className="justify-content-between w-90 mx-auto mt-3">
        <Col>Service</Col>
        <Col>
          <p className="text-end fs-7">{paymentDetails.service}</p>
        </Col>
      </Row>
      <Row className="justify-content-between w-90 mx-auto mt-3">
        <Col>Status</Col>
        <Col>
          {paymentDetails?.status === "onHold" ? (
            <p className="pink-text fs-7">{paymentDetails?.status}</p>
          ) : (
            <p className="green-text fs-7">{paymentDetails?.status}</p>
          )}
        </Col>
      </Row>

      <div className="w-80 mx-auto border p-3 rounded mt-2">
        <h4 className="fw-bold pt-4">Have you deliver this service?</h4>
        <Row className="justify-content-between w-90 mx-auto mt-4">
          <Col>
            <CustomButton
              isLoading={loading}
              onClick={() =>
                transactionService.requestFunds(paymentDetails?.id, {
                  setLoading,
                  error,
                  success,
                })
              }
            >
              Yes
            </CustomButton>
          </Col>
          <Col>
            <CustomButton
              bgColor="bg-white"
              borderColor="border-theme"
              textColor="text-theme"
              onClick={() => setShowPaymentDetails(false)}
            >
              No
            </CustomButton>
          </Col>
        </Row>
        <p className="fs-8 text-center pt-4">
          The admin will approve your payment in the next 10min and revert to
          your wallet.{" "}
        </p>
      </div>
    </Sidebar>
  );
};
export default PaymentDetails;
