import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { routes } from "../utils/router";
import { SCREEN_SIZE } from "../utils/utils";
import CustomButton from "./customButton";
import ResponsiveRender from "./responsiveRender";

const Footer: React.FC = () => {
  const navigate = useNavigate();
  return (
    <footer
      className="position-absolute bottom-0 w-100"
      style={{ backgroundColor: "#091B3D" }}
    >
      <Row className="w-100 justify-content-between">
        <ResponsiveRender>
          <Col id={SCREEN_SIZE.isXLargeDesktop}>
            <p className="text-white pt-3 ms-2 fs-7">
              © 2022 Yanga Dental Clinic . All Rights Reserved
            </p>
          </Col>
          <Col id={SCREEN_SIZE.isMediumPortrait}>
            <p className="text-white pt-3 ms-2 fs-7">
              © 2022 Yanga Dental Clinic . All Rights Reserved
            </p>
          </Col>
          <Col id={SCREEN_SIZE.isMobilePortrait}>
            <p className="text-white pt-3 ms-2 fs-7">
              © 2022 Yanga Dental Clinic . All Rights Reserved
            </p>
          </Col>
        </ResponsiveRender>
        <ResponsiveRender>
          <Col lg={3} md={3} id={SCREEN_SIZE.isXLargeDesktop}>
            <CustomButton
              width={90}
              className="mt-2"
              onClick={() => navigate(routes.login)}
            >
              Sign in
            </CustomButton>
          </Col>
          <Col lg={3} md={3} id={SCREEN_SIZE.isMediumPortrait}>
            <CustomButton
              width={90}
              className="mt-2"
              onClick={() => navigate(routes.login)}
            >
              Sign in
            </CustomButton>
          </Col>
          <Col lg={3} md={3} id={SCREEN_SIZE.isMobilePortrait}>
            <CustomButton
              width={30}
              className="mb-5 position-absolute bottom-30 start-35"
              center
              onClick={() => navigate(routes.login)}
            >
              Sign in
            </CustomButton>
          </Col>
        </ResponsiveRender>
      </Row>
    </footer>
  );
};

export default Footer;
