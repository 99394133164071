import React from "react";
import { Row, Col, Stack } from "react-bootstrap";
import { ProfileSettingsProps } from "../../../@types/props";
import { assets } from "../../../assets/assets";
import CustomButton from "../../../components/customButton";
import Input from "../../../components/input";
import Uploader from "../../../components/uploader";
import { useGetScreen, useNotification } from "../../../hooks/app";
import appServices from "../../../services/app.service";
import authServices from "../../../services/auth.service";

const ProfileSettings: React.FC<ProfileSettingsProps> = ({ userDetails }) => {
  const [currentState, setCurrentState] = React.useState<any[]>([]);
  const { error, setLoading, loading, success, info } = useNotification();
  const [newAvatar, setNewAvatar] = React.useState<any>();
  const { isMobilePortrait } = useGetScreen();
  const [userData, setUserData] = React.useState({
    fullName: userDetails?.fullName,
    email: userDetails?.email,
    phone: userDetails?.phone,
    gender: userDetails?.gender,
    state: userDetails?.state,
    clinicAddress: userDetails?.clinicAddress,
    twitter: userDetails?.twitter,
    instagram: userDetails?.instagram,
    facebook: userDetails?.facebook,
    doctorBio: userDetails?.doctorBio,
    businessName: userDetails?.businessName,
  });

  React.useEffect(() => {
    Promise.all([appServices.getStates()]).then(([states]) => {
      setCurrentState(states);
    });
  }, []);

  return (
    <React.Fragment>
      <Row className="mt-5">
        <Col lg={6} md={6}>
          <Stack className="bg-white p-3 rounded-4" direction="horizontal">
            <div className="ms-1 w-30">
              <p
                className={`${
                  isMobilePortrait ? "" : "lh-0"
                } fs-7 text-center `}
              >
                Your picture
              </p>
              <Uploader
                value={
                  appServices.getStoredData().user?.avatar ||
                  assets.mock_avatar_7
                }
                width={100}
                onClick={(data) => {
                  setNewAvatar(data);
                }}
              />
            </div>
            <div className="w-40 ms-2">
              <CustomButton
                className="p-2"
                btnClass="fs-7"
                loadingText="Loading"
                isLoading={loading}
                disabled={loading}
                radius={4}
                onClick={() => {
                  setLoading(true);
                  appServices.uploadNewAvatar(newAvatar).then((avatar) => {
                    authServices.updateUserProfile(
                      { avatar },
                      { success, error, info },
                      []
                    );
                    setLoading(false);
                  });
                }}
              >
                Upload New
              </CustomButton>
            </div>
            <div className="w-40 ms-2">
              <CustomButton
                className="p-2"
                bgColor="bg-theme-gray"
                textColor="text-black"
                btnClass="fs-7"
                radius={4}
                onClick={() => {
                  const avatar = document.getElementsByTagName("img");
                  avatar[5].src = assets.img_holder;
                  authServices.updateUserProfile(
                    { avatar: "" },
                    { success, error },
                    ["avatar"]
                  );
                }}
              >
                Remove Old
              </CustomButton>
            </div>
          </Stack>
        </Col>
        <Col lg={6} md={6}>
          <div className="bg-theme  p-2 rounded-4">
            <h6 className="text-white ps-1">Business name</h6>
            <Input
              value={userData?.businessName}
              containerClass="mt-3"
              style={{ backgroundColor: "#FF518F", color: "white" }}
              onChange={(businessName) => {
                setUserData({
                  ...userData,
                  businessName,
                });
              }}
            />
          </div>
        </Col>
      </Row>

      <form>
        <Row>
          <Col lg={6} md={6}>
            <div className="bg-white p-3 rounded-4 mt-3">
              <Input
                label="Full name"
                containerClass="mt-3"
                value={userData.fullName}
                onChange={(fullName) => {
                  setUserData({
                    ...userData,
                    fullName,
                  });
                }}
              />
              <Input
                label="Email"
                disabled
                containerClass="mt-3"
                value={userData.email}
                onChange={(email) => {
                  setUserData({
                    ...userData,
                    email,
                  });
                }}
              />
              <Input
                label="Phone"
                containerClass="mt-3"
                disabled
                value={userData.phone}
                onChange={(phone) => {
                  setUserData({
                    ...userData,
                    phone,
                  });
                }}
              />
              <Input
                label="Gender"
                placeholder="Select Gender"
                className="mt-2"
                value={userData.gender}
                onChange={(gender) => {
                  setUserData({
                    ...userData,
                    gender,
                  });
                }}
                type={"select"}
                options={[
                  { name: "Male", label: "Male", value: "Male" },
                  { name: "Female", label: "Female", value: "Female" },
                ]}
              />
            </div>
          </Col>
          <Col lg={6} md={6}>
            <div className="bg-white p-3 rounded-4 mt-3">
              <Input
                label="State"
                containerClass="mt-3"
                placeholder="Select state"
                type={"select"}
                options={[...currentState]}
                value={userData.state}
                onChange={(state) => {
                  setUserData({
                    ...userData,
                    state,
                  });
                }}
              />
              <Input
                label="Clinic address"
                containerClass="mt-3"
                disabled
                value={userData.clinicAddress}
                onChange={(clinicAddress) => {
                  setUserData({
                    ...userData,
                    clinicAddress,
                  });
                }}
              />
              <Input
                label="Twitter"
                containerClass="mt-3"
                value={userData.twitter}
                onChange={(twitter) => {
                  setUserData({
                    ...userData,
                    twitter,
                  });
                }}
              />
              <Input
                label="Instagram"
                containerClass="mt-3"
                value={userData.instagram}
                onChange={(instagram) => {
                  setUserData({
                    ...userData,
                    instagram,
                  });
                }}
              />
              <Input
                label="Facebook"
                containerClass="mt-3"
                value={userData.facebook}
                onChange={(facebook) => {
                  setUserData({
                    ...userData,
                    facebook,
                  });
                }}
              />
              <Input
                label="Your Bio"
                containerClass="mt-3"
                type={"textarea"}
                value={userData.doctorBio}
                onChange={(doctorBio) => {
                  setUserData({
                    ...userData,
                    doctorBio,
                  });
                }}
              />
              <Row className="row-reverse">
                <Col>
                  <CustomButton
                    type="button"
                    loadingText="Loading"
                    isLoading={loading}
                    disabled={loading}
                    onClick={() =>
                      authServices.updateUserProfile(userData, {
                        error,
                        setLoading,
                        success,
                      })
                    }
                    width={40}
                    className="mt-2"
                  >
                    Submit
                  </CustomButton>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};

export default ProfileSettings;
