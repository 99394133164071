import { useAtom } from "jotai";
import {
  appointmentSidebar,
  appointmentDataState,
  patientShowState,
  patientState,
  editServicesState,
  servicesState,
  enquiriesSidebar,
  enquiryState,
  userOnboardingSidebar,
  bookAppointmentState,
  doctorInfoState,
  serviceShowState,
  allBankSidebar,
  addBankSidebar,
  otherDetailsState,
  detailsOnboardingState,
  bookAppointmentShowState,
  userDetailsSidebar,
  addPatientState,
  pageLoadingState,
  addAppointmentDataState,
  isLoggedInState,
  loginState,
  registerState,
  leftSidebarState,
  rightSidebarState,
  anyDataState,
  notificationSidebarDetailsState,
  notificationSidebarState,
  doctorsBankListState,
  walletAccountSate,
  transactionState,
  dashboardDataState,
  doctorNotificationState,
  appointmentDetailsState,
  paymentDetailsState,
  paymentDetailsSidebar,
} from "../utils/state";

const useAtomState = (pathname?: string) => {
  const [data, setData] = useAtom(anyDataState);
  const [showAppointment, setShowAppointment] = useAtom(appointmentSidebar);
  const [appointment, setAppointment] = useAtom(appointmentDataState);
  const [addAppointment, setAddAppointment] = useAtom(addAppointmentDataState);
  const [showPatient, setShowPatient] = useAtom(patientShowState);
  const [patients, setPatients] = useAtom(patientState);
  const [services, setService] = useAtom(servicesState);
  const [editServiceState, setEditServiceState] = useAtom(editServicesState);
  const [showEnquiries, setShowEnquiries] = useAtom(enquiriesSidebar);
  const [showPaymentDetails, setShowPaymentDetails] = useAtom(
    paymentDetailsSidebar
  );
  const [paymentDetails, setPaymentDetails] = useAtom(paymentDetailsState);
  const [enquiries, setEnquiries] = useAtom(enquiryState);
  const [doctorInfo, setDoctorInfo] = useAtom(doctorInfoState);
  const [showService, setShowService] = useAtom(serviceShowState);
  const [bookAppointment, setBookAppointment] = useAtom(bookAppointmentState);
  const [showAllBank, setShowAllBank] = useAtom(allBankSidebar);
  const [showAddBank, setShowAddBank] = useAtom(addBankSidebar);
  const [otherDetails, setOtherDetails] = useAtom(otherDetailsState);
  const [detailsState, setDetailsState] = useAtom(detailsOnboardingState);
  const [showUserDetails, setShowUserDetails] = useAtom(userDetailsSidebar);
  const [addPatient, setAddPatient] = useAtom(addPatientState);
  const [isLoggedIn, setIsLoggedIn] = useAtom(isLoggedInState);
  const [pageLoading, setPageLoading] = useAtom(pageLoadingState);
  const [loginData, setLoginData] = useAtom(loginState);
  const [regData, setRegData] = useAtom(registerState);
  const [showLeftSidebar, setShowLeftSidebar] = useAtom(leftSidebarState);
  const [showRightSidebar, setShowRightSidebar] = useAtom(rightSidebarState);
  const [showBookAppointment, setShowBookAppointment] = useAtom(
    bookAppointmentShowState
  );
  const [showNotificationDetailsSidebar, setShowNotificationDetailsSidebar] =
    useAtom(notificationSidebarDetailsState);
  const [showNotificationSidebar, setShowNotificationSidebar] = useAtom(
    notificationSidebarState
  );
  const [showUserOnboarding, setShowUserOnboarding] = useAtom(
    userOnboardingSidebar
  );
  const [doctorsBankList, setDoctorsBankList] = useAtom(doctorsBankListState);
  const [walletAccount, setWalletAccount] = useAtom(walletAccountSate);
  const [transactions, setTransactions] = useAtom(transactionState);
  const [dashboardData, setDashboardData] = useAtom(dashboardDataState);
  const [doctorNotification, setDoctorNotification] = useAtom(
    doctorNotificationState
  );
  const [appointmentDetails, setAppointmentDetails] = useAtom(
    appointmentDetailsState
  );

  return {
    showAppointment,
    data,
    setData,
    loginData,
    showLeftSidebar,
    setShowLeftSidebar,
    showRightSidebar,
    setShowRightSidebar,
    setIsLoggedIn,
    setLoginData,
    setRegData,
    regData,
    setShowAppointment,
    isLoggedIn,
    showUserOnboarding,
    setShowUserOnboarding,
    pageLoading,
    setPageLoading,
    setShowUserDetails,
    showUserDetails,
    showAddBank,
    showAllBank,
    setShowAddBank,
    setShowAllBank,
    appointment,
    setAppointment,
    showPatient,
    setShowPatient,
    patients,
    setPatients,
    services,
    setEditServiceState,
    editServiceState,
    setService,
    enquiries,
    setEnquiries,
    doctorInfo,
    setDoctorInfo,
    bookAppointment,
    setBookAppointment,
    showEnquiries,
    setShowEnquiries,
    showService,
    setShowService,
    otherDetails,
    setOtherDetails,
    setDetailsState,
    detailsState,
    showBookAppointment,
    setShowBookAppointment,
    setAddPatient,
    addPatient,
    addAppointment,
    setAddAppointment,
    showNotificationSidebar,
    setShowNotificationSidebar,
    showNotificationDetailsSidebar,
    setShowNotificationDetailsSidebar,
    doctorsBankList,
    setDoctorsBankList,
    walletAccount,
    setWalletAccount,
    transactions,
    setTransactions,
    dashboardData,
    setDashboardData,
    doctorNotification,
    setDoctorNotification,
    appointmentDetails,
    setAppointmentDetails,
    setShowPaymentDetails,
    showPaymentDetails,
    paymentDetails,
    setPaymentDetails
  };
};
export default useAtomState;
