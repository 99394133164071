import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Logo from "../../components/logo";
import authServices from "../../services/auth.service";

const PageNotFound = () => {
  return (
    <Container fluid>
      <Logo center width={10} />
      <div className="position-absolute top-50 start-50 translate-middle-x">
        <h2 className="text-theme">Page not found</h2>
        <Link
          to={authServices.authenticationStages() as string}
          className="text-center mt-4 d-block"
        >
          <span className="text-primary fw-bold">Home</span>
        </Link>
      </div>
    </Container>
  );
};
export default PageNotFound;
