import React from "react";
import { Col, Row, Stack } from "react-bootstrap";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import { PaginationProps } from "../@types/props";
import Input from "../components/input";
import appServices from "../services/app.service";
import { useGetScreen } from "./app";

const usePagination = (settings: PaginationProps) => {
  const { isMobilePortrait } = useGetScreen();
  const [pageSettings, setPageSettings] = React.useState({
    skip: 0,
    total: 10,
    limit: 10,
    query: settings.query,
  });

  React.useEffect(() => {
    callPagination().then((data) => {
      settings.setData?.(data);
    });
    // eslint-disable-next-line
  }, [settings.query]);

  async function callPagination(skip?: number) {
    settings.setLoading?.(true);
    const data = {
      ...pageSettings,
      skip: skip as number,
      query: settings.query,
    };

    const res = await appServices.handlePagination(data, settings.type);
    if (res) {
      setPageSettings({
        ...data,
        total: res.total,
        limit: res.limit,
      });
      settings.setLoading?.(false);
    }
    return res?.data;
  }

  const onPageChange = (currentPage: number) => {
    let offset = (currentPage + 1 - 1) * pageSettings?.limit;
    callPagination(offset).then((data) => {
      settings.setData?.(data);
    });
  };

  const Pagination: React.FC = () => (
    <React.Fragment>
      <Row>
        {!isMobilePortrait && (
          <Col>
            <Stack direction="horizontal">
              {settings.showResults && (
                <>
                  <div className="pt-2 pe-2" style={{ color: "#84818A" }}>
                    Show result
                  </div>
                  <div className="w-40">
                    <Input
                      type={"select"}
                      value={pageSettings.limit}
                      onChange={(limit) => {
                        setPageSettings({ ...pageSettings, limit });
                      }}
                      className="border-1 mt-2"
                      options={[
                        { name: "10", label: "10", value: 10 },
                        { name: "20", label: "20", value: 20 },
                      ]}
                    />
                  </div>
                </>
              )}
            </Stack>
          </Col>
        )}
        <Col>
          <ReactPaginate
            pageCount={Math.ceil(pageSettings.total / pageSettings.limit)}
            breakLabel="..."
            previousLabel={<BsChevronLeft color="#84818A" />}
            nextLabel={<BsChevronRight color="#84818A" />}
            onPageChange={(data) => onPageChange(data.selected)}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
            forcePage={Math.ceil(pageSettings.skip / pageSettings.limit)}
          />
        </Col>
      </Row>
    </React.Fragment>
  );

  return {
    Pagination,
  };
};

export default usePagination;
