import React from "react";
import { Col, Row } from "react-bootstrap";
import Input from "../../components/input";
import useAtomState from "../../hooks/state";
import appServices from "../../services/app.service";

const Details: React.FC = () => {
  const [currentState, setCurrentState] = React.useState<any[]>([]);
  const { detailsState, setDetailsState } = useAtomState();

  React.useEffect(() => {
    Promise.all([appServices.getStates()]).then(([states]) => {
      setCurrentState(states);
    });
  }, []);

  return (
    <div className="mb-5">
      <div className="ms-4 mt-4">
        <h1>Welcome to Yanga dental</h1>
        <p>Kindly fill the data form below</p>
        <p className="mt-4 text-gray">Personal</p>
      </div>

      <form className="w-90 ms-3">
        <Row className="g-2">
          <Col sm={12} md={6}>
            <Input
              label="Full name"
              containerClass="mt-3"
              className="mt-2"
              placeholder="Your full name"
              value={detailsState.fullName}
              onChange={(fullName) => {
                setDetailsState((state: any) => {
                  return { ...state, fullName };
                });
              }}
            />
          </Col>
          <Col sm={12} md={6}>
            <Input
              containerClass="mt-3"
              className="mt-2"
              label="Business name"
              placeholder="Business name"
              value={detailsState.businessName}
              onChange={(businessName) => {
                setDetailsState((state: any) => {
                  return { ...state, businessName };
                });
              }}
            />
          </Col>
        </Row>
        <Row className="g-2">
          <Col sm={12} md={6}>
            <Input
              label="Email"
              containerClass="mt-3"
              className="mt-2"
              placeholder="Email address"
              value={detailsState.email}
              onChange={(email) => {
                setDetailsState((state: any) => {
                  return { ...state, email };
                });
              }}
            />
          </Col>
          <Col sm={12} md={6}>
            <Input
              label="Phone"
              type={'number'}
              containerClass="mt-3"
              className="mt-2"
              placeholder="Your phone number"
              maxLength={11}
              value={detailsState.phone}
              onChange={(phone) => {
                setDetailsState((state: any) => {
                  return { ...state, phone };
                });
              }}
            />
          </Col>
        </Row>
        <Row className="g-2">
          <Col sm={12} md={6}>
            <Input
              label="Gender"
              placeholder="Select gender"
              className="mt-2"
              type={"select"}
              options={[
                { name: "Male", label: "Male", value: "Male" },
                { name: "Female", label: "Female", value: "Male" },
              ]}
              value={detailsState.gender}
              onChange={(gender) => {
                setDetailsState((state: any) => {
                  return { ...state, gender };
                });
              }}
            />
          </Col>
          <Col sm={12} md={6}>
            <Input
              label="State"
              placeholder="Select state"
              type={"select"}
              className="mt-2"
              options={[...currentState]}
              value={detailsState.state}
              onChange={(states) => {
                setDetailsState((state: any) => {
                  return { ...state, state: states };
                });
              }}
            />
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default Details;
