import React from "react";
import { Row, Col } from "react-bootstrap";
import { BsFillEyeSlashFill } from "react-icons/bs";
import { AccountSettingsProps } from "../../../@types/props";
import CustomButton from "../../../components/customButton";
import Input from "../../../components/input";
import { useGetScreen } from "../../../hooks/app";

const AccountSettings: React.FC<AccountSettingsProps> = () => {
  const { isMobilePortrait } = useGetScreen();
  return (
    <React.Fragment>
      <form
        className={`bg-white p-3 rounded-4 mt-3 ${
          isMobilePortrait ? "w-100" : "w-70"
        }`}
      >
        <Input
          label="Current password"
          type={"password"}
          containerClass="mt-3"
          icon={<BsFillEyeSlashFill size={20} />}
        />
        <Input
          label="New password"
          type={"password"}
          containerClass="mt-3"
          icon={<BsFillEyeSlashFill size={20} />}
        />
        <Row className="justify-content-between mt-4">
          <Col sm={12} lg={6} md={6}>
            <CustomButton
              type="button"
              className={`${isMobilePortrait ? "mx-auto w-50" : "w-60"}`}
            >
              Save changes
            </CustomButton>
          </Col>
          <Col sm={12} lg={6} md={6}>
            <p
              className={`${isMobilePortrait ? "text-center pt-3" : ""}`}
              style={{ fontSize: isMobilePortrait ? 12 : 16 }}
            >
              Can't remember your current password?
              <span className="text-theme"> Reset pasword.</span>
            </p>
          </Col>
        </Row>
      </form>
      <form
        className={`bg-white p-3 rounded-4 mt-3 ${
          isMobilePortrait ? "w-100" : "w-70"
        }`}
      >
        <Input label="Current email" containerClass="mt-3" />
        <Input label="New email" containerClass="mt-3" />
        <Row className="justify-content-between mt-4">
          <Col sm={12} lg={6} md={6}>
            <CustomButton
              type="button"
              className={`${isMobilePortrait ? "mx-auto w-50" : "w-60"}`}
            >
              Save changes
            </CustomButton>
          </Col>
          <Col sm={12} lg={6} md={6}></Col>
        </Row>
      </form>
    </React.Fragment>
  );
};

export default AccountSettings;
