import moment from "moment";
import React from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { assets } from "../../assets/assets";
import CustomTable from "../../components/customTable";
import Input from "../../components/input";
import { useGetScreen } from "../../hooks/app";
import useAtomState from "../../hooks/state";

const Transactions: React.FC = () => {
  const {
    transactions,
    setTransactions,
    setPaymentDetails,
    setShowPaymentDetails,
  } = useAtomState();
  const { isMobilePortrait } = useGetScreen();
  const [filterState, setFilterState] = React.useState({
    referenceNumber: "",
    transactionType: "",
    transactionDate: "",
  });

  return (
    <div className="w-95 mx-auto">
      <form>
        <div
          className={`d-flex ${
            isMobilePortrait ? "flex-column-reverse" : "flex-row"
          } justify-content-between`}
        >
          <div className={`${isMobilePortrait ? "w-95 mx-auto mt-3" : "w-45"}`}>
            <Input
              icon={<AiOutlineSearch size={25} />}
              type="text"
              iconClass="transaction_search_icon"
              label="Search"
              style={{ paddingLeft: 30 }}
              value={filterState.referenceNumber}
              onChange={async (referenceNumber) => {
                setFilterState({ ...filterState, referenceNumber });
              }}
            />
          </div>
          <div
            className={`${
              isMobilePortrait
                ? "w-100 d-flex flex-row gap-4"
                : "w-45 d-flex flex-row-reverse gap-4"
            }`}
          >
            <Input
              type={"select"}
              label="Transaction type"
              value={filterState.transactionType}
              options={[
                { label: "Pending", name: "Pending", value: "pending" },
                { label: "OnHold", name: "OnHold", value: "onHold" },
              ]}
              onChange={async (transactionType) => {
                setFilterState({ ...filterState, transactionType });
              }}
            />
            <Input
              type={"date"}
              label="Date"
              value={filterState.transactionDate}
              onChange={async (transactionDate) => {
                setFilterState({ ...filterState, transactionDate });
              }}
            />
          </div>
        </div>
      </form>

      <div className="bg-white mt-3 rounded-2">
        <p className="ps-3 pt-3 fs-5 fw-normal">Recent Transactions</p>
        <CustomTable
          type="transaction"
          screen={{ isLargeDesktop: "60vh" }}
          setData={setTransactions}
          query={filterState}
        >
          <thead className="theader" style={{ backgroundColor: "#FAFAFB" }}>
            <tr>
              <th colSpan={2}>Category</th>
              <th>Date & time</th>
              <th>Amount</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {transactions?.map((data) => {
              console.log(data?.payment.appointment);

              const picture = data?.payment.appointment?.patient?.picture;
              const sender =
                data?.payment.appointment?.patient?.fullName ||
                "Patient deleted";
              const service =
                data?.payment.appointment?.doctor_service?.serviceName;
              return (
                <tr
                  key={data?.id}
                  className="cursor-p"
                  onClick={() => {
                    setPaymentDetails({
                      amount: data?.amount,
                      date: data?.createdAt,
                      status: data?.status,
                      picture,
                      sender,
                      service,
                      id: data?.id
                    });
                    setShowPaymentDetails(true);
                  }}
                >
                  <td colSpan={2}>
                    <img
                      src={assets.mock_avatar_7}
                      alt="avatar"
                      className="rounded-3 d-inline-block"
                      width={30}
                    />
                    <span className="ms-2 fs-7">{data?.category}</span>
                  </td>
                  <td>
                    <p
                      style={{
                        lineHeight: isMobilePortrait ? 1.5 : 0.5,
                        fontSize: 13,
                        paddingTop: 10,
                      }}
                    >
                      {moment(data?.createdAt).toDate().toUTCString()}
                    </p>
                  </td>
                  <td>
                    <p className="pt-2 fs-7">
                      {data?.type === "credit" ? "+" : "-"} {data?.amount}
                    </p>
                  </td>
                  <td>
                    {data?.status === "onHold" ? (
                      <p className="pink-text">{data?.status}</p>
                    ) : (
                      <p className="green-text">{data?.status}</p>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </CustomTable>
      </div>
    </div>
  );
};

export default Transactions;
